import { unionize, UnionOf, ofType } from 'unionize';
import gql from 'types/gql';
import { unionizeConfig } from '../utils';
import { CheckListWithTags } from 'components/Admin/CheckLists';

export interface AppReducerState {
  icons: gql.Icon[];
  checklistCategories: gql.ChecklistCategory[];
}

export const appActions = unionize(
  {
    setIcons: ofType<gql.Icon[]>(),
    addIcon: ofType<gql.Icon>(),
    deleteIcon: ofType<{ iconId: string }>(),
    updateIconWeight: ofType<gql.Icon>(),
    setChecklistCategories: ofType<gql.ChecklistCategory[]>(),
    createChecklistCategory: ofType<{
      title: string;
      id: string;
      weight: number;
    }>(),
    deleteChecklistCategory: ofType<{ categoryId: string }>(),
    updateChecklistCategory: ofType<{
      title: string;
      id: string;
      weight: number;
    }>(),
    createCheckList: ofType<CheckListWithTags>(),
    editChecklist: ofType<CheckListWithTags>(),
    deleteChecklistFromCategories: ofType<{
      checklistId: string;
      categoriesIds: string[];
    }>(),
    addChecklistToCategories: ofType<{
      checklist: gql.CategoryChecklist;
      categoriesIds: string[];
    }>(),
    deleteChecklist: ofType<{ checklistId: string; categories: string[] }>(),
    updateCategoryWeight: ofType<{ categoryId: string; newWeight: number }>(),
    updateChecklistWeight: ofType<{
      categoryId: string;
      checklistId: string;
      newWeight: number;
    }>(),
  },
  unionizeConfig,
);

export type AppAction = UnionOf<typeof appActions>;

const initialState: AppReducerState = {
  icons: [],
  checklistCategories: [],
};

function appReducer(state: AppReducerState = initialState, action: AppAction) {
  return appActions.match(action, {
    setIcons: (icons) => ({ ...state, icons: [...icons] }),
    setChecklistCategories: (checklistCategories) => ({
      ...state,
      checklistCategories: [...checklistCategories],
    }),
    createChecklistCategory: (category) => {
      return {
        ...state,
        checklistCategories: [...state.checklistCategories, { ...category, checklists: [] }],
      };
    },
    deleteChecklistCategory: ({ categoryId }) => ({
      ...state,
      checklistCategories: state.checklistCategories.filter((category) => categoryId !== category.id),
    }),
    updateChecklistCategory: ({ title, id, weight }) => {
      return {
        ...state,
        checklistCategories: state.checklistCategories.map((category) =>
          category.id === id ? { ...category, title, weight } : category,
        ),
      };
    },
    createCheckList: ({
      categories,
      defaultDescription,
      defaultTitle,
      icon,
      id,
      trackerDescription,
      trackerTitle,
      usersCount,
      weight,
    }) => {
      return {
        ...state,
        checklistCategories: state.checklistCategories.map((checklistCategory) => {
          if (
            categories.some(
              //@ts-ignore
              (categoryId) => categoryId === checklistCategory.id,
            )
          ) {
            const category = state.checklistCategories.find((category) => category.id === checklistCategory.id);
            const weights = category?.checklists.map((checklist) => checklist.weight).sort((a, b) => a - b)!;
            const weight = weights[0] - 1;
            return {
              ...checklistCategory,
              checklists: [
                ...checklistCategory.checklists,
                {
                  trackerTitle,
                  defaultDescription,
                  defaultTitle,
                  icon,
                  id,
                  trackerDescription,
                  usersCount,
                  weight,
                },
              ],
            };
          } else return checklistCategory;
        }),
      };
    },
    editChecklist: ({
      categories,
      defaultDescription,
      defaultTitle,
      icon,
      id,
      trackerDescription,
      trackerTitle,
      usersCount,
      weight,
    }) => {
      return {
        ...state,
        checklistCategories: state.checklistCategories.map((checklistCategory) => {
          if (
            categories.some(
              //@ts-ignore
              (categoryId) => categoryId === checklistCategory.id,
            )
          ) {
            return {
              ...checklistCategory,
              checklists: checklistCategory.checklists.map((checklist) =>
                checklist.id === id
                  ? {
                      ...checklist,
                      defaultDescription,
                      defaultTitle,
                      icon,
                      trackerDescription,
                      trackerTitle,
                      usersCount,
                      weight,
                    }
                  : checklist,
              ),
            };
          } else return checklistCategory;
        }),
      };
    },
    deleteChecklistFromCategories: ({ categoriesIds, checklistId }) => ({
      ...state,
      checklistCategories: state.checklistCategories.map((checklistCategory) => {
        if (categoriesIds.some((categoryId) => categoryId === checklistCategory.id)) {
          return {
            ...checklistCategory,
            checklists: checklistCategory.checklists.filter((checklist) => checklist.id !== checklistId),
          };
        } else return checklistCategory;
      }),
    }),
    addChecklistToCategories: ({ categoriesIds, checklist }) => ({
      ...state,
      checklistCategories: state.checklistCategories.map((checklistCategory) => {
        if (
          categoriesIds.some((categoryId) => categoryId === checklistCategory.id) &&
          !checklistCategory.checklists.some((categoryChecklist) => categoryChecklist.id === checklist.id)
        ) {
          const category = state.checklistCategories.find((category) => category.id === checklistCategory.id);
          const weights = category?.checklists.map((checklist) => checklist.weight).sort((a, b) => b - a)!;
          const weight = weights[0] - 1;
          const checklistWithWeight = { ...checklist, weight: weight };
          return {
            ...checklistCategory,
            checklists: [...checklistCategory.checklists, checklistWithWeight],
          };
        } else return checklistCategory;
      }),
    }),
    deleteChecklist: ({ checklistId, categories }) => ({
      ...state,
      checklistCategories: state.checklistCategories.map((checklistCategory) => {
        if (categories.find((categoryId) => categoryId === checklistCategory.id)) {
          return {
            ...checklistCategory,
            checklists: checklistCategory.checklists.filter((checklist) => checklist.id !== checklistId),
          };
        } else return checklistCategory;
      }),
    }),
    addIcon: ({ id, imageUrl, weight }) => {
      const iconsWeights = state.icons.sort((a, b) => a.weight - b.weight);
      const minWeight = iconsWeights[0].weight;
      return {
        ...state,
        icons: [...state.icons, { id, imageUrl, weight: minWeight - 1 }],
      };
    },
    updateCategoryWeight: ({ categoryId, newWeight }) => {
      const category = state.checklistCategories.find((category) => category.id === categoryId)!;

      const min = Math.min(newWeight, category.weight);
      const max = Math.max(newWeight, category.weight);
      const trend = newWeight > category.weight ? -1 : 1;

      return {
        ...state,
        checklistCategories: state.checklistCategories.map((category) =>
          category.id === categoryId
            ? { ...category, weight: newWeight }
            : category.weight >= min && category.weight <= max
            ? { ...category, weight: category.weight + trend }
            : category,
        ),
      };
    },
    updateChecklistWeight: ({ categoryId, checklistId, newWeight }) => {
      const categoryToUpdateIn = state.checklistCategories.find((category) => category.id === categoryId)!;
      const checklistToUpdate = categoryToUpdateIn.checklists.find((checklist) => checklist.id === checklistId)!;

      const min = Math.min(newWeight, checklistToUpdate.weight);
      const max = Math.max(newWeight, checklistToUpdate.weight);
      const trend = newWeight > checklistToUpdate.weight ? -1 : 1;

      return {
        ...state,
        checklistCategories: state.checklistCategories.map((category) => {
          if (categoryId === category.id) {
            return {
              ...category,
              checklists: category.checklists.map((checklist) =>
                checklist.id === checklistId
                  ? { ...checklist, weight: newWeight }
                  : checklist.weight >= min && checklist.weight <= max
                  ? { ...checklist, weight: checklist.weight + trend }
                  : checklist,
              ),
            };
          } else {
            return category;
          }
        }),
      };
    },
    deleteIcon: ({ iconId }) => ({
      ...state,
      icons: state.icons.filter((icon) => icon.id !== iconId),
    }),

    updateIconWeight: ({ id, imageUrl, weight }) => {
      const iconToUpdate = state.icons.find((icon) => icon.id === id)!;
      const min = Math.min(weight, iconToUpdate.weight);
      const max = Math.max(weight, iconToUpdate.weight);
      const trend = weight > iconToUpdate.weight ? -1 : 1;

      return {
        ...state,
        icons: state.icons.map((icon) => {
          if (icon.id === id) {
            return { ...icon, weight };
          } else {
            if (icon.weight >= min && icon.weight <= max) {
              return { ...icon, weight: icon.weight + trend };
            } else return icon;
          }
        }),
      };
    },
    default: () => state,
  });
}

export default appReducer;
