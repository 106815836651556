import React, { useEffect, useRef, memo } from 'react';
import VKModalRoot, { ModalRootProps } from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot';
import {useThrottle} from '../../../hooks';

const MODAL_APPEAR_ANIMATION_DELAY = 600;

/**
 * Переопределяет компонент ModalRoot из VK UI.
 * - Лечит баг поломки приложения при быстром переключении activeModal.
 * - Скрывает скролл страницы в момент отображения модального окна;
 */
const ModalRoot: React.FC<ModalRootProps> = memo(({ activeModal, ...modalRootProps }) => {
  // Меняем activeModalId на новый только после того, когда завершится анимация открытия старого
  // FIXME: https://github.com/VKCOM/VKUI/issues/482 (при быстрой смене activeModal ломается приложение)
  const throttledActiveModal = useThrottle(activeModal, MODAL_APPEAR_ANIMATION_DELAY);

  const activeModalOpened = typeof throttledActiveModal === 'string';

  /* Если открыто модально окно, скрываем скролл страницы */

  const previousBodyOverflow = useRef(document.body.style.overflow);

  useEffect(() => {
    if (activeModalOpened) {
      previousBodyOverflow.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = previousBodyOverflow.current;
    }
  }, [activeModalOpened]); // eslint-disable-line

  /* Рендер */

  return <VKModalRoot {...modalRootProps} activeModal={throttledActiveModal} />;
});

export default ModalRoot;
