import {useEffect, useState, useCallback} from 'react';
import {useRouteMatch} from 'react-router-dom';

export function useStrictRouteMatch<T extends string>(
  path: string,
  routes: { [key: string]: T },
  fallbackRoute?: T,
) {
  const validate = useCallback((url: T) => Object.values(routes).includes(url), [routes]);

  const url = useRouteMatch(path)?.url as T;
  const [activeRoute, setActiveRoute] = useState<T>(validate(url) ? url : fallbackRoute as T);

  useEffect(() => {
    if (validate(url)) {
      setActiveRoute(url);
    } else if (fallbackRoute) {
      setActiveRoute(fallbackRoute);
    }
  }, [fallbackRoute, url, validate]);

  return activeRoute;
}

