import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import cn from 'classnames';
import { makeStyles } from '@material-ui/styles';

// import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Icon24BrowserForward from '@vkontakte/icons/dist/24/browser_forward';

import { isSameDate, isDisabled } from '../../../utils/calendar';
import './calendar.css';
import { useSelector } from '../../../hooks';
import { Gallery } from '@vkontakte/vkui';

const useStyles = makeStyles((theme) => ({
  month: {
    width: '330px !important',
    margin: '0 16px',
    boxSizing: 'border-box',
  },
  monthTitle: {
    color: theme.palette.text?.primary,
  },
  localActive: {
    '& span': {
      backgroundColor: theme.palette.card.background,
      border: `1.5px solid ${theme.palette.icon?.checked}`,
    },
  },
  desktop: {
    height: '100%',
    minHeight: 'max-content',
    
    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 24,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 48,
    },
  },
  navigationIcon: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    objectFit: 'cover',
    borderRadius: '15px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.4)',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    color: '#939393',
    opacity: 0.75,
    
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
  navigationIconLeft: {
    left: 16,
  },
  navigationIconRight: {
    right: 16,
  },
}));

class ChecklistCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      days: [],
      slideIndex: 0,
    };
    
    this.handleSelectedDate = this.handleSelectedDate.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.changeSlideIndex = this.changeSlideIndex.bind(this);
  }
  
  handleSelectedDate(e, value) {
    e && e.preventDefault();
    this.setSelectedDate(value);
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }
  
  setSelectedDate(date) {
    this.setState({ selectedDate: date });
  }
  
  changeSlideIndex(slideIndex) {
    this.setState({
      slideIndex: slideIndex,
    });
  }
  
  componentDidMount() {
    this.setSelectedDate(this.props.selectedDate);
    this.setState({ days: this.props.days });
    
    const diffMonth = Math.round(
      this.props.selectedDate.diff(
        this.props.minDate.clone().startOf('month'),
        'months', true,
      ),
    );
    
    if (diffMonth > 0) {
      const currentMonthEl = document.getElementById(
        moment(this.props.selectedDate, 'DD/MMM/YYYY').format('MMMM-YYYY'),
      );
      if (currentMonthEl) {
        setTimeout(() => {
          currentMonthEl.scrollIntoView({
            block: 'nearest',
            behavior: 'smooth',
          });
        }, 1500);
      }
    }
  }
  
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(props) {
    if (props.selectedDate) {
      this.setSelectedDate(props.selectedDate);
    }
    
    if (props.days) {
      this.setState({
        days: props.days,
      });
    }
  }
  
  render() {
    const props = {
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      selectedDate: this.state.selectedDate,
      handleSelect: this.handleSelectedDate,
      className: `mobile-datepicker ${this.props.className || ''}`,
      yearFormat: this.props.yearFormat,
      monthFormat: this.props.monthFormat,
      enableYearTitle: this.props.enableYearTitle,
      enableMonthTitle: this.props.enableMonthTitle,
      icon: this.props.icon,
      days: this.state.days,
      slideIndex: this.state.slideIndex,
      changeSlideIndex: this.changeSlideIndex,
    };
    
    return <RenderCalendarYear {...props} />;
  }
}

export const RenderCalendarYear = (props) => {
  const { minDate, maxDate, slideIndex } = props;
  const mc = useStyles();
  const isDesktop = useSelector((state) => state.config.launchParams.platform === 'desktop_web');
  const now = moment(minDate, 'DD/MMM/YYYY');
  const elements = [];
  
  let totalMonth = Math.round(maxDate.diff(minDate, 'months', true)) + 1;
  if (totalMonth === 1 && isDesktop) {
    totalMonth += 1;
  }
  
  for (let i = 0; i < totalMonth; i++) {
    elements.push(
      <RenderMonthCard
        key={i}
        currentMonth={now.clone()}
        isDesktop={isDesktop}
        {...props}
      />,
    );
    
    now.add(1, 'M');
  }
  
  return isDesktop ?
    <>
      <Gallery
        slideWidth="custom"
        align="left"
        className={cn(props.className, mc.desktop)}
        slideIndex={slideIndex}
        onChange={slideIndex => props.changeSlideIndex(slideIndex)}
      >
        {elements}
      </Gallery>
      {/*<div className={cn(mc.navigationIcon, mc.navigationIconLeft)}*/}
      {/*     onClick={() => props.changeSlideIndex(slideIndex === 0 ? elements.length - 1 : slideIndex - 1)}*/}
      {/*><Icon24BrowserBack/></div>*/}
      {totalMonth > 1 &&
      <div className={cn(mc.navigationIcon, mc.navigationIconRight)}
           onClick={() => props.changeSlideIndex(slideIndex === elements.length - 1 ? 0 : slideIndex + 1)}
      ><Icon24BrowserForward/></div>}
    </>
    :
    <div className={props.className}>{elements}</div>;
};

export const RenderMonthCard = (props) => {
  const mc = useStyles();
  const now = props.currentMonth;
  
  return (
    <div
      id={now.format('MMMM-YYYY')}
      className={cn('month', props.isDesktop && mc.month)}
      style={props.isDesktop ? { paddingBottom: 0 } : {}}
    >
      <RenderMonthHeader date={now} {...props} />
      <RenderDayHeader/>
      <RenderDays date={now} {...props} />
    </div>
  );
};

export const RenderMonthHeader = (props) => {
  const mc = useStyles();
  const month = props.date.format(props.monthFormat);
  const year = props.date.format(props.yearFormat);
  
  return (
    <div className={cn('month-title', mc.monthTitle)}>
      {props.enableYearTitle ? <span>{year}</span> : null}
      {props.enableMonthTitle ? month : null}
    </div>
  );
};

export const RenderDayHeader = () => {
  return (
    <ul className="days">
      <li key={'Monday'}>Пн</li>
      <li key={'Tuesday'}>Вт</li>
      <li key={'Wednesday'}>Ср</li>
      <li key={'Thursday'}>Чт</li>
      <li key={'Friday'}>Пт</li>
      <li key={'Saturday'}>Сб</li>
      <li key={'Sunday'}>Вс</li>
    </ul>
  );
};

export const RenderSingleDay = (
  {
    isActive,
    handleClick,
    currentValue,
    isDisabled,
    i,
    icon,
    checked,
  },
) => {
  const mc = useStyles();
  const className = cn({
    active: isActive,
    [mc.localActive]: isActive,
    disabled: isDisabled,
    dayChecked: checked,
  });
  
  return (
    <li className={className} key={i}>
      <span onClick={(e) => handleClick(e, currentValue)}>
        {/*{currentValue.date()}*/}
        <img src={icon} alt="*"/>
      </span>
    </li>
  );
};

export const RenderDays = (
  {
    date,
    selectedDate,
    handleSelect,
    minDate,
    maxDate,
    icon,
    days = [],
  },
) => {
  const daysInMonth = date.daysInMonth();
  const startDate = date.startOf('month');
  const balanceDayCount = startDate.day() - 1; // From Monday
  
  const renderDay = () => {
    const elements = [];
    const now = moment(date, 'DD/MMM/YYYY');
    
    for (let i = 1; i <= daysInMonth; i++) {
      const inDays = !!days.find((d) => isSameDate(now.clone(), d));
      
      elements.push(
        <RenderSingleDay
          isActive={isSameDate(now.clone(), selectedDate)}
          isDisabled={isDisabled(minDate, now.clone(), maxDate)}
          handleClick={handleSelect}
          currentValue={now.clone()}
          key={i}
          icon={icon}
          checked={inDays}
        />,
      );
      
      now.add(1, 'days');
    }
    return elements;
  };
  
  const renderUnwantedDay = (balanceDayCount) => {
    const elements = [];
    for (let i = 0; i < balanceDayCount; i++) {
      elements.push(<li className="visible-hidden" key={i}/>);
    }
    return elements;
  };
  
  return (
    <ul className="date">
      {renderUnwantedDay(balanceDayCount)}
      {renderDay()}
    </ul>
  );
};

ChecklistCalendar.defaultProps = {
  minDate: moment(),
  maxDate: moment(),
  selectedDate: moment(),
  monthFormat: 'MMMM',
  yearFormat: 'YYYY',
  enableYearTitle: true,
  enableMonthTitle: true,
  slideIndex: 1,
};

export default ChecklistCalendar;
