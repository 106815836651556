import React from 'react';

import {
  UserChecklist,
  Checklist,
  ChecklistCategory,
  Icon,
} from '../../../types/gql';

import ChecklistAddModal from './ChecklistAddModal';
import ChecklistModal from './ChecklistModal';
import ChecklistEditModal from './ChecklistEditModal';
import ChecklistEditNotificationModal from './ChecklistEditNotificationModal';
import ChecklistConfirmDeleteModal from './ChecklistConfirmDeleteModal';
import ErrorRetryModal from './ErrorRetryModal';
import { AdminAddCategoryModal } from '../../Admin/AdminModals/AdminAddCategoryModal';
import { AdminEditCategoryModal } from '../../Admin/AdminModals/AdminEditCategoryModal';
import { AdminDeleteModal } from '../../Admin/AdminModals/AdminDeleteModal';
import { AdminAddChecklistModal } from '../../Admin/AdminModals/AdminAddChecklistModal';
import { CheckListWithTags } from '../../Admin/CheckLists';
import { AdminDeleteIconModal } from '../../Admin/AdminModals/AdminDeleteIconModal';
import { AdminAddIconModal } from '../../Admin/AdminModals/AdminIconAddModal';

export enum ModalId {
  CHECKLIST_VIEW = 'checklist-view',
  CHECKLIST_ADD = 'checklist-add',
  CHECKLIST_EDIT = 'checklist-edit',
  CHECKLIST_EDIT_NOTIFICATION = 'checklist-edit-notification',
  CHECKLIST_DELETE = 'checklist-delete',
  ERROR_RETRY = 'error-retry',
  ADMIN_CATEGORY_ADD = 'admin-category-add',
  ADMIN_CATEGORY_EDIT = 'admin-category-edit',
  ADMIN_DELETE = 'admin-delete',
  ADMIN_CHECKLIST_ADD_OR_EDIT = 'admin-checklist-add-or-edit',
  ADMIN_ICON_DELETE = 'admin-icon-delete',
  ADMIN_ICON_ADD = 'admin-icon-add',
}

export const modalComponents: { [K in ModalId]: React.FC<ModalProps<K>> } = {
  [ModalId.CHECKLIST_VIEW]: ChecklistModal,
  [ModalId.CHECKLIST_ADD]: ChecklistAddModal,
  [ModalId.CHECKLIST_EDIT]: ChecklistEditModal,
  [ModalId.CHECKLIST_EDIT_NOTIFICATION]: ChecklistEditNotificationModal,
  [ModalId.CHECKLIST_DELETE]: ChecklistConfirmDeleteModal,
  [ModalId.ERROR_RETRY]: ErrorRetryModal,
  [ModalId.ADMIN_CATEGORY_ADD]: AdminAddCategoryModal,
  [ModalId.ADMIN_CATEGORY_EDIT]: AdminEditCategoryModal,
  [ModalId.ADMIN_DELETE]: AdminDeleteModal,
  [ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT]: AdminAddChecklistModal,
  [ModalId.ADMIN_ICON_DELETE]: AdminDeleteIconModal,
  [ModalId.ADMIN_ICON_ADD]: AdminAddIconModal,
};

export enum NotificationType {
  EDIT = 'edit',
  DISABLE = 'disable',
}

export interface ModalPayload {
  [ModalId.CHECKLIST_VIEW]: { checklist: UserChecklist };
  [ModalId.CHECKLIST_ADD]: { checklist: Checklist | null };
  [ModalId.CHECKLIST_EDIT]: { checklist: UserChecklist };
  [ModalId.CHECKLIST_EDIT_NOTIFICATION]: { checklist: UserChecklist } & (
    | { type?: NotificationType.EDIT }
    | { type?: NotificationType.DISABLE }
    );
  [ModalId.CHECKLIST_DELETE]: { checklist: UserChecklist };
  [ModalId.ERROR_RETRY]: {
    text: string;
    onRetry(): Promise<void>;
    withoutCloseOnSuccess?: boolean;
  };
  [ModalId.ADMIN_CATEGORY_ADD]: {};
  [ModalId.ADMIN_CATEGORY_EDIT]: {
    category: ChecklistCategory;
  };
  [ModalId.ADMIN_DELETE]: {
    category?: ChecklistCategory;
    checklist?: {
      id: string;
      title: string;
      desc: string;
      iconSrc: string;
      categories: string[];
    };
    categoryId?: string;
  };
  [ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT]: {
    checklist?: CheckListWithTags;
    categoryId?: string;
  };
  [ModalId.ADMIN_ICON_DELETE]: {
    icon: Icon;
  };
  [ModalId.ADMIN_ICON_ADD]: {};
}

export interface ModalProps<K extends ModalId> {
  id: string;
  onClose(): void;
  settlingHeight?: number;
  dynamicContentHeight?: boolean;
  payload: ModalPayload[K];
  updateModalHeight?(): void;
}
