import { unionize, UnionOf, ofType } from 'unionize';
import { unionizeConfig } from '../utils';
import { DateTime, UserChecklist } from '../../types/gql';
import { isSameDate } from '../../utils';

export interface UserReducerState {
  fullName: string;
  profileImageUrl: string | undefined;
  isRegistered?: boolean;
  isAdmin?: boolean;
  checklists: UserChecklist[];
}

export const userActions = unionize(
  {
    setUserRegistered: {},
    setUserChecklists: ofType<UserChecklist[]>(),
    setUserChecklist: ofType<UserChecklist>(),
    createUserChecklist: ofType<{
      checklistId: string;
      iconId: string;
      duration: number;
    }>(),
    updateUserChecklist: ofType<UserChecklist>(),
    updateUserChecklistWeight: ofType<{ checklist: UserChecklist; newWeight: number }>(),
    deleteUserChecklist: ofType<{ userChecklistId: string }>(),
    checkUserDay: ofType<{
      userChecklistId: string;
      date: DateTime;
      isChecked: boolean;
    }>(),
    updateChecklistNotification: ofType<UserChecklist>(),
  },
  unionizeConfig,
);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserReducerState = {
  fullName: '',
  profileImageUrl: '',
  isRegistered: false,
  checklists: [],
};

/**
 * Редьюсер который отвечает за информацию о пользователе.
 */
function userReducer(
  state: UserReducerState = initialState,
  action: UserAction,
) {
  return userActions.match(action, {
    setUserRegistered: () => ({ ...state, isRegistered: true }),
    setUserChecklists: (checklists) => ({
      ...state,
      checklists: [...checklists],
    }),
    setUserChecklist: (checklist) => ({
      ...state,
      checklists: [...state.checklists, checklist],
    }),
    createUserChecklist: () => state,
    updateUserChecklist: (updatedChecklists) => ({
      ...state,
      checklists: [
        ...state.checklists?.filter(
          (checklist) => checklist.id !== updatedChecklists.id,
        ),
        updatedChecklists,
      ],
    }),
    updateUserChecklistWeight: ({ checklist: checklistToUpdate, newWeight }) => {
      const min = Math.min(newWeight, checklistToUpdate.weight);
      const max = Math.max(newWeight, checklistToUpdate.weight);
      const trend = newWeight > checklistToUpdate.weight ? -1 : 1;

      return {
        ...state,
        checklists: state.checklists.map((checklist) =>
          checklist.id === checklistToUpdate.id
            ? { ...checklist, weight: newWeight }
            : checklist.weight >= min && checklist.weight <= max
            ? { ...checklist, weight: checklist.weight + trend }
            : checklist,
        ),
      };
    },
    deleteUserChecklist: ({ userChecklistId }) => ({
      ...state,
      checklists: state.checklists?.filter(
        (checklist) => checklist.id !== userChecklistId,
      ),
    }),

    checkUserDay: ({ userChecklistId, date, isChecked }) => {
      const checklists = state.checklists;
      const checklist = checklists.find(
        (checklist) => checklist.id === userChecklistId,
      );
      let days = checklist ? checklist.days : [];

      if (isChecked) {
        days.push(date);
      } else {
        days = days.filter((day) => !isSameDate(day, date));
      }

      return {
        ...state,
        checklists: checklists.map((checklist) =>
          checklist.id !== userChecklistId
            ? checklist
            : { ...checklist, days: [...days] },
        ),
      };
    },
    updateChecklistNotification: (userChecklist) => {
      return {
        ...state,
        checklists: state.checklists.map((checklist) =>
          checklist.id !== userChecklist.id ? checklist : { ...userChecklist },
        ),
      };
    },
    default: () => state,
  });
}

export default userReducer;
