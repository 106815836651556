import React, {memo, useMemo} from 'react';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import {AppearanceSchemeType} from '@vkontakte/vk-bridge';
import ConfigProvider from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProvider';
import {ThemeProviderProps} from './types';
import {useSelector} from '../../hooks';
import {extendThemeWithOS} from './utils';
import {brightLightTheme, spaceGrayTheme, Theme} from '../../theme';

/**
 * Theme provider for application
 * @type {React.NamedExoticComponent<Props>}
 */
export const ThemeProvider = memo(
  function ThemeProvider(props: ThemeProviderProps) {
    const {children} = props;

    const {scheme, os} = useSelector((state) => ({
      scheme: state.config.scheme,
      os: state.device.os,
    }));

    const {brightTheme, darkTheme} = useMemo(
      () => ({
        brightTheme: extendThemeWithOS(brightLightTheme, os),
        darkTheme: extendThemeWithOS(spaceGrayTheme, os),
      }),
      [os],
    );

    const themesMap: Record<AppearanceSchemeType, Theme> = useMemo(
      () => ({
        client_light: brightTheme,
        bright_light: brightTheme,
        client_dark: darkTheme,
        space_gray: darkTheme,
      }),
      [brightTheme, darkTheme],
    );

    const theme = useMemo(() => themesMap[scheme], [themesMap, scheme]);

    return (
      <ConfigProvider scheme={scheme}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </ConfigProvider>
    );
  },
);
