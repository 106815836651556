import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import vkBridge from '@vkontakte/vk-bridge';
import View, {ViewProps} from '@vkontakte/vkui/dist/components/View/View';
import {useStrictRouteMatch} from '../../hooks';
import {AdminPanel} from '../panels/AdminPanel';
import {useLayoutContext} from '../LayoutContextProvider';
import {AdminCategoryPanel} from '../panels/AdminCategoryPanel';

export enum AdminViewPanels {
  ADMIN = '/admin',
  ADMIN_CATEGORY = '/admin/category',
}

export const AdminView: React.FC<Partial<ViewProps>> = ({...viewProps}) => {
  const history = useHistory();
  const activePanel = useStrictRouteMatch(
    '/admin/category/',
    AdminViewPanels,
    AdminViewPanels.ADMIN,
  );
  console.log(history.location.pathname);

  const [swipeHistory, setSwipeHistory] = useState([AdminViewPanels.ADMIN]);

  const goBack = () => {
    const newSwipeHistory = [...swipeHistory];
    newSwipeHistory.pop();
    const newActivePanel = newSwipeHistory[newSwipeHistory.length - 1];
    if (newActivePanel === AdminViewPanels.ADMIN) {
      vkBridge.send('VKWebAppDisableSwipeBack');
    }
    setSwipeHistory(newSwipeHistory);
    history.push(newActivePanel);
  };

  const goForward = (newActivePanel: AdminViewPanels) => {
    const newSwipeHistory = [...swipeHistory];
    newSwipeHistory.push(newActivePanel);
    if (activePanel === AdminViewPanels.ADMIN) {
      vkBridge.send('VKWebAppEnableSwipeBack');
    }
    setSwipeHistory(newSwipeHistory);
    history.push(newActivePanel);
  };

  return (
    <View
      {...viewProps}
      onSwipeBack={goBack}
      activePanel={activePanel}
      history={swipeHistory}
    >
      <AdminPanel
        id={AdminViewPanels.ADMIN}
        onBack={goBack}
        separator={false}
      />
      <AdminCategoryPanel
        id={AdminViewPanels.ADMIN_CATEGORY}
        onBack={goBack}
        separator={false}
      />
    </View>
  );
};
