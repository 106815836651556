import React, {memo} from 'react';
import {makeStyles} from '@material-ui/styles';

import {Panel, View} from '@vkontakte/vkui';
import {MainView} from '../views/MainView';
import {OnboardPanel} from '../panels/OnboardPanel';
import LayoutContextProvider from '../LayoutContextProvider/LayoutContextProvider';
import {StatusBar} from '../tools/StatusBar';
import {useStorageValue} from '../../hooks';
import {StorageFieldEnum} from '../../types';
import {Theme} from '../../theme/types';

enum AppActivePanel {
  ONBOARD = 'app-onbord',
  MAIN = 'app-main',
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamilyTT,
    background: theme.header.background,
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& .ModalRoot__viewport ': {
      top: '0 !important',
    }
  },
}));

/**
 * Visual entry of application
 * @type {React.NamedExoticComponent<object>}
 */
export const App = memo(function App() {
  const classes = styles();
  document.body.className = classes.root;

  const [obc] = useStorageValue(StorageFieldEnum.OnboardingCompleted);
  const activePanel = obc ? AppActivePanel.MAIN : AppActivePanel.ONBOARD;

  return (
    <LayoutContextProvider>
      <View activePanel={activePanel}>
        <OnboardPanel id={AppActivePanel.ONBOARD} separator={false}/>
        <Panel id={AppActivePanel.MAIN} separator={false}>
          <StatusBar/>
          <MainView/>
        </Panel>
      </View>
    </LayoutContextProvider>
  );
});
