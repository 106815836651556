import React from 'react';
import c from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import VKActionSheetItem, {
  ActionSheetItemProps as VKActionSheetItemProps,
} from '@vkontakte/vkui/dist/components/ActionSheetItem/ActionSheetItem';
import {Theme} from '../../../theme/types';

const useStyles = makeStyles<Theme>((theme) => ({
  actionSheetItem: {
    '&.ActionSheetItem--cancel .ActionSheetItem__container': {
      color: theme.palette.primary,
    },
    '& .ActionSheetItem__container': {
      color: '#000',
    },
    '& .ActionSheetItem__before': {
      color: theme.palette.primary,
    },
  },
}));

const ActionSheetItem: React.FC<VKActionSheetItemProps> = ({className, children, ...props}) => {
  const mc = useStyles();
  return (
    <VKActionSheetItem {...props} className={c(className, mc.actionSheetItem)}>
      {children}
    </VKActionSheetItem>
  );
};

export default ActionSheetItem;
