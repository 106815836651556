import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

import logo from '../../assets/logo.png';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImg: {
    width: '80px',
  },
}, { name: 'AppLoadingView' });

/**
 * Вью которая отображается в случае, когда приложение загружается.
 * @type {React.NamedExoticComponent<object>}
 */
export const AppLoadingView = memo(() => {
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <div className={mc.logo}>
        <img
          src={logo}
          className={mc.logoImg}
          alt='Загрузка приложения...'
        />
      </div>
    </div>
  );
});
