import React, { useState } from 'react';
import { ModalPage, PanelHeader, Div, Button, FormLayoutGroup, File } from '@vkontakte/vkui';
import { ModalProps, ModalId } from '../../atomic/modals/types';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { CreateIconMutation, createIconMutation } from 'types/gql';
import { useSnackbar, useActions } from 'hooks';
import { appActions } from 'store/reducers/app';
import CameraIcon from '@vkontakte/icons/dist/28/camera_outline';

const styles = makeStyles(
  {
    root: {
      paddingTop: 30,
    },
    hiddenIconFileInput: {
      width: '100%',
      borderRadius: 0,
      height: '100%',
      padding: 0,
      margin: '0 !important',
      opacity: '0 !important',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    fileIconContainer: {
      height: 120,
      width: 120,
      background: '#F0F2F5',
      position: 'relative',
      marginLeft: 12,
      padding: 0,
      borderRadius: 4,
      cursor: 'pointer',
    },
    cameraIconContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#2A5885',
    },
    btnsContainer: {
      paddingTop: 30,
      '& > button': {
        marginRight: 20,
      },
    },
  },
  { classNamePrefix: 'AdminDeleteIcon' },
);

export const AdminAddIconModal: React.FC<ModalProps<ModalId.ADMIN_ICON_ADD>> = ({ onClose, ...modalProps }) => {
  const classes = styles();
  const openSnackbar = useSnackbar();

  const addIcon = useActions(appActions.addIcon);

  const [createIcon, { loading }] = useMutation<CreateIconMutation, CreateIconMutation.Arguments>(createIconMutation);

  const [loadedIcon, setLoadedIcon] = useState<string>('');

  const addHandler = async () => {
    await createIcon({
      variables: {
        params: {
          image: null,
          imageBase64: loadedIcon,
          imageUrl: null,
        },
      },
    })
      .then(({ data }) => {
        if (data?.createIcon) {
          addIcon({
            id: data.createIcon.id,
            imageUrl: data.createIcon.imageUrl,
            weight: data.createIcon.weight,
          });
          onClose();
        }
      })
      .catch((e) => openSnackbar({ text: e.message }));
  };

  const readImage = (file: any) => {
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      setLoadedIcon(event.target.result);
    });

    reader.readAsDataURL(file);
  };

  const onIconFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    file && readImage(file);
  };

  const iconComponent = loadedIcon ? (
    <img
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 4,
        border: '1px solid #99a2ad',
      }}
      alt={'иконка чеклиста'}
      src={loadedIcon}
    ></img>
  ) : (
    <>
      <CameraIcon style={{ color: '#7996B2' }} height={60} width={64} />
      <div>Загрузить</div>
    </>
  );
  return (
    <ModalPage title="Добавить иконку" {...modalProps} header={<PanelHeader>Добавить иконку</PanelHeader>}>
      <Div className={classes.root}>
        <FormLayoutGroup top={'Загрузить свою иконку'}>
          <Div className={classes.fileIconContainer}>
            <div className={classes.cameraIconContainer}>{iconComponent}</div>
            <File
              accept="image/*"
              onChange={(e) => {
                onIconFileChangeHandler(e);
              }}
              className={classes.hiddenIconFileInput}
              type="file"
            />
          </Div>
        </FormLayoutGroup>
      </Div>
      <Div className={classes.btnsContainer}>
        <Button disabled={loadedIcon.length <= 0} onClick={addHandler} size={'l'} mode={'primary'}>
          {loading ? 'Добавляю иконку...' : 'Добавить иконку'}
        </Button>
        <Button mode={'secondary'} onClick={() => onClose()} size={'l'}>
          Отмена
        </Button>
      </Div>
    </ModalPage>
  );
};
