import React from 'react';

import Icon28EditOutline from '@vkontakte/icons/dist/28/edit_outline';
import DeleteOutline from '@vkontakte/icons/dist/28/delete_outline';

import ActionSheet from '@overrided-vkui/ActionSheet/ActionSheet';
import { useModal } from '../../../hooks';
import { ModalId } from '../modals/types';
import { PopoutId, PopoutProps } from './types';

const ChecklistContextActionSheet: React.FC<PopoutProps<PopoutId.CHECKLIST_CONTEXT>> = ({
  onClose,
  payload: { checklist },
}) => {
  const openCheckListEdit = useModal(ModalId.CHECKLIST_EDIT);
  const openCheckListConfirmDelete = useModal(ModalId.CHECKLIST_DELETE);

  return (
    <ActionSheet onClose={onClose}>
      <ActionSheet.Item
        onClick={() => openCheckListEdit({ checklist })}
        before={<Icon28EditOutline style={{ color: '#2787f5' }}/>}
      >
        <span style={{ color: '#2787f5' }}>Редактировать</span>
      </ActionSheet.Item>
      <ActionSheet.Item
        onClick={() => openCheckListConfirmDelete({ checklist })}
        before={<DeleteOutline style={{ color: '#e64646' }}/>}
      >
        <span style={{ color: '#e64646' }}>Удалить чеклист</span>
      </ActionSheet.Item>
    </ActionSheet>
  );
};

export default ChecklistContextActionSheet;
