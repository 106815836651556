import React, { useState, useEffect } from "react";
import { ModalProps, ModalId } from "../../atomic/modals/types";

import {
  ModalPage,
  PanelHeader,
  PanelHeaderButton,
  FormLayout,
  FormLayoutGroup,
  Input,
  Button,
} from "@vkontakte/vkui";
import { makeStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/react-hooks";
import {
  CreateChecklistCategoryMutation,
  createChecklistCategoryMutation,
} from "../../../types/gql";
import { useSnackbar, useActions } from "../../../hooks";
import { appActions } from "../../../store/reducers/app";

const styles = makeStyles(
  {
    bottomBtnsGroup: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  { classNamePrefix: "AdminAddCategoryModal" }
);
export const AdminAddCategoryModal: React.FC<ModalProps<
  ModalId.ADMIN_CATEGORY_ADD
>> = ({ onClose, ...modalProps }) => {
  const classes = styles();
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const showErrorSnackbar = useSnackbar();

  const createChecklistCategoryAction = useActions(
    appActions.createChecklistCategory
  );

  const [createChecklistCategory, { loading }] = useMutation<
    CreateChecklistCategoryMutation,
    CreateChecklistCategoryMutation.Arguments
  >(createChecklistCategoryMutation);

  const addCategoryHandler = () => {
    return createChecklistCategory({
      variables: {
        params: {
          title: categoryTitle,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          createChecklistCategoryAction({
            id: data.createChecklistCategory.id,
            title: data.createChecklistCategory.title,
            weight: data.createChecklistCategory.weight,
          });
        }
        setCategoryTitle("");
        onClose();
        console.log(data);
      })
      .catch((e) => showErrorSnackbar({ text: e.message }));
  };

  return (
    <ModalPage
      title={"Добавить категорию"}
      {...modalProps}
      header={
        <PanelHeader
          left={
            <PanelHeaderButton onClick={onClose}> Отменить </PanelHeaderButton>
          }
        >
          Добавить категорию
        </PanelHeader>
      }
    >
      <FormLayout>
        <FormLayoutGroup top={"Название"}>
          <Input
            type="text"
            name="title"
            placeholder={"Напишите название"}
            value={categoryTitle}
            onChange={(e) => setCategoryTitle(e.target.value)}
          />
        </FormLayoutGroup>
        <div className={classes.bottomBtnsGroup}>
          <Button
            disabled={categoryTitle.length <= 0}
            onClick={addCategoryHandler}
          >
            {loading ? "Добавляю..." : "Добавить"}
          </Button>
        </div>
      </FormLayout>
    </ModalPage>
  );
};
