import {
  ttCommonsRegular,
  ttCommonsMedium,
  ttCommonsDemiBold,
  sfProDisplayRegular,
  sfProDisplayMedium,
  sfProTextRegular,
  sfProTextMedium,
  sfProTextDemiBold,
} from './fonts';
import {makeStyles} from '@material-ui/styles';
import {memo} from 'react';

const useStyles = makeStyles(() => ({
  '@global': {
    '@font-face': [
      {
        fontFamily: 'TT Commons',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `url("${ttCommonsRegular}") format("woff")`,
      },
      {
        fontFamily: 'TT Commons',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url("${ttCommonsMedium}") format("woff")`,
      },
      {
        fontFamily: 'TT Commons',
        fontStyle: 'normal',
        fontWeight: 600,
        src: `url("${ttCommonsDemiBold}") format("woff")`,
      },
      {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `url("${sfProTextRegular}") format("woff2")`,
      },
      {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url("${sfProTextMedium}") format("woff2")`,
      },
      {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        src: `url("${sfProTextDemiBold}") format("woff2")`,
      },
      {
        fontFamily: 'SF Pro Display',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `url("${sfProDisplayRegular}") format("woff2")`,
      },
      {
        fontFamily: 'SF Pro Display',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url("${sfProDisplayMedium}") format("woff2")`,
      },
    ],
  },
}));

export const GlobalStyleSheet = memo(() => {
  useStyles();
  return null;
});
