import React from 'react';

import { FormLayoutGroup, ModalCard } from '@vkontakte/vkui';
import Div from '../vkui/Div';
import Button from '../vkui/Button';

import { useErrorClose } from '../../../hooks';
import { ModalId, ModalProps } from './types';

const ErrorRetryModal: React.FC<ModalProps<ModalId.ERROR_RETRY>> = (
  { payload: { text, onRetry, withoutCloseOnSuccess }, ...modalProps },
) => {

  const errorClose = useErrorClose();

  const closeModal = () => {
    errorClose();
  };

  const onRetryClick = () => {
    onRetry().then(() => {
      if (!withoutCloseOnSuccess) {
        closeModal();
      }
    });
  };

  return (
    <ModalCard header="Ошибка" {...modalProps} onClose={closeModal}>
      <Div>
        <FormLayoutGroup>
          <p>{text}</p>
          <Button
            onClick={onRetryClick}
            stretched size='xl'
          >
            Попробовать еще раз
          </Button>
          <Button
            onClick={closeModal} mode="outline"
            stretched size='xl'
          >
            Отмена
          </Button>
        </FormLayoutGroup>
      </Div>
    </ModalCard>
  );
};

export default ErrorRetryModal;
