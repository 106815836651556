import React, {useEffect, useRef, useCallback} from 'react';
import {withModalRootContext} from '@vkontakte/vkui';
import {ModalId, ModalPayload, ModalProps} from '../../atomic/modals/types';

interface ModalWrapperProps<K extends ModalId> {
  id: K;
  visible: boolean;
  onClose(): void;
  payload: ModalPayload[K];
  settlingHeight?: number;
  dynamicContentHeight?: boolean;
  modal: React.FC<ModalProps<K>>;
}

function ModalComponentWrapper<K extends ModalId = any>(
  {
    onClose,
    visible,
    payload,
    modal: Modal,
    updateModalHeight,
    ...restProps
  }: ModalWrapperProps<K> & { updateModalHeight?(): void },
) {
  const used = useRef(false);

  // const windowHeight = useWindowSizes().innerHeight;

  useEffect(() => {
    if (visible && updateModalHeight) {
      updateModalHeight();
    }
  }, [visible, updateModalHeight]);

  const wrappedOnClose = useCallback(() => {
    if (!used.current && visible) {
      used.current = true;
      onClose();
    }
  }, [visible, used, onClose]);

  // eslint-disable-next-line
  return <Modal onClose={wrappedOnClose} payload={payload} updateModalHeight={updateModalHeight!} {...restProps} />;
}

export default withModalRootContext(ModalComponentWrapper);
