import React, {memo, ReactNode, ReactNodeArray} from 'react';
import ApolloClient from 'apollo-client';
import {ApolloProvider as ReactApolloProvider} from '@apollo/react-hooks';

export interface ApolloProviderProps<TCache> {
  client: ApolloClient<TCache>;
  children: ReactNode | ReactNodeArray | null;
}

export const ApolloProvider = memo(
  function ApolloProvider(props: ApolloProviderProps<any>) {
    const {
      client, children,
    } = props;

    return (
      <ReactApolloProvider client={client}>
        {children}
      </ReactApolloProvider>
    );
  },
);
