import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, matchPath } from 'react-router-dom';

import {
  Panel, PanelHeader, List, Cell,
  Title, Text, PanelHeaderButton, Div,
} from '@vkontakte/vkui';
import { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';
import SettingsIcon from '@vkontakte/icons/dist/24/settings';
import BackIcon from '@vkontakte/icons/dist/24/browser_back';

import { AdminViewPanels } from 'components/views/AdminView';
import { getCheckListsWithMultipleCategories } from 'components/Admin/AdminModals/utils/formateChecklists';
import { CheckListWithTags } from 'components/Admin/CheckLists';

import { useSelector, useModal, useSnackbar, useActions } from 'hooks';
import { appActions } from 'store/reducers/app';

import { ModalId } from 'components/atomic/modals/types';
import { Theme } from 'theme';
import {
  UpdateChecklistWeightMutation,
  updateChecklistWeightMutation,
} from 'types/gql/updateChecklistWeightMutation';

interface AdminPanelProps extends PanelProps {
  onBack(): void;
}

export const ADMIN_FIXED_LAYOUT_HEIGHT = 53;
const styles = makeStyles((theme: Theme) => ({
    root: {},
    categoriesListContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 18,
      '&:not(:first-child)': {
        marginTop: 18,
      },
      '& svg': {
        color: '#AEB7C2',
        cursor: 'pointer',
        '&:active': {
          opacity: 0.7,
        },
      },
    },
    cell: {
      borderBottom: '1px solid #E7E8EC',
    },
    categoriesList: {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      marginRight: 12,
      '&>li': {
        height: 20,
        backgroundColor: '#EBEDF0',
        color: '#6D7885',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px',
        borderRadius: 5,
        fontSize: 15,
        lineHeight: 15,
        cursor: 'pointer',
        '&:active': {
          opacity: 0.7,
        },
        '&:not(:last-child)': {
          marginRight: 8,
        },
      },
    },
    checklistTitle: {
      marginBottom: 5,
    },
    checklistDesc: {
      color: '#828282',
      fontSize: 16,
      marginBottom: 15,
    },
  }),
  { classNamePrefix: 'AdminChecklists' },
);

export const AdminCategoryPanel = ({ onBack, ...panelProps }: AdminPanelProps) => {
  const classes = styles();
  const snackbar = useSnackbar();
  const openEditChecklistModal = useModal(ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT);

  const history = useHistory();
  const getParams = (pathname: string): { id: string } | {} => {
    const matchProfile = matchPath(pathname, {
      path: `${AdminViewPanels.ADMIN_CATEGORY}/:id`,
    });
    return (matchProfile && matchProfile.params) || {};
  };
  const historyParams = getParams(history.location.pathname) as { id: string };


  const [updateChecklistWeight] =
    useMutation<UpdateChecklistWeightMutation, UpdateChecklistWeightMutation.Arguments>(updateChecklistWeightMutation);
  const updateChecklistWeightAction = useActions(appActions.updateChecklistWeight);
  const checklistsCategories = useSelector((state) => state.app.checklistCategories);

  const currentCategory = checklistsCategories.find(
    (category) => category.id === historyParams.id,
  );
  const title = currentCategory?.title;

  const checkListsWithMultipleCategories = useMemo(
    () =>
      getCheckListsWithMultipleCategories(
        checklistsCategories,
        true,
        currentCategory?.id,
      ),
    [checklistsCategories, currentCategory],
  ).sort((a, b) => b.weight - a.weight);

  const [checklists, setChecklists] = useState<CheckListWithTags[]>(checkListsWithMultipleCategories);

  useEffect(() => {
    setChecklists(checkListsWithMultipleCategories);
  }, [checkListsWithMultipleCategories]);

  const onChecklistWeightChange = (
    from: number,
    to: number,
    id: string,
    weight: number,
  ) => {
    console.log(from, to, id);
    updateChecklistWeight({
      variables: {
        categoryId: historyParams.id,
        checklistId: id,
        weight,
      },
    })
      .then(() => {
        updateChecklistWeightAction({
          categoryId: historyParams.id,
          checklistId: id,
          newWeight: weight,
        });
        snackbar({ text: 'Позиция категории обновлена' });
      })
      .catch((e) => snackbar({ text: e.message }));
  };

  return (
    <Panel {...panelProps}>
      <PanelHeader
        left={
          <PanelHeaderButton
            style={{ color: '#818C99' }}
            onClick={() => history.goBack()}
          >
            <BackIcon/>
            Назад
          </PanelHeaderButton>
        }
      >
        {title}
      </PanelHeader>
      {checklists.length <= 0 ? (
        <Div>В этой категории еще нет чеклистов</Div>
      ) : (
        <List className={classes.root}>
          {checklists.map(
            (
              {
                id,
                icon,
                trackerTitle,
                categories,
                trackerDescription,
                usersCount,
                weight,
              },
              i,
            ) => {
              return (
                <Cell
                  className={classes.cell}
                  draggable={checklists.length > 1}
                  key={i}
                  onDragFinish={({ from, to }) => {
                    const newChecklistsList = [...checklists];
                    const newWeight = to && checklists[to].weight;

                    newChecklistsList.splice(from, 1);
                    newChecklistsList.splice(to, 0, checklists[from]);

                    to >= 0 && to !== from && setChecklists(newChecklistsList);
                    to >= 0 && to !== from && onChecklistWeightChange(from, to, id, newWeight);
                  }}
                >
                  <Title
                    className={classes.checklistTitle}
                    level={'2'}
                    weight={'medium'}
                  >
                    {trackerTitle}
                  </Title>
                  <Text className={classes.checklistDesc} weight={'regular'}>
                    {trackerDescription}
                  </Text>
                  <div className={classes.categoriesListContainer}>
                    <ul className={classes.categoriesList}>
                      {categories.map((category) => (
                        <li key={category.id}>{category.title}</li>
                      ))}
                    </ul>
                    <SettingsIcon
                      onClick={() =>
                        openEditChecklistModal({
                          checklist: {
                            trackerTitle,
                            trackerDescription,
                            categories,
                            id,
                            icon,
                            defaultTitle: trackerTitle,
                            defaultDescription: trackerDescription,
                            usersCount,
                            weight,
                          },
                          categoryId: historyParams.id,
                        })
                      }
                      width={20}
                      color={'#AEB7C2'}
                    />
                  </div>
                </Cell>
              );
            },
          )}
        </List>
      )}
    </Panel>
  );
};
