import React from 'react';
import VKRoot, {RootProps} from '@vkontakte/vkui/dist/components/Root/Root';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import c from 'classnames';

const useStyles = makeStyles({
  root: {
    '& .ModalPage__in': {
      width: '100%'
    },
  },
  root_blur: {
    '& .ModalRoot__mask, & .PopoutWrapper__mask': {
      backdropFilter: 'blur(3px)',
    },
  },
  root_light: {
    '& .ModalRoot__mask, & .PopoutWrapper__mask': {
      background: 'none',
    },
    '& .ModalPage__in': {
      boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.08)',
    },
  },
});

type Props = RootProps & { backStyle?: 'default' | 'blur' | 'light' };

const Root: React.FC<Props> = ({modal, backStyle, ...props}) => {
  const mc = useStyles();

  return (
    <VKRoot
      className={c(mc.root, {
        [mc.root_blur]: backStyle === 'blur',
        [mc.root_light]: backStyle === 'light',
      })}
      modal={modal}
      {...props}
    />
  );
};

export default Root;
