import React, { useState, useEffect } from 'react';
import { Div, List, Cell, Text, Separator } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useModal, useSnackbar, useActions } from 'hooks';
import { sfProDisplayMedium } from 'components/GlobalStyleSheet/fonts';
import { Theme } from 'theme';
import { ModalId } from 'components/atomic/modals/types';
import { useHistory } from 'react-router-dom';
import { AdminViewPanels } from 'components/views/AdminView';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  UpdateChecklistCategoryMutation,
  updateChecklistCategoryMutation,
  ChecklistCategoriesQuery,
  checklistCategoriesQuery,
} from 'types/gql';
import { AppLoadingView } from 'components/views/AppLoadingView';
import { checklistCategoryActions } from 'store/reducers/categories';
import { appActions } from 'store/reducers/app';

export const ADMIN_FIXED_LAYOUT_HEIGHT = 53;
const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: `${ADMIN_FIXED_LAYOUT_HEIGHT}px`,
    },
    cell: {
      borderBottom: '1px solid #E7E8EC',
      '& .Cell__in': {
        flexDirection: 'row-reverse',
      },
      '& .Cell__indicator + .Cell__aside': {
        paddingBottom: 8,
        paddingRight: 17,
        paddingLeft: '0px',
      },
      '& .Cell__children': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: theme.typography.fontWeightDemiBold,
      },
    },
    cell__after: {
      display: 'flex',
    },
    cell__after__text: {
      color: theme.palette.blue,
      cursor: 'pointer',
      '&:active': {
        opacity: 0.7,
      },
    },
    cell__after__middleDot: {
      padding: '0px 7px',
    },
  }),
  { classNamePrefix: 'AdminCategories' },
);
export const AdminCategories = () => {
  const classes = styles();
  const blue_600 = '#2a5885';
  const categories = useSelector((state) => state.app.checklistCategories);

  const [categoriesList, setCategoriesList] = useState<ChecklistCategoriesQuery.checklistCategories>(
    categories.sort((a, b) => b.weight - a.weight),
  );

  useEffect(() => {
    const sortedCategoriesList = categories.sort((a, b) => b.weight - a.weight);
    setCategoriesList(sortedCategoriesList);
  }, [categories]);

  const updateCategoryWeight = useActions(appActions.updateCategoryWeight);

  const snackbar = useSnackbar();
  const [updateChecklistCategory] = useMutation<
    UpdateChecklistCategoryMutation,
    UpdateChecklistCategoryMutation.Arguments
  >(updateChecklistCategoryMutation);

  const openEditCategoryModal = useModal(ModalId.ADMIN_CATEGORY_EDIT);
  const editHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const categoryId = e.currentTarget.dataset.id;
    const category = categories.find((category) => category.id === categoryId);
    category && openEditCategoryModal({ category });
  };

  const onWeightChangeHandler = (from: number, to: number, id: string, title: string, weight: number) => {
    console.log(from, to, id);

    updateChecklistCategory({
      variables: {
        params: {
          categoryId: id,
          title,
          weight,
        },
      },
    })
      .then((data) => {
        if (data.data?.updateChecklistCategory) {
          updateCategoryWeight({ categoryId: id, newWeight: weight });
          snackbar({ text: 'Позиция категории обновлена' });
        } else {
          snackbar({ text: 'Не удалось обновить позицию' });
        }
      })
      .catch((e) => snackbar({ text: e.message }));
  };

  const history = useHistory();

  return (
    <Div className={classes.root}>
      <List>
        {categoriesList.map((category) => (
          <Cell
            key={category.id}
            className={classes.cell}
            draggable
            onDragFinish={({ from, to }) => {
              const newCategoriesList = [...categoriesList];
              newCategoriesList.splice(from, 1);
              newCategoriesList.splice(to, 0, categoriesList[from]);

              to >= 0 && to !== from && setCategoriesList(newCategoriesList);
              const newWeight = to && categoriesList[to].weight;
              to >= 0 && to !== from && onWeightChangeHandler(from, to, category.id, category.title, newWeight);
            }}
          >
            {category.title}
            <Div className={classes.cell__after}>
              <Text
                data-id={category.id}
                className={classes.cell__after__text}
                weight={'regular'}
                style={{ color: blue_600 }}
                onClick={(e) => editHandler(e)}
              >
                Редактировать
              </Text>
              <Text className={classes.cell__after__middleDot} weight={'regular'}>
                &#183;
              </Text>
              <Text
                onClick={() => history.push(`${AdminViewPanels.ADMIN_CATEGORY}/${category.id}`)}
                className={classes.cell__after__text}
                style={{ color: blue_600 }}
                weight={'regular'}
              >
                Перейти
              </Text>
            </Div>
          </Cell>
        ))}
      </List>
    </Div>
  );
};
