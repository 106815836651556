import {Theme} from '../types';
import {themeDefaults} from './themeDefaults';

import {
  buttonBrightLightTheme,
  formControlBrightLightTheme,
  inputBrightLightTheme,
  linkBrightLightTheme, panelBrightLightTheme, panelHeaderBrightLightTheme,
  selectBrightLightTheme,
  separatorBrightLightTheme, viewBrightLightTheme,
} from '../component-themes';

export const brightLightTheme: Theme = {
  ...themeDefaults,
  components: {
    Button: buttonBrightLightTheme,
    FormControl: formControlBrightLightTheme,
    Input: inputBrightLightTheme,
    Link: linkBrightLightTheme,
    Panel: panelBrightLightTheme,
    PanelHeader: panelHeaderBrightLightTheme,
    Select: selectBrightLightTheme,
    Separator: separatorBrightLightTheme,
    View: viewBrightLightTheme,
  },
  palette: {
    bodyBackground: '#ffffff',
    bodyText: '#000000',
    text: {
      primary: '#000000',
      secondary: '#818c99',
    },
    card: {
      background: '#f2f3f5',
      backgroundDrop: '0 0 16px 0 rgba(0, 0, 0, 0.4)',
      label: '#000000',
      description: '#818c99',
      text: '#6d7885',
    },
    progress: {
      background: '#e1e3e6',
      active: '#3f8ae0',
      done: '#4bb34b',
    },
    icon: {
      active: '#99a2ad',
      inactive: '#aeb7c2',
      blue: '#3f8ae0',
      red: '#e64646',
      checked: '#3f8ae0',
    },
    share: {
      background: '#ffffff',
      label: '#818c99',
      description: '#000000',
      icon: '#aeb7c2'
    },
    statusBar: {
      default: {
        color: '#ffffff',
        style: 'dark',
      },
    },
  },
};
