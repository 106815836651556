import React, { useState, useEffect } from 'react';
import { Div, List, Cell, Text, Button } from '@vkontakte/vkui';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useModal, useSnackbar, useActions } from 'hooks';
import { Theme } from 'theme';
import { ModalId } from '../atomic/modals/types';
import { useMutation } from '@apollo/react-hooks';
import { UpdateIconMutation, updateIconMutation } from 'types/gql/updateIconMutation';
import { appActions } from 'store/reducers/app';

export const ADMIN_FIXED_LAYOUT_HEIGHT = 53;

const styles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: `${ADMIN_FIXED_LAYOUT_HEIGHT}px`,
  },
  iconContainer: {
    display: 'inline-block',
    width: 35,
    height: 35,
    border: '1px solid #a9a0a0',
    borderRadius: '4px',
    padding: 6,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  cell: {
    '& .Cell__children': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: theme.typography.fontWeightDemiBold,
    },
    '& .Cell__in': {
      flexDirection: 'row-reverse',
    },
    '& .Cell__indicator + .Cell__aside': {
      paddingBottom: 8,
      paddingRight: 17,
      paddingLeft: '0px',
    },
  },
}));
export const AdminIcons = () => {
  const classes = styles();
  const icons = useSelector((state) => state.app.icons);
  const openDeleteModal = useModal(ModalId.ADMIN_ICON_DELETE);
  const snackbar = useSnackbar();

  const [iconsList, setIconsList] = useState(icons.sort((a, b) => b.weight - a.weight));

  useEffect(() => {
    const sortedIcons = icons.sort((a, b) => b.weight - a.weight);
    setIconsList(sortedIcons);
  }, [icons]);

  const [updateIcon] = useMutation<UpdateIconMutation, UpdateIconMutation.Arguments>(updateIconMutation);
  const updateIconAction = useActions(appActions.updateIconWeight);

  const onWeightChangeHandler = (weight: number, iconId: string) => {
    const imageUrl = icons.find((icon) => icon.id === iconId)?.imageUrl!;
    updateIcon({
      variables: {
        params: {
          iconId,
          weight,
          imageUrl,
        },
      },
    })
      .then(({ data }) => {
        if (data?.updateIcon) {
          updateIconAction({ id: iconId, imageUrl, weight });
          snackbar({ text: 'Позиция иконки обновлена' });
        } else {
          snackbar({ text: 'Не удалось обновить позицию' });
        }
      })
      .catch((e) => {
        snackbar({ text: e.message });
      });
  };
  const deleteHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const iconId = e.currentTarget.dataset.id;
    openDeleteModal({ icon: icons.find((icon) => icon.id === iconId)! });
  };
  return (
    <List className={classes.root}>
      {iconsList.map((icon) => (
        <Cell
          draggable
          className={classes.cell}
          key={icon.id}
          onDragFinish={({ from, to }) => {
            const newIconsList = [...iconsList];
            newIconsList.splice(from, 1);
            newIconsList.splice(to, 0, iconsList[from]);
            const newWeight = iconsList[to] && iconsList[to].weight;
            console.log(newWeight, iconsList[to] && iconsList[to].weight);

            to >= 0 && to !== from && setIconsList(newIconsList);
            to >= 0 && to !== from && onWeightChangeHandler(newWeight, icon.id);
          }}
        >
          <div className={classes.iconContainer}>
            <img alt={'иконка'} src={icon.imageUrl} />
          </div>
          <Button data-id={icon.id} onClick={(e) => deleteHandler(e)} mode={'destructive'}>
            Удалить
          </Button>
        </Cell>
      ))}
    </List>
  );
};
