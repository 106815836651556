import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Root from '@overrided-vkui/Root/Root';
import ModalComponentWrapper from '@overrided-vkui/ModalWrapper/ModalWrapper';
import ModalRoot from '@overrided-vkui/ModalRoot/ModalRoot';
import Snackbar, { SnackbarProps } from '@overrided-vkui/Snackbar/Snackbar';

import { parseQueryParams, stringifyQueryParams } from '../../utils';
import { modalComponents, ModalId, ModalPayload } from '../atomic/modals/types';
import { popoutComponents, PopoutId, PopoutPayload } from '../atomic/popouts/types';
import layoutContext from './context';

export interface QueryParams {
  modal: ModalId | null;
  popout: PopoutId | null;
  checklist?: string | null;
}

const LayoutContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const searchKeyValues = parseQueryParams<QueryParams>(location.search);

  const clearQuery = useCallback((key: keyof QueryParams) => {
    searchKeyValues[key] = null;
    history.replace({ search: stringifyQueryParams(searchKeyValues) });
  }, [history, searchKeyValues]);

  const errorClose = useCallback(() => {
    searchKeyValues['popout'] = null;
    searchKeyValues['modal'] = null;
    history.replace({ search: stringifyQueryParams(searchKeyValues) });
  }, [history, searchKeyValues]);

  /* Модалки */
  let activeModal = searchKeyValues.modal;
  const activeModalPayloadRef = useRef<Partial<ModalPayload>>({});

  if (activeModal && !activeModalPayloadRef.current[activeModal]) {
    activeModal = null;
  }

  const setModalQuery = useCallback(<T extends keyof QueryParams>(key: T, value: QueryParams[T]) => {
    clearQuery('popout');
    history.push({
      search: stringifyQueryParams({ ...searchKeyValues, [key]: value }),
    });
  }, [clearQuery, history, searchKeyValues]);

  const openModal = useCallback(
    <K extends keyof ModalPayload>
    (modalId: K, payload: ModalPayload[K], override = false) => {
      activeModalPayloadRef.current[modalId] = payload;

      if (override) {
        clearQuery('modal');
      }

      setModalQuery('modal', modalId);
    }, [setModalQuery, clearQuery]);

  const closeModal = useCallback(() => history.goBack(), [history]);

  const modal = (
    <ModalRoot activeModal={activeModal || null}>
      {Object.entries(modalComponents).map(([id, ModalComponent]) => (
        <ModalComponentWrapper
          key={id}
          id={id as ModalId}
          visible={!!activeModal && id === activeModal}
          payload={activeModalPayloadRef.current[id as ModalId] as any}
          // BUG: не работает, если onClose, settlingHeight, dynamicContentHeight поместить внутри компонента
          onClose={closeModal}
          settlingHeight={100}
          dynamicContentHeight
          modal={ModalComponent as any}
        />
      ))}
    </ModalRoot>
  );

  /* Попауты */
  let activePopout = searchKeyValues.popout;
  const activePopoutPayloadRef = useRef<Partial<PopoutPayload>>({});

  if (activePopout && !activePopoutPayloadRef.current[activePopout]) {
    activePopout = null;
  }

  const setPopoutQuery = useCallback(<T extends keyof QueryParams>(key: T, value: QueryParams[T]) => {
    history.replace({
      search: stringifyQueryParams({ ...searchKeyValues, [key]: value }),
    });
  }, [clearQuery, history, searchKeyValues]);

  const openPopout = useCallback(<K extends keyof PopoutPayload>(popoutId: K, payload: PopoutPayload[K]) => {
    activePopoutPayloadRef.current[popoutId] = payload;
    setPopoutQuery('popout', popoutId);
  }, [setPopoutQuery]);
  const closePopout = () => clearQuery('popout');

  let popout;
  if (activePopout) {
    const PopoutComponent = popoutComponents[activePopout];

    popout = (
      <PopoutComponent
        key={activePopout}
        onClose={closePopout}
        payload={activePopoutPayloadRef.current[activePopout] as any}
      />
    );
  }

  /* Снекбар */
  const [snackbarPayload, setSnackbarPayload] = useState<SnackbarProps | null>(null);
  const snackbarKeyRef = useRef(0);
  const openSnackbar = useCallback((props: SnackbarProps) => {
    snackbarKeyRef.current += 1;
    setSnackbarPayload(props);
  }, []);
  const closeSnackbar = useCallback(() => setSnackbarPayload(null), []);

  const [isOverlayOpened, openOverlay] = useState(false);

  const overlayOpened = Boolean(popout) ||
    (typeof activeModal === 'string' && activeModal !== ModalId.CHECKLIST_VIEW &&
      activeModal !== ModalId.CHECKLIST_ADD && activeModal !== ModalId.CHECKLIST_EDIT) || isOverlayOpened;

  return (
    <layoutContext.Provider
      value={{
        closeModal, openModal, openPopout, closePopout,
        openSnackbar, closeSnackbar, clearQuery,
        errorClose, overlayOpened, openOverlay,
      }}
    >
      <Root modal={modal} popout={popout} activeView="root.view" backStyle="default">
        <div id="root.view">{children}</div>
      </Root>
      {snackbarPayload &&
      <Snackbar clearSnackbar={closeSnackbar} key={snackbarKeyRef.current} {...snackbarPayload} />}
    </layoutContext.Provider>
  );
};

export default LayoutContextProvider;
