import React from 'react';
import { ButtonProps } from '@vkontakte/vkui/dist/components/Button/Button';
import { makeStyles } from '@material-ui/styles';
import c from 'classnames';
import getClassname from '@vkontakte/vkui/dist/helpers/getClassName';

const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    borderRadius: 8,
    '& .Button__content': {
      position: 'relative',
    },
    '&.Button--sz-m .Button__content': {
      lineHeight: '18px',
      fontWeight: 400,
    },
    '&:active .Button__content': {
      top: 1,
    },
    '&:disabled': {
      pointerEvents: 'none',
    },
  },
  '@global': {
    'body:not([scheme=space_gray]) $button': {
      '&': {
        '--button_primary_background': '#5181b8',
        '--button_primary_foreground': '#fff',
        '--button_secondary_background': '#e5ebf1',
        '--button_secondary_foreground': '#55677d',
        '--button_tertiary_background': 'transparent',
        '--button_tertiary_foreground': '#436c93',
      },
      '&:hover': {
        '--button_primary_background': '#6690c0',
        '--button_primary_foreground': '#fff',
        '--button_secondary_background': '#e8edf2',
        '--button_secondary_foreground': '#436c93',
        '--button_tertiary_background': '#e8edf2',
        '--button_tertiary_foreground': '#436c93',
      },
      '&:active': {
        '--button_primary_background': '#4a76a8',
        '--button_primary_foreground': '#fff',
        '--button_secondary_background': '#dae2ea',
        '--button_secondary_foreground': '#2a5885',
        '--button_tertiary_background': '#dae2ea',
        '--button_tertiary_foreground': '#2a5885',
      },
    },
  },
});

const Button: React.FC<ButtonProps> = (props) => {
  const { className, size, mode, stretched, align, children, before, after, ...restProps } = props;

  return (
    <button
      {...restProps}
      className={c(
        getClassname('Button'),
        useStyles().button,
        className,
        `Button--sz-${size || 'm'}`,
        `Button--lvl-${mode || 'primary'}`,
        `Button--aln-${align || 'center'}`,
        { 'Button--str': stretched },
      )}
    >
      <div className="Button__in">
        {before && <div className="Button__before">{before}</div>}
        {children && <div className="Button__content">{children}</div>}
        {after && <div className="Button__after">{after}</div>}
      </div>
    </button>
  );
};

export default Button;
