export const Durations = [
  { value: 7, label: 'Неделя' },
  { value: 30, label: 'Месяц' },
  { value: 182, label: 'Полгода' },
  { value: 365, label: 'Год' },
];

export const DurationValues = [7, 30, 182, 365];

export enum DurationsEnum {
  WEEK = 'Неделя',
  MONTH = 'Месяц',
  HALFYEAR = 'Полгода',
  YEAR = 'Год',
}

export enum DurationsEnumValue {
  WEEK = 7,
  MONTH = 30,
  HALFYEAR = 182,
  YEAR = 365,
}
