import React, {memo, useCallback, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';

import vkBridge from '@vkontakte/vk-bridge';
import {Root, View, Panel, ModalRoot, ModalCard, Div, Button} from '@vkontakte/vkui';
import Icon56AddCircleOutline from '@vkontakte/icons/dist/56/add_circle_outline';

import {copyToClipboard} from '../../utils';

interface IProps {
  error: string;
  onRestartClick(): void;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0, left: 0, bottom: 0, right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  crashIcon: {
    color: '#2975cc',
    transform: 'rotate(45deg)',
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  error: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  dismissIcon: {
    color: '#818c99',
  },
}));

enum AppCrashModal {
  BUTTONS = 'buttons',
  INFO = 'info',
}

/**
 * Вью которая отображается в случае, когда в приложении произошла ошибка.
 * @type {React.NamedExoticComponent<IProps>}
 */
export const AppCrashView = memo((props: IProps) => {
  const {onRestartClick, error} = props;
  const mc = useStyles(props);

  const [activeModal, setActiveModal] = useState<AppCrashModal>(AppCrashModal.BUTTONS);
  const [copying, setCopying] = useState(false);

  const onCopyClick = useCallback(async () => {
    setCopying(true);
    try {
      await copyToClipboard(error);
    } finally {
      setCopying(false);
    }
  }, [error]);

  useEffect(() => {
    vkBridge.send('VKWebAppSetViewSettings', {
      status_bar_style: 'dark',
      action_bar_color: '#ffffff',
    });
  }, []);

  const modal = (
    <ModalRoot activeModal={activeModal}>
      <ModalCard
        id={AppCrashModal.BUTTONS}
        header="Произошла ошибка"
        onClose={() => setActiveModal(AppCrashModal.INFO)}
      >
        <Div className={mc.centered}>
          <Button size={'xl'} onClick={onRestartClick} className={mc.button}>
            Перезапустить приложение
          </Button>
          <Button
            size={'xl'}
            mode="outline"
            onClick={() => setActiveModal(AppCrashModal.INFO)}
            className={mc.button}
          >
            Подробнее об ошибке
          </Button>
        </Div>
      </ModalCard>
      <ModalCard
        id={AppCrashModal.INFO}
        header="Ошибка"
        onClose={() => setActiveModal(AppCrashModal.BUTTONS)}
      >
        <Div className={mc.error}>{error}</Div>
        <Div className={mc.centered}>
          <Button size={'xl'} onClick={onCopyClick} disabled={copying}>
            Скопировать в буфер обмена
          </Button>
        </Div>
      </ModalCard>
    </ModalRoot>
  );

  return (
    <Root activeView="main-crash-view" modal={modal}>
      <View id="main-crash-view" activePanel="main-crash-panel">
        <Panel id="main-crash-panel" centered>
          <Div className={mc.root}>
            <Icon56AddCircleOutline className={mc.crashIcon}/>
          </Div>
        </Panel>
      </View>
    </Root>
  );
});
