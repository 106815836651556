import moment from 'moment';

export function isSameDate(firstDate: any, secondDate: any) {
  return moment(firstDate).startOf('day').isSame(moment(secondDate).startOf('day'), 'day');
}

export function isDisabled(minDate: any, currentDate: any, maxDate: any) {
  const min = moment(minDate);
  const max = moment(maxDate);
  const current = moment(currentDate);

  return current.isBefore(min, 'day') || current.isAfter(max, 'day');
}

export function getDuration(firstDate: any, secondDate: any) {
  return moment.utc(firstDate).startOf('day').diff(
    moment.utc(secondDate).startOf('day'),
    'days',
  );
}
