import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { FormLayoutGroup, ModalCard } from '@vkontakte/vkui';
import Div from '../vkui/Div';
import Button from '../vkui/Button';

import { useActions, useErrorClose, useModal, useSnackbar } from '../../../hooks';
import { userActions } from '../../../store/reducers/user';
import { ModalId, ModalProps } from './types';
import { deleteUserChecklistMutation, DeleteUserChecklistMutation } from '../../../types/gql';

const ChecklistConfirmDeleteModal: React.FC<ModalProps<ModalId.CHECKLIST_DELETE>> = (
  { payload: { checklist }, onClose, ...modalProps },
) => {
  const openErrorRetryModal = useModal(ModalId.ERROR_RETRY);
  const showSnackbar = useSnackbar();
  const deleteUserChecklistAction = useActions(userActions.deleteUserChecklist);
  const [deleteUserChecklist] =
    useMutation<DeleteUserChecklistMutation, DeleteUserChecklistMutation.Arguments>(deleteUserChecklistMutation);

  const onDeleteUserChecklist = () =>
    deleteUserChecklist({ variables: { userChecklistId: checklist.id } })
      .then(({ data }) => {
        if (data) {
          deleteUserChecklistAction({ userChecklistId: checklist.id });
          showSnackbar({ text: 'Чеклист успешно удалён' });
        }
      });

  const errorClose = useErrorClose();
  const closeAfterConfirm = () => {
    onClose();
    setTimeout(() => errorClose(), 10);
  };
  const onConfirmClick = () => {
    if (checklist) {
      onDeleteUserChecklist().then(closeAfterConfirm, () => {
        openErrorRetryModal({ text: 'Не удалось удалить чеклист', onRetry: onDeleteUserChecklist });
      });
    }
  };

  return (
    <ModalCard {...modalProps} header="Удалить чеклист?" onClose={onClose}>
      <Div>
        <FormLayoutGroup>
          <Button
            onClick={onConfirmClick}
            stretched size='xl'
          >
            Подтвердить
          </Button>
          <Button mode="outline" onClick={onClose} stretched size='xl'>
            Отмена
          </Button>
        </FormLayoutGroup>
      </Div>
    </ModalCard>
  );
};

export default ChecklistConfirmDeleteModal;
