import React, { useState, useMemo, useEffect } from "react";
import { List, Title, Text, Cell } from "@vkontakte/vkui";
import { useSelector, useModal } from "hooks";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "theme";
import { CategoryChecklist } from "types/gql";
import SettingsIcon from "@vkontakte/icons/dist/24/settings";
import { ModalProps, ModalId } from "../atomic/modals/types";
import { getCheckListsWithMultipleCategories } from "./AdminModals/utils/formateChecklists";

export interface CheckListWithTags extends CategoryChecklist {
  categories: { id: string; title: string }[];
  categoriesToDelete?: string[];
}
export const ADMIN_FIXED_LAYOUT_HEIGHT = 53;
const styles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: `${ADMIN_FIXED_LAYOUT_HEIGHT}px`,
    },
    categoriesListContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: 18,
      "&:not(:first-child)": {
        marginTop: 18,
      },
      "& svg": {
        color: "#AEB7C2",
        cursor: "pointer",
        "&:active": {
          opacity: 0.7,
        },
      },
    },
    cell: {
      borderBottom: "1px solid #E7E8EC",
    },
    categoriesList: {
      display: "flex",
      listStyle: "none",
      padding: 0,
      margin: 0,
      marginRight: 12,
      "&>li": {
        height: 20,
        backgroundColor: "#EBEDF0",
        color: "#6D7885",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 10px",
        borderRadius: 5,
        fontSize: 15,
        lineHeight: 15,
        cursor: "pointer",
        "&:active": {
          opacity: 0.7,
        },
        "&:not(:last-child)": {
          marginRight: 8,
        },
      },
    },
    checklistTitle: {
      marginBottom: 5,
    },
    checklistDesc: {
      color: "#828282",
      fontSize: 16,
      marginBottom: 15,
    },
  }),
  { classNamePrefix: "AdminChecklists" }
);

export const AdminChecklists = () => {
  const classes = styles();
  const openEditChecklistModal = useModal(ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT);

  const checklistsCategories = useSelector(
    (state) => state.app.checklistCategories
  );

  const checkListsWithMultipleCategories = useMemo(
    () => getCheckListsWithMultipleCategories(checklistsCategories),
    [checklistsCategories]
  );

  const [checklists, setChecklists] = useState<CheckListWithTags[]>(
    checkListsWithMultipleCategories
  );

  useEffect(() => {
    setChecklists(checkListsWithMultipleCategories);
  }, [checkListsWithMultipleCategories]);

  const editHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const checkListId = { ...e.currentTarget.dataset } as { id: string };
    const checklist = checkListsWithMultipleCategories.find(
      (checklist) => checklist.id === checkListId.id
    );
    checklist && openEditChecklistModal({ checklist });
  };
  console.log(checklists);
  return (
    <List className={classes.root}>
      {checklists.map(
        ({ id, trackerTitle, categories, trackerDescription }, i) => {
          return (
            <Cell className={classes.cell} key={i}>
              <Title
                className={classes.checklistTitle}
                level={"2"}
                weight={"medium"}
              >
                {trackerTitle}
              </Title>
              <Text className={classes.checklistDesc} weight={"regular"}>
                {trackerDescription}
              </Text>
              <div className={classes.categoriesListContainer}>
                <ul className={classes.categoriesList}>
                  {categories.map((category) => (
                    <li key={category.id}>{category.title}</li>
                  ))}
                </ul>
                <SettingsIcon
                  width={20}
                  color={"#AEB7C2"}
                  data-id={id}
                  onClick={(e) => editHandler(e)}
                />
              </div>
            </Cell>
          );
        }
      )}
    </List>
  );
};
