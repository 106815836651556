import { ReduxState } from "./types";
import { Store, createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import appReducer from "./reducers/app";
import userReducer from "./reducers/user";
import checklistReducer from "./reducers/checklist";
import configReducer from "./reducers/config";
import storageReducer from "./reducers/storage";
import layoutReducer from "./reducers/layout";
import checklistCategoryReducer from "./reducers/categories";
import { deviceReducer } from "./reducers/device";

const reducers = combineReducers<ReduxState>({
  app: appReducer,
  user: userReducer,
  checklist: checklistReducer,
  categories: checklistCategoryReducer,
  config: configReducer,
  storage: storageReducer,
  layout: layoutReducer,
  device: deviceReducer,
});

export function createReduxStore(
  state?: Partial<ReduxState>
): Store<ReduxState> {
  return createStore(reducers, state, devToolsEnhancer({}));
}
