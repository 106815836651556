export interface Config {
  gqlHttpUrl: string;
  group_id: number;
}

// Здесь происходит загрузка конфига на основе переменных внешней среды
// Только переменные, начинающиеся с REACT_APP будут загружены
const gqlHttpUrl = process.env.REACT_APP_GQL_HTTP_URL;
const group_id = Number(process.env.REACT_APP_VK_GROUP_ID);

if (!gqlHttpUrl) {
  throw new Error('Http Url base url not passed');
}
if (!group_id) {
  throw new Error('Group ID not passed');
}

const config: Config = {
  gqlHttpUrl,
  group_id,
};

export default config;
