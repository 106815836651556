import { ChecklistCategory } from "types/gql";
import { CheckListWithTags } from "components/Admin/CheckLists";

export const getCheckListsWithMultipleCategories = (
  originCategoryChecklist: ChecklistCategory[],
  sortByCategory = false,
  currentCategoryId?: string
) => {
  const formatedChecklists = [] as CheckListWithTags[];

  originCategoryChecklist.forEach((category) => {
    const checklists = category.checklists;

    for (let i = 0; i < checklists.length; i++) {
      const checklist = checklists[i];
      //индекс элемента, который появился в новой категории и уже есть в отформатированном листе
      const dublicatedIndex = formatedChecklists.findIndex(
        (formatedCheckList) => checklist.id === formatedCheckList.id
      );

      const isCurrentCategory =
        currentCategoryId && currentCategoryId === category.id;
      console.log(isCurrentCategory);

      if (dublicatedIndex >= 0) {
        formatedChecklists[dublicatedIndex] = {
          ...formatedChecklists[dublicatedIndex],
          categories: [
            ...formatedChecklists[dublicatedIndex].categories,
            { id: category.id, title: category.title },
          ],
          weight: isCurrentCategory
            ? checklist.weight
            : formatedChecklists[dublicatedIndex].weight,
        };
      } else {
        formatedChecklists.push({
          id: checklist.id,
          categories: [{ id: category.id, title: category.title }],
          trackerDescription: checklist.trackerDescription,
          trackerTitle: checklist.trackerTitle,
          defaultTitle: checklist.defaultTitle,
          defaultDescription: checklist.defaultDescription,
          usersCount: checklist.usersCount,
          icon: checklist.icon,
          weight: checklist.weight,
        });
      }
    }
  });

  return sortByCategory
    ? formatedChecklists.filter((checklist) =>
        checklist.categories.some(
          (category) => category.id === currentCategoryId
        )
      )
    : formatedChecklists;
};
