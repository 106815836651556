import {useCallback} from 'react';
import {useLayoutContext} from '../components/LayoutContextProvider';
import {PopoutPayload} from '../components/atomic/popouts/types';

export const usePopout = <K extends keyof PopoutPayload>(popout: K) => {
  const {openPopout} = useLayoutContext();
  return useCallback((payload: PopoutPayload[K]) => {
    openPopout(popout, payload);
  }, [popout, openPopout]);
};
