import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import cn from 'classnames';

import { Panel, PanelHeader, PanelHeaderButton, Group, Gallery, Button } from '@vkontakte/vkui';
import { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';
import UserOutline from '@vkontakte/icons/dist/24/user_outline';
import DoneOutline from '@vkontakte/icons/dist/16/done';
import Add from '@vkontakte/icons/dist/16/add';
import AddOutline from '@vkontakte/icons/dist/28/add_outline';

import { Theme } from '../../theme/types';
import { useModal, useSelector } from '../../hooks';
import { ModalId } from '../atomic/modals/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',

    '& .Group>:not(.Header):first-child': {
      marginTop: 0,
    },
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '21px',
    lineHeight: '26px',
    paddingLeft: 16,
  },
  labelDesktop: {
    paddingLeft: 24,
  },
  swipeList: {
    width: '100%',
    height: '100%',

    '& .Gallery__slide': {
      height: 'auto',
    },
    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 16,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 16,
    },
  },
  swipeListDesktop: {
    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 24,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 24,
    },
  },
  checklist: {
    display: 'flex',
    margin: '0 8px',
    boxSizing: 'border-box',
    borderRadius: '14px',
    background: theme.palette.card?.background,
    overflow: 'hidden',
  },
  checklistInfo: {
    width: 'calc(100% - 90px)',
    padding: '16px 10px 20px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  checklistIcon: {
    position: 'relative',
    width: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,

    '& img': {
      width: '50px',
      height: '50px',
    },
  },
  checklistIconBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '-webkit-filter': 'blur(1px)',
    filter: 'blur(1px)',
    overflow: 'hidden',
    zIndex: -1,

    '& img:nth-child(1)': {
      position: 'absolute',
      top: -10,
      left: -10,
      transform: 'rotate(45deg) scale(0.3)',
    },
    '& img:nth-child(2)': {
      position: 'absolute',
      bottom: -5,
      right: 15,
      transform: 'rotate(135deg) scale(0.2)',
    },
    '& img:nth-child(3)': {
      position: 'absolute',
      top: -10,
      right: -20,
      transform: 'rotate(-45deg) scale(0.4)',
    },
    '& img:nth-child(4)': {
      position: 'absolute',
      bottom: -10,
      right: -20,
      transform: 'rotate(-135deg) scale(0.4)',
    },
  },
  checklistLabel: {
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.card?.label,
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: '0.38px',
  },
  checklistDescription: {
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '8px',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-0.078px',
    color: theme.palette.card?.description,
  },
  addLine: {
    marginTop: '22px',
    display: 'flex',
    alignItems: 'center',
  },
  checklistButton: {
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightDemiBold,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#000',
    background: '#FFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.06)',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  checklistButtonChecked: {
    color: theme.palette.card?.text,
    background: theme.palette.card?.background,
    boxShadow: 'none',
  },
  checklistButtonIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.icon?.active,
  },
  userCount: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '13px',
    lineHeight: '1.69',
    letterSpacing: '-0.14px',
    color: theme.palette.card?.text,
    marginLeft: 4,
  },
  userCountIcon: {
    width: 16,
    marginRight: 4,
    color: theme.palette.icon?.active,
  },
}));

interface CatalogPanelProps extends PanelProps {
  onBack(): void;
}

export const CatalogPanel: React.FC<CatalogPanelProps> = function Catalog({ onBack, ...panelProps }) {
  const mc = useStyles();
  const isWeb = useSelector((state) => ['desktop_web', 'mobile_web'].includes(state.config.launchParams.platform));
  const isDesktop = useSelector((state) => state.config.launchParams.platform === 'desktop_web');
  const checklistCategories = useSelector((store) => store.app.checklistCategories);
  const userChecklists = useSelector((store) => store.user.checklists);
  const openCheckListAdd = useModal(ModalId.CHECKLIST_ADD);

  const parentIds: any = [];
  userChecklists.length && userChecklists.forEach(ch => parentIds.push(ch.parentId));

  return (
    <Panel {...panelProps}>
      <PanelHeader left={
        <PanelHeaderButton onClick={() => openCheckListAdd({ checklist: null })}>
          <AddOutline/>
        </PanelHeaderButton>
      }>Каталог</PanelHeader>

      <div className={mc.root}>
        {checklistCategories
          .sort((a, b) => b.weight - a.weight)
          .map((category, categoryIndex) => (
            <div key={categoryIndex} className={mc.category}>
              <div className={cn(mc.label, isDesktop && mc.labelDesktop)}>{category.title}</div>
              <Group style={{ width: '100%' }}>
                <Gallery
                  slideWidth='custom'
                  align="left"
                  className={cn(mc.swipeList, isDesktop && mc.swipeListDesktop)}
                >
                  {
                    category.checklists.length ?
                      category.checklists
                        .sort((a, b) => b.weight - a.weight)
                        .map((checklist, checklistIndex) => (
                          <div
                            key={checklistIndex}
                            className={mc.checklist}
                            style={isDesktop ? { width: '320px' } : { width: '280px' }}
                            onClick={() => !isWeb && openCheckListAdd({ checklist })}
                          >
                            <div className={mc.checklistInfo}>
                              <div>
                                <div className={mc.checklistLabel}>{checklist.trackerTitle}</div>
                                <div className={mc.checklistDescription}>{checklist.trackerDescription}</div>
                              </div>
                              <div className={mc.addLine}>
                                {parentIds.includes(checklist.id) ?
                                  <Button
                                    size='m'
                                    mode='secondary'
                                    className={cn(mc.checklistButton, mc.checklistButtonChecked)}
                                    onClick={() => openCheckListAdd({ checklist })}
                                    before={<DoneOutline className={mc.checklistButtonIcon}/>}
                                  >Добавлено</Button> :
                                  <Button
                                    size='m'
                                    mode='primary'
                                    className={mc.checklistButton}
                                    onClick={() => openCheckListAdd({ checklist })}
                                    before={<Add className={mc.checklistButtonIcon}/>}
                                  >Добавить</Button>
                                }
                                {checklist.usersCount > 0 && <div className={mc.userCount}>
                                  <UserOutline className={mc.userCountIcon}/>
                                  <div>{checklist.usersCount}</div>
                                </div>}
                              </div>
                            </div>
                            <div className={mc.checklistIcon}>
                              <img src={checklist.icon.imageUrl} alt="*"/>
                              <div className={mc.checklistIconBg}>
                                <img src={checklist.icon.imageUrl} alt="*"/>
                                <img src={checklist.icon.imageUrl} alt="*"/>
                                <img src={checklist.icon.imageUrl} alt="*"/>
                                <img src={checklist.icon.imageUrl} alt="*"/>
                              </div>
                            </div>
                          </div>
                        )) : (
                        <div>Пусто</div>
                      )
                  }
                </Gallery>
              </Group>
            </div>
          ))}
      </div>
    </Panel>
  );
};
