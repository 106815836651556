import {useCallback} from 'react';
import {useLayoutContext} from '../components/LayoutContextProvider';
import {ModalPayload} from '../components/atomic/modals/types';

export const useModal = <K extends keyof ModalPayload>(modal: K, override?: boolean) => {
  const {openModal} = useLayoutContext();
  return useCallback((payload: ModalPayload[K]) => {
    openModal(modal, payload, override);
  }, [modal, openModal, override]);
};
