import {UpdateConfigData} from '@vkontakte/vk-bridge';
import {PrepareUpdateStatePayloadResult} from './types';

export function prepareUpdateStatePayload(
  config: UpdateConfigData,
  isReceivedBefore: boolean,
): PrepareUpdateStatePayloadResult {
  const result: PrepareUpdateStatePayloadResult = {config};

  if ('appearance' in config) {
    result.appearance = config.appearance;
  }

  if (isReceivedBefore) {
    if ('insets' in config) {
      result.currentInsets = config.insets;
    }
    return result;
  }

  if ('insets' in config) {
    result.currentInsets = config.insets;
    result.insets = config.insets;
  }

  if ('scheme' in config) {
    result.scheme = config.scheme;
  }

  return result;
}
