import { ofType, unionize, UnionOf } from "unionize";
import { unionizeConfig } from "../utils";
import { ChecklistCategory } from "../../types/gql";

export type ChecklistCategoryReducerState = ChecklistCategory[];

export const checklistCategoryActions = unionize(
  {
    createChecklistCategory: ofType<ChecklistCategory[]>(),
  },
  unionizeConfig
);

type ChecklistCategoryAction = UnionOf<typeof checklistCategoryActions>;

const initialState: ChecklistCategoryReducerState = [];

/**
 * Редьюсер который отвечает за чеклист пользователя
 * @param {ChecklistCategoryReducerState} state
 * @param {ChecklistCategoryAction} action
 * @returns {string[]}
 */

function checklistCategoryReducer(
  state: ChecklistCategoryReducerState = initialState,
  action: ChecklistCategoryAction
) {
  return checklistCategoryActions.match(action, {
    createChecklistCategory: (checklistsCategory) => ({
      ...state,
      ...checklistsCategory,
    }),

    default: () => state,
  });
}

export default checklistCategoryReducer;
