import React, { memo, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import { Gallery, Group, PanelHeaderButton, withModalRootContext } from '@vkontakte/vkui';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import ShareOutline from '@vkontakte/icons/dist/24/share_outline';
import SettingsOutline from '@vkontakte/icons/dist/28/settings_outline';
import ModalPage from '../../vkui/Modal';
import ChecklistCalendar from '../../Calendar/Calendar';

import { useActions, usePopout, useSelector, useSnackbar } from '../../../../hooks';
import { getDuration, incline, isSameDate, tapticNotification } from '../../../../utils';
import { userActions } from '../../../../store/reducers/user';

import { ModalId, ModalProps } from '../types';
import { PopoutId } from '../../popouts/types';
import { Theme } from '../../../../theme/types';
import {
  checkUserDayMutation, CheckUserDayMutation,
  DateTime, SharingDuration, UserChecklist,
} from '../../../../types/gql';

const useStyles = makeStyles<Theme>((theme) => ({
  panelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0 24px',
  },
  headerIconBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  headerIcon: {
    color: theme.palette.icon?.active,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  root: {
    height: '100%',
  },
  info: {
    maxWidth: '350px',
  },
  title: {
    marginTop: 16,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightDemiBold,
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'left',
    color: theme.palette.text?.primary,
  },
  description: {
    marginTop: 8,
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
    letterSpacing: '-0.32px',
    color: theme.palette.text?.secondary,
    whiteSpace: 'pre-wrap',
  },
  shareGroup: {
    marginTop: 25,
  },
  shareGallery: {
    height: '76px',

    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 24,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 24,
    },
  },
  share: {
    margin: '0 4px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '178px',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    background: theme.palette.share?.background,
  },
  shareText: {
    padding: 16,
  },
  shareLabel: {
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-0.078px',
    textTransform: 'uppercase',
    color: theme.palette.share?.label,
  },
  shareDescription: {
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '0.36px',
    color: theme.palette.share?.description,
    whiteSpace: 'nowrap',
  },
  shareIcon: {
    padding: '10px',
    color: theme.palette.share?.icon,
  },
  shareIconActive: {
    color: theme.palette.share?.icon,
  },
  calendar: {
    position: 'relative',
    marginTop: 24,
  },
}));

const ChecklistModal: React.FC<ModalProps<ModalId.CHECKLIST_VIEW>> = memo(function Checklist(
  { payload: { checklist: propsChecklist }, onClose, ...modalProps },
) {
  const mc = useStyles();
  const showChecklistPopout = usePopout(PopoutId.CHECKLIST_CONTEXT);
  const showChecklistSharePopout = usePopout(PopoutId.CHECKLIST_SHARE_METHOD);
  const showChecklistEndPopout = usePopout(PopoutId.CHECKLIST_END);
  const showSnackbar = useSnackbar();
  const [checkUserDay] = useMutation<CheckUserDayMutation, CheckUserDayMutation.Arguments>(checkUserDayMutation);
  const checkUserDayAction = useActions(userActions.checkUserDay);

  const checklist = useSelector(
    state => state.user.checklists.find(ch => ch.id === propsChecklist.id),
  ) || propsChecklist;
  const isEnded = moment().startOf('day').utc(true).isSameOrAfter(checklist.endsAt);

  useEffect(() => {
    if (isEnded) {
      showChecklistEndPopout({ checklist });
    }
  }, []);

  const checkUserDayHandler = (date: any) => {
    if (!isEnded) {
      const inDays = !!checklist.days.find((d: any) => isSameDate(d, date));
      const utcDate: DateTime = moment(date).utc(true).toDate();

      checkUserDayAction({
        userChecklistId: checklist.id,
        date: utcDate,
        isChecked: !inDays,
      });

      checkUserDay({
        variables: {
          userChecklistId: checklist.id,
          date: utcDate,
          isChecked: !inDays,
        },
      }).then(({ data }) => {
        tapticNotification('success');

        if (data && !data.checkUserDay) {
          checkUserDayAction({
            userChecklistId: checklist.id,
            date: utcDate,
            isChecked: inDays,
          });
        }

        if (data && data.checkUserDay) {
          if (isSameDate(moment().subtract(1, 'day'), checklist.endsAt) && !inDays) {
            showChecklistEndPopout({ checklist });
          }
        }
      }).catch(() => {
        checkUserDayAction({
          userChecklistId: checklist.id,
          date: utcDate,
          isChecked: inDays,
        });
        tapticNotification('error');
        showSnackbar({ text: 'Чеклист не был отмечен!' });
      });
    } else {
      tapticNotification('error');
      showSnackbar({ text: 'Чеклист завершён' });
    }
  };

  const share = useCallback((checklist: UserChecklist) => {
      const duration = getDuration(checklist.endsAt, checklist.createdAt);
      const shares = [];

      const isInWeek = (day: Date) => {
        const weekStart = moment().startOf('week');
        const weekEnd = moment().endOf('week');

        return moment(day).isAfter(weekStart) && moment(day).isSameOrBefore(weekEnd);
      };

      const isInMonth = (day: Date) => {
        const monthStart = moment().startOf('month');
        const monthEnd = moment().endOf('month');

        return moment(day).isSameOrAfter(monthStart) && moment(day).isSameOrBefore(monthEnd);
      };

      const isInHalfYear = (day: Date) => {
        const yearStart = moment().startOf('year');
        const daysElapsed = moment().diff(yearStart, 'day');

        if (daysElapsed > 182) {
          yearStart.add(183, 'day');
        }

        const halfYear = moment(yearStart).add(6, 'month');

        return moment(day).isSameOrAfter(yearStart) && moment(day).isSameOrBefore(halfYear);
      };

      const isInYear = (day: Date) => {
        const yearStart = moment().startOf('year');
        const yearEnd = moment().endOf('year');

        return moment(day).isSameOrAfter(yearStart) && moment(day).isSameOrBefore(yearEnd);
      };

      const isInFull = (day: Date) => moment(day).isSameOrAfter(moment(checklist.createdAt).startOf('day')) && moment(
        day).isSameOrBefore(checklist.endsAt);

      const counter = checklist.days.reduce((counter, day) => {
        counter.week += Number(isInWeek(day));
        counter.month += Number(isInMonth(day));
        counter.halfyear += Number(isInHalfYear(day));
        counter.year += Number(isInYear(day));
        counter.full += Number(isInFull(day));

        return counter;
      }, { week: 0, month: 0, halfyear: 0, year: 0, full: 0 });

      const getIncline = (counter: number) => incline(
        counter,
        'раз',
        'раза',
        'раз',
      );
      const getInclineDay = () => incline(
        duration,
        'день',
        'дня',
        'дней',
      );

      if (!isEnded) {
        Object.keys(counter).forEach((type) => {
          if (type === 'week') {
            shares.push({
              label: 'НЕДЕЛЯ',
              description: `${counter.week} ${getIncline(counter.week)}`,
              duration: counter.week,
              durationType: SharingDuration.WEEK,
            });
          }

          if (type === 'month' && duration >= 30) {
            shares.push({
              label: 'МЕСЯЦ',
              description: `${counter.month} ${getIncline(counter.month)}`,
              duration: counter.month,
              durationType: SharingDuration.MONTH,
            });
          }

          if (type === 'halfyear' && duration >= 182) {
            shares.push({
              label: 'ПОЛГОДА',
              description: `${counter.halfyear} ${getIncline(counter.halfyear)}`,
              duration: counter.halfyear,
              durationType: SharingDuration.HALFYEAR,
            });
          }

          if (type === 'year' && duration >= 365) {
            shares.push({
              label: 'ГОД',
              description: `${counter.year} ${getIncline(counter.year)}`,
              duration: counter.year,
              durationType: SharingDuration.YEAR,
            });
          }

          if (type === 'full') {
            shares.push({
              label: `За ${duration} ${getInclineDay()}`,
              description: `${counter.full} ${getIncline(counter.full)}`,
              duration: counter.full,
              durationType: SharingDuration.ALLTIME,
            });
          }
        });
      } else {
        shares.push({
          label: `За ${duration} ${getInclineDay()}`,
          description: `${counter.full} ${getIncline(counter.full)}`,
          duration: counter.full,
          durationType: SharingDuration.ALLTIME,
        });
      }

      return (
        <Group className={mc.shareGroup}>
          <Gallery
            slideWidth="custom"
            align="left"
            className={mc.shareGallery}
          >
            {shares.map(({ label, description, duration, durationType }, i) => (
              <div
                key={i} className={mc.share}
                onClick={() => showChecklistSharePopout({ checklist, duration, durationType })}
              >
                <div className={mc.shareText}>
                  <div className={mc.shareLabel}>{label}</div>
                  <div className={mc.shareDescription}>{description}</div>
                </div>
                <ShareOutline className={mc.shareIcon}/>
              </div>
            ))}
          </Gallery>
        </Group>
      );
    },
    [isEnded, showChecklistSharePopout]);

  const calendar = useCallback((checklist: UserChecklist) => {
    return (
      <ChecklistCalendar
        minDate={moment(checklist.createdAt)}
        maxDate={moment(checklist.endsAt).subtract(1, 'day')}
        days={checklist.days}
        icon={checklist.icon.imageUrl}
        enableYearTitle={false}
        onSelect={checkUserDayHandler}
      />
    );
  }, [checkUserDayHandler]);

  return (
    <ModalPage
      title={'Чеклист'}
      header={null}
      fullHeight
      {...modalProps}
    >
      <div className={mc.root}>
        <div className={mc.panelHeader}>
          <div className={mc.info}>
            <div className={mc.title}>{checklist.title}</div>
            <div className={mc.description}>{checklist.description}</div>
          </div>
          <div className={mc.headerIconBlock}>
            <PanelHeaderButton>
              <SettingsOutline
                className={mc.headerIcon}
                onClick={() => showChecklistPopout({ checklist })}
              />
            </PanelHeaderButton>
            <PanelHeaderButton>
              <Icon24Dismiss
                className={mc.headerIcon}
                onClick={() => onClose()}
              />
            </PanelHeaderButton>
          </div>
        </div>
        {share(checklist)}
        <div className={mc.calendar}>
          {calendar(checklist)}
        </div>
      </div>
    </ModalPage>
  );
});

export default withModalRootContext(ChecklistModal);
