import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useTheme} from '@material-ui/styles';
import vkBridge from '@vkontakte/vk-bridge';
import {useLazyQuery} from '@apollo/react-hooks';
// import moment from 'moment';

import Icon28StoryOutline from '@vkontakte/icons/dist/28/story_outline';
import Icon28ArticleOutline from '@vkontakte/icons/dist/28/article_outline';
import ActionSheet from '@overrided-vkui/ActionSheet/ActionSheet';

import {
  shareContentByStory, shareContentByWall,
  // delay,
} from '../../../utils';
import {
  useModal, useSelector,
  // usePopout,
} from '../../../hooks';
// import {useLayoutContext} from '../LayoutContextProvider';

import {
  UserChecklistSnippetQuery,
  userChecklistSnippetQuery,
} from '../../../types/gql'

import {ModalId} from '../modals/types';
import {Theme} from '../../../theme/types';
import {PopoutId, PopoutProps} from './types';

import shareExample from '../../../assets/share/share.base64.png';
import shareBgExample from '../../../assets/share/shareBg.base64.png';

// const SCREEN_SPINNER_TIMEOUT = 5000;

const SelectShareMethodActionSheet: React.FC<PopoutProps<PopoutId.CHECKLIST_SHARE_METHOD>> = (
  {onClose, payload: {checklist, duration, durationType}},
) => {
  const theme = useTheme<Theme>();

  const openErrorRetryModal = useModal(ModalId.ERROR_RETRY);
  // const openScreenSpinner = usePopout(PopoutId.SCREEN_SPINNER);
  // const {closePopout: closeScreenSpinner} = useLayoutContext();

  const appId = useSelector((state) => state.config.launchParams.appId);
  const isMobileWeb = useSelector((state) => state.config.launchParams.platform === 'mobile_web');
  const appUrl = `https://vk.com/app${appId}#/`;
  const title = `Поставьте цель и стремитесь к ней с приложением «Чеклисты»!`;

  const [
    getSnippet, {data: snippet, loading},
  ] = useLazyQuery<UserChecklistSnippetQuery, UserChecklistSnippetQuery.Arguments>(
    userChecklistSnippetQuery,
    {fetchPolicy: 'network-only'},
  );

  useEffect(() => {
    getSnippet({
      variables: {
        userChecklistId: checklist.id,
        duration: durationType,
      },
    });
  }, []);

  const previewBgImageUrl = shareBgExample;
  const wallSnippet = (snippet && snippet.userChecklistSnippet) || shareExample;

  const callWithSpinner = useCallback(
    (shareMethod: () => Promise<any>) => () => {
      // openScreenSpinner({});
      Promise.race([
        shareMethod().catch(() => {
          openErrorRetryModal({text: 'Ошибка при создании публикации', onRetry: shareMethod});
        }),

        // перестаем блокировать интерфейс спиннером, если shareMethod долго находится в состоянии pending
        // delay(SCREEN_SPINNER_TIMEOUT),
      ]).catch(() => null)
      // .finally(closeScreenSpinner);
    },
    [openErrorRetryModal],
  );

  const onShareStoryClick = useMemo(
    () => callWithSpinner(() => shareContentByStory(previewBgImageUrl, appUrl, theme, checklist, duration, wallSnippet)),
    [callWithSpinner, previewBgImageUrl, appUrl, theme, checklist, duration, wallSnippet],
  );

  const onShareWallClick = useMemo(
    () => callWithSpinner(() => shareContentByWall(wallSnippet, title, appUrl, appId)),
    [callWithSpinner, wallSnippet, title, appUrl, appId],
  );

  const [showSelect, setShowSelect] = useState(false);

  useLayoutEffect(() => {
    if (vkBridge.supports('VKWebAppShowStoryBox')) {
      setShowSelect(true);
    } else {
      onClose();
      onShareWallClick();
    }
  }, [onClose, onShareWallClick]);

  if (!showSelect) {
    return null;
  }

  return (
    <ActionSheet onClose={onClose}>
      {!isMobileWeb && <ActionSheet.Item
        onClick={onShareStoryClick}
        before={<Icon28StoryOutline style={{color: '#2787f5'}}/>}>
        <span style={{color: '#2787f5'}}>В историю</span>
      </ActionSheet.Item>}
      <ActionSheet.Item
        onClick={onShareWallClick}
        before={<Icon28ArticleOutline style={{color: '#2787f5'}}/>}
      >
        <span style={{color: '#2787f5'}}>На стену</span>
      </ActionSheet.Item>
    </ActionSheet>
  );
};

export default SelectShareMethodActionSheet;
