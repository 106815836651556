import React, { useState } from "react";
import { ModalProps, ModalId } from "../../atomic/modals/types";
import {
  ModalPage,
  PanelHeader,
  PanelHeaderButton,
  FormLayout,
  FormLayoutGroup,
  Input,
  Button,
} from "@vkontakte/vkui";
import { makeStyles } from "@material-ui/styles";
import { useModal, useSnackbar, useErrorClose, useActions } from "../../../hooks";
import { useMutation } from "@apollo/react-hooks";
import {
  UpdateChecklistCategoryMutation,
  updateChecklistCategoryMutation,
} from "../../../types/gql";
import { appActions } from "../../../store/reducers/app";

const styles = makeStyles(
  {
    bottomBtnsGroup: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  { classNamePrefix: "AdminEditCategory" }
);
export const AdminEditCategoryModal: React.FC<ModalProps<
  ModalId.ADMIN_CATEGORY_EDIT
>> = ({ payload: { category }, onClose, ...modalProps }) => {
  const classes = styles();
  const [categoryTitle, setCategoryTitle] = useState<string>(category.title!);
  const closeAll = useErrorClose();
  const openDeleteCategoryModal = useModal(ModalId.ADMIN_DELETE);

  const editChecklistCategoryAction = useActions(
    appActions.updateChecklistCategory
  );

  const showErrorSnackbar = useSnackbar();
  const [editChecklistCategory, { loading }] = useMutation<
    UpdateChecklistCategoryMutation,
    UpdateChecklistCategoryMutation.Arguments
  >(updateChecklistCategoryMutation);

  const editHandler = () => {
    editChecklistCategory({
      variables: {
        params: {
          categoryId: category.id,
          title: categoryTitle,
          weight: category.weight,
        },
      },
    })
      .then(({ data }) => {
        console.log(data);
        editChecklistCategoryAction({
          id: category.id,
          title: categoryTitle,
          weight: category.weight,
        });
        onClose();
      })
      .catch((e) => showErrorSnackbar({ text: e.message }));
  };

  return (
    <ModalPage
      title={"Редактировать категорию"}
      {...modalProps}
      header={
        <PanelHeader
          left={
            <PanelHeaderButton onClick={onClose}> Отменить </PanelHeaderButton>
          }
        >
          Редактирование категории
        </PanelHeader>
      }
    >
      <FormLayout>
        <FormLayoutGroup top={"Название"}>
          <Input
            type="text"
            name="title"
            placeholder={"Напишите название"}
            value={categoryTitle}
            onChange={(e) => setCategoryTitle(e.target.value)}
          />
        </FormLayoutGroup>
        <div className={classes.bottomBtnsGroup}>
          <Button
            onClick={() => {
              openDeleteCategoryModal({ category });
            }}
            mode={"destructive"}
          >
            Удалить категорию
          </Button>
          <Button onClick={editHandler}>
            {loading ? "Сохраняю..." : "Сохранить"}
          </Button>
        </div>
      </FormLayout>
    </ModalPage>
  );
};
