import {ofType, unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';
import {Checklist} from '../../types/gql';

export type ChecklistsReducerState = Checklist[];

export const checklistActions = unionize(
  {
    setChecklist: ofType<Checklist[]>(),
    createChecklist: ofType<{ checklistId: string; iconId: string; duration: number }>(),
    updateChecklist: ofType<{ ChecklistId: string; iconId: string; duration: number }>(),
    deleteChecklist: ofType<{ ChecklistId: string }>(),
  },
  unionizeConfig,
);

type ChecklistAction = UnionOf<typeof checklistActions>;

const initialState: ChecklistsReducerState = [];

/**
 * Редьюсер который отвечает за чеклист пользователя
 * @param {ChecklistsReducerState} state
 * @param {ChecklistAction} action
 * @returns {string[]}
 */

function checklistReducer(state: ChecklistsReducerState = initialState, action: ChecklistAction) {
  return checklistActions.match(action, {
    setChecklist: (checklists) => ({ ...state, ...checklists }),
    createChecklist: () => state,
    updateChecklist: () => state,
    deleteChecklist: () => state,
    default: () => state,
  });
}

export default checklistReducer;
