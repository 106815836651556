import {ofType, unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';
import {
  Insets,
  DefaultUpdateConfigData,
  UpdateConfigData,
  MVKUpdateConfigData,
  AppearanceType,
  AppearanceSchemeType,
} from '@vkontakte/vk-bridge';
import {Config} from '../../config';
import {LaunchParams} from '../../types';

type ConfigData = Omit<DefaultUpdateConfigData, 'app_id'> &
  Omit<MVKUpdateConfigData, 'viewport_width' | 'viewport_height'>;

export interface ConfigReducerState extends ConfigData {
  appConfig: Config;
  viewportWidth: number;
  viewportHeight: number;
  launchParams: LaunchParams;
  viewSettings: { statusBarStyle: AppearanceType; actionBarColor?: string };
}

export const configActions = unionize(
  {
    updateConfig: ofType<UpdateConfigData>(),
    // updateConfig: ofType<Partial<UpdateConfigData>>(),
    updateAppearanceScheme: ofType<AppearanceSchemeType>(),
    updateInsets: ofType<Insets>(),
    setViewSettings: ofType<ConfigReducerState['viewSettings']>(),
  },
  unionizeConfig,
);

type ConfigAction = UnionOf<typeof configActions>;

const initialState: ConfigReducerState = {
  app: 'vkclient',
  appConfig: {
    gqlHttpUrl: '',
    group_id: 0,
  },
  appearance: 'light',
  scheme: 'client_light',
  viewSettings: {statusBarStyle: 'light'},
  insets: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  viewportHeight: 0,
  viewportWidth: 0,
  launchParams: {
    accessTokenSettings: [],
    appId: 0,
    areNotificationsEnabled: false,
    isAppUser: false,
    isFavorite: false,
    language: 'ru',
    platform: 'desktop_web',
    ref: 'other',
    userId: 0,
    groupId: null,
    viewerGroupRole: null,
    sign: '',
  },
};

/**
 * Редьюсер ответственный за конфиг приложения который приходит от ВКонтакте.
 */
function configReducer(
  state: ConfigReducerState = initialState,
  action: ConfigAction,
) {
  return configActions.match(action, {
    updateConfig: (config) => {
      if ('insets' in config) {
        const {app_id, ...restConfig} = config;

        return {
          ...state,
          ...restConfig,
          appId: app_id,
        };
      }
      const {viewport_height, viewport_width, ...restConfig} = config;

      return {
        ...state,
        ...restConfig,
        viewportHeight: viewport_height,
        viewportWidth: viewport_width,
      };
    },
    updateInsets: (insets) => ({...state, insets}),
    setViewSettings: (viewSettings) => ({ ...state, viewSettings }),
    updateAppearanceScheme: scheme => ({ ...state, scheme }),
    default: () => state,
  });
}

export default configReducer;
