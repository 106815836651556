import {Theme} from '../types';

export const themeDefaults: Theme = {
  breakpoints: {
    up: (breakpoint) => `@media screen and (min-width: ${breakpoint}px)`,
    down: (breakpoint) => `@media screen and (max-width: ${breakpoint}px)`,
  },
  palette: {
    bodyBackground: '#ffffff',
    bodyText: '#000000',
    primary: '#2975cc',
    secondary: '#818c99',
    blue: '#3f8ae0',
    gray: '#76787a',
    text: {
      primary: '#000000',
      secondary: '#818c99',
    },
    statusBar: {
      default: {
        color: '#ffffff',
        style: 'dark',
      },
    },
  },
  header: {
    background: '#ffffff',
    color: '#000000',
  },
  indents: {
    layout: 12,
    panelHeaderHeight: 52,
    headerRight: '100px',
  },
  typography: {
    fontFamily: '-apple-system, Roboto, Helvetica Neue, Arial',
    fontFamilyTT: '"TT Commons", -apple-system, Roboto, Helvetica Neue, Arial',
    fontFamilySFD: '"SF Pro Display", -apple-system, Roboto, Helvetica Neue, Arial',
    fontFamilySFT: '"SF Pro Text", -apple-system, Roboto, Helvetica Neue, Arial',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightDemiBold: 600,
    fontWeightBold: 700,
  },
};
