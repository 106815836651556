import React from "react";
import { ModalPage, PanelHeader, Div, Button } from "@vkontakte/vkui";
import { ModalProps, ModalId } from "../../atomic/modals/types";
import { makeStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/react-hooks";
import { DeleteIconMutation, deleteIconMutation } from "types/gql";
import { useSnackbar, useActions } from "hooks";
import { appActions } from "store/reducers/app";

const styles = makeStyles(
  {
    bottomBtnsGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 5,
      "& > :not(:last-child)": {
        marginBottom: 10,
      },
      "& > button": {
        width: "60%",
      },
    },
    modalPage: {
      zIndex: 2,
    },
    iconContainer: {
      width: 40,
      height: 40,
      paddingLeft: "15px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& > img": {
        objectFit: "cover",
        width: "100%",
        height: "100%",
      },
    },
    panelHeader: {
      "& .PanelHeader__content": {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  { classNamePrefix: "AdminDeleteIcon" }
);

export const AdminDeleteIconModal: React.FC<ModalProps<
  ModalId.ADMIN_ICON_DELETE
>> = ({ payload: { icon }, onClose, ...modalProps }) => {
  const classes = styles();
  const openSnackbar = useSnackbar();
  const deleteIconAction = useActions(appActions.deleteIcon);
  const [deleteIcon, { loading }] = useMutation<
    DeleteIconMutation,
    DeleteIconMutation.Arguments
  >(deleteIconMutation);

  const deleteHandler = () => {
    deleteIcon({
      variables: {
        iconId: icon.id,
      },
    })
      .then(() => {
        deleteIconAction({ iconId: icon.id });
        onClose();
      })
      .catch((e) => openSnackbar({ text: e.message }));
  };
  return (
    <ModalPage
      className={classes.modalPage}
      title="Удалить иконку"
      {...modalProps}
      header={
        <PanelHeader className={classes.panelHeader}>
          Удалить иконку
          <Div className={classes.iconContainer}>
            <img alt="иконка" src={icon.imageUrl} />
          </Div>
        </PanelHeader>
      }
    >
      <Div className={classes.bottomBtnsGroup}>
        <Button onClick={deleteHandler} size={"l"} mode={"destructive"}>
          {loading ? "Удаляю иконку..." : "Удалить иконку"}
        </Button>
        <Button onClick={() => onClose()} size={"l"}>
          Отмена
        </Button>
      </Div>
    </ModalPage>
  );
};
