import React from 'react';
import VKSnackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import Icon28ErrorOutline from '@vkontakte/icons/dist/28/error_outline';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {Theme} from '../../../theme/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    zIndex: 100,
    pointerEvents: 'none',
    '& .Snackbar__in': {
      paddingBottom: '60px',
    },
    '& .Snackbar__body': {
      pointerEvents: 'all',
    },
  },
  icon: {
    color: theme.palette.primary,
  },
}));

export interface SnackbarProps {
  text: string;
  clearSnackbar?: any;
}

const Snackbar: React.FC<SnackbarProps> = ({text, clearSnackbar}) => {
  const mc = useStyles();

  return (
    <VKSnackbar
      className={mc.root}
      onClose={clearSnackbar as any}
      before={(<Icon28ErrorOutline className={mc.icon}/>) as any}
    >
      {text}
    </VKSnackbar>
  );
};

export default Snackbar;
