import React from 'react';

import Notifications from '@vkontakte/icons/dist/28/notifications';
import NotificationsDisable from '@vkontakte/icons/dist/28/notification_disable_outline';

import ActionSheet from '@overrided-vkui/ActionSheet/ActionSheet';
import {useModal} from '../../../hooks';
import {ModalId, NotificationType} from '../modals/types';
import {PopoutId, PopoutProps} from './types';

const ChecklistNotificationActionSheet: React.FC<PopoutProps<PopoutId.CHECKLIST_NOTIFICATION>> = ({
  onClose,
  payload: {checklist},
}) => {
  const openCheckListEditNotification = useModal(ModalId.CHECKLIST_EDIT_NOTIFICATION);

  return (
    <ActionSheet onClose={onClose}>
      <ActionSheet.Item
        onClick={() => openCheckListEditNotification({checklist, type: NotificationType.EDIT})}
        before={<Notifications style={{color: '#2787f5'}}/>}
      >
        <span style={{color: '#2787f5'}}>Изменить время</span>
      </ActionSheet.Item>
      <ActionSheet.Item
        onClick={() => openCheckListEditNotification({checklist, type: NotificationType.DISABLE})}
        before={<NotificationsDisable style={{color: '#e64646'}}/>}
      >
        <span style={{color: '#e64646'}}>Отключить уведомления</span>
      </ActionSheet.Item>
    </ActionSheet>
  );
};

export default ChecklistNotificationActionSheet;
