import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import vkBridge from '@vkontakte/vk-bridge';
import { FormLayoutGroup, Input, ModalCard } from '@vkontakte/vkui';
import Div from '../vkui/Div';
import Button from '../vkui/Button';

import { useActions, useModal, useSnackbar } from '../../../hooks';
import { getDuration } from '../../../utils';
import { userActions } from '../../../store/reducers/user';
import { ModalId, ModalProps, NotificationType } from './types';
import { updateUserChecklistMutation, UpdateUserChecklistMutation } from '../../../types/gql';
import config from '../../../config';

const ChecklistEditNotificationModal: React.FC<ModalProps<ModalId.CHECKLIST_EDIT_NOTIFICATION>> = (
  { payload: { checklist, type }, onClose, ...modalProps },
) => {
  const openErrorRetryModal = useModal(ModalId.ERROR_RETRY);
  const showSnackbar = useSnackbar();
  const updateChecklistNotification = useActions(userActions.updateChecklistNotification);
  const [updateUserChecklist] =
    useMutation<UpdateUserChecklistMutation, UpdateUserChecklistMutation.Arguments>(updateUserChecklistMutation);

  let initialTime = moment().format('HH:mm');

  if (checklist.notifyAt) {
    const utcOffset = moment().utcOffset() / 60;
    const initialHours = Math.floor(checklist.notifyAt / 60) + Number(utcOffset);
    const initialMinutes = checklist.notifyAt % 60;

    initialTime = moment()
      .startOf('day')
      .utc(true)
      .add(initialHours, 'hours')
      .add(initialMinutes, 'minutes')
      .format('HH:mm');
  }

  const [time, setTime] = useState(initialTime);

  const changeTime = (e: any) => {
    setTime(e.target.value);
  };

  const getMinutes = (time: string) => {
    const timeArray = time.split(':');
    const hours = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    const actualTime = moment()
      .startOf('day')
      .utc(false)
      .add(hours, 'hours')
      .add(minutes, 'minutes');

    return moment(actualTime).get('hours') * 60 + moment(actualTime).get('minutes');
  };

  const onUpdateUserChecklistNotification = () =>
    updateUserChecklist({
      variables: {
        params: {
          userChecklistId: checklist.id,
          title: checklist.title,
          description: checklist.description,
          iconId: checklist.icon.id,
          weight: checklist.weight,
          duration: getDuration(checklist.endsAt, checklist.createdAt),
          notifyAt: type === NotificationType.EDIT ? getMinutes(time) : null,
        },
      },
    }).then(({ data }) => {
      if (data) {
        updateChecklistNotification({
          ...checklist,
          notifyAt: type === NotificationType.EDIT ? getMinutes(time) : null,
        });

        showSnackbar({
          text:
            type === NotificationType.EDIT
              ? 'Время уведомлений чеклиста успешно изменено'
              : 'Уведомления выключены',
        });
      }
    });

  const onConfirmClick = () => {
    if (checklist) {
      onUpdateUserChecklistNotification().then(onClose, () => {
        openErrorRetryModal({
          text: 'Не удалось изменить время уведомлений чеклиста',
          onRetry: onUpdateUserChecklistNotification,
        });
      });
    }
  };

  useEffect(() => {
    try {
      vkBridge
        .send('VKWebAppAllowMessagesFromGroup', {
          group_id: config.group_id,
        })
        .catch(() => {
          onClose();
        });
    } catch (e) {
      console.log('VKWebAppAllowMessagesFromGroup error: ', e);
    }
  }, []);

  return (
    <ModalCard
      {...modalProps}
      onClose={onClose}
      header={
        type === NotificationType.EDIT
          ? 'Выберите время уведомлений'
          : 'Отключить уведомления?'
      }
    >
      <Div>
        <FormLayoutGroup>
          {type === NotificationType.EDIT && (
            <Input
              type="time"
              value={time}
              onChange={(e) => changeTime(e)}
              status={time ? 'default' : 'error'}
            />
          )}

          <Button
            onClick={onConfirmClick}
            stretched size='xl'
            disabled={!time}
          >
            Подтвердить
          </Button>
          <Button mode="outline" onClick={onClose} stretched size='xl'>
            Отмена
          </Button>
        </FormLayoutGroup>
      </Div>
    </ModalCard>
  );
};

export default ChecklistEditNotificationModal;
