import React, { useCallback, useMemo, memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme/types';
import cn from 'classnames';

import vkBridge from '@vkontakte/vk-bridge';
import Panel, { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';
import { Epic, FixedLayout, PanelHeaderButton, Tabbar, TabbarItem, Tabs, TabsItem } from '@vkontakte/vkui';
import CheckSquareOutline from '@vkontakte/icons/dist/28/check_square_outline';
import MenuOutline from '@vkontakte/icons/dist/28/menu_outline';
import AdminSmile from '@vkontakte/icons/dist/28/smile_outline';
import AddOutline from '@vkontakte/icons/dist/24/add_outline';

import { CheckListsView } from '../views/CheckListsView';
import { CatalogView } from '../views/CatalogView';
import { AdminView } from '../views/AdminView';

import { useHistory } from 'react-router-dom';
import { useStrictRouteMatch, useSelector, useModal, useStorageValue } from '../../hooks';
import { ModalId } from '../atomic/modals/types';
import { StorageFieldEnum } from '../../types';

export enum MainViews {
  CATALOG = '/catalog',
  CHECKLISTS = '/checklists',
  ADMIN = '/admin',
}

const useStyles = makeStyles((theme: Theme) => ({
  tabbarItem: {
    color: theme.palette.secondary,

    '&.TabbarItem--selected': {
      color: theme.palette.primary,
    },
  },
  desktop: {
    '& .Panel__in': {
      paddingBottom: 0,
    },
  },
  tabsItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fixedLayout: {
    width: '100%',
    background: theme.palette.bodyBackground,
    paddingTop: 10,

    '& .FixedLayout__in': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 24px',
    },

    '& .TabsItem:first-child': {
      paddingLeft: 0,

      '&:after': {
        left: 0,
        width: 'calc(100% - 16px)',
      }

    },
  },
  add: {
    display: 'flex',
    color: theme.palette.icon?.blue,
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: 13,
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
    letterSpacing: '-0.08px',
    textAlign: 'center',
  },
}));

export const MainPanel: React.FC<PanelProps> = memo(({ ...panelProps }) => {
  const mc = useStyles();
  const history = useHistory();
  const openCheckListAdd = useModal(ModalId.CHECKLIST_ADD);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const isDesktop = useSelector((state) => state.config.launchParams.platform === 'desktop_web');
  const [firstVisit, setFirstVisit] = useStorageValue(StorageFieldEnum.FirstVisit);
  const [isAskedAddToHomeScreen, setIsAskedAddToHomeScreen] = useStorageValue(StorageFieldEnum.IsAskedAddToHomeScreen);
  const [isAddedToHomeScreen, setIsAddedToHomeScreen] = useStorageValue(StorageFieldEnum.IsAddedToHomeScreen);

  useEffect(() => {
    if (firstVisit && !isAskedAddToHomeScreen && !isAddedToHomeScreen) {
      if (vkBridge.supports("VKWebAppAddToHomeScreen")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        vkBridge.send("VKWebAppAddToHomeScreen")
          .then(({result}: any) => {
            if (result) {
              setIsAskedAddToHomeScreen(true);
              setIsAddedToHomeScreen(true);
            }
          })
          .catch((err: any) => {
            setIsAskedAddToHomeScreen(true);
            setIsAddedToHomeScreen(false);
          })
      }
    }
  }, []);

  const activeStory = useStrictRouteMatch(
    '/:section?',
    MainViews,
    firstVisit ? MainViews.CHECKLISTS : MainViews.CATALOG,
  );

  const onStoryChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!firstVisit) {
        setFirstVisit(true);
      }

      window.scrollTo({
        top: 0, left: 0,
        behavior: activeStory === e.currentTarget.dataset.story ?
          'smooth' : 'auto',
      });

      history.replace(e.currentTarget.dataset.story as any);
    },
    [firstVisit, history, setFirstVisit, activeStory],
  );

  const tabbar = useMemo(
    () => (
      <Tabbar shadow={true} itemsLayout={'horizontal'}>
        <TabbarItem
          text={'Мои чеклисты'}
          onClick={onStoryChange}
          selected={activeStory === MainViews.CHECKLISTS}
          data-story={MainViews.CHECKLISTS}
          className={mc.tabbarItem}
        >
          <CheckSquareOutline/>
        </TabbarItem>
        <TabbarItem
          text={'Каталог'}
          onClick={onStoryChange}
          selected={activeStory === MainViews.CATALOG}
          data-story={MainViews.CATALOG}
          className={mc.tabbarItem}
        >
          <MenuOutline/>
        </TabbarItem>
        {isAdmin && (
          <TabbarItem
            text={'Админ'}
            onClick={onStoryChange}
            selected={activeStory === MainViews.ADMIN}
            data-story={MainViews.ADMIN}
            className={mc.tabbarItem}
          >
            <AdminSmile/>
          </TabbarItem>
        )}
      </Tabbar>
    ),
    [activeStory, isAdmin, onStoryChange],
  );

  const tabbarDesktop = useMemo(() => (
      <FixedLayout className={mc.fixedLayout} vertical={'top'}>
        <Tabs>
          <TabsItem
            onClick={onStoryChange}
            selected={activeStory === MainViews.CHECKLISTS}
            data-story={MainViews.CHECKLISTS}
            className={mc.tabsItem}
          >
            Мои чеклисты
          </TabsItem>
          <TabsItem
            onClick={onStoryChange}
            selected={activeStory === MainViews.CATALOG}
            data-story={MainViews.CATALOG}
            className={mc.tabsItem}
          >
            Каталог
          </TabsItem>
          {isAdmin && (
            <TabsItem
              onClick={onStoryChange}
              selected={activeStory === MainViews.ADMIN}
              data-story={MainViews.ADMIN}
              className={mc.tabsItem}
            >
              Админ
            </TabsItem>
          )}
        </Tabs>

        <PanelHeaderButton
          className={mc.add}
          onClick={() => openCheckListAdd({ checklist: null })}
        >
          <AddOutline/> Создать чеклист
        </PanelHeaderButton>
      </FixedLayout>
    ),
    [activeStory, isAdmin, onStoryChange, openCheckListAdd],
  );

  return useMemo(
    () => (
      <Panel {...panelProps} separator={false}>
        {isDesktop && tabbarDesktop}
        <Epic
          className={cn(isDesktop && mc.desktop)}
          activeStory={activeStory}
          tabbar={!isDesktop && tabbar}
        >
          <CatalogView id={MainViews.CATALOG} header={false}/>
          <CheckListsView id={MainViews.CHECKLISTS} header={false}/>
          <AdminView id={MainViews.ADMIN} header={false}/>
        </Epic>
      </Panel>
    ),
    [panelProps, isDesktop, tabbarDesktop, activeStory, tabbar],
  );
});
