import React from 'react';
import VKActionSheet, {ActionSheetProps} from '@vkontakte/vkui/dist/components/ActionSheet/ActionSheet';
import {usePlatform, IOS} from '@vkontakte/vkui';
import ActionSheetItem from './ActionSheetItem';
import { useSelector } from '../../../hooks';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { Theme } from '../../../theme/types';

const useStyles = makeStyles<Theme>((theme) => ({
  actionSheet: {
    '& .PopoutWrapper__container': {
      alignItems: 'center',
    },
    '& .PopoutWrapper--closing': {
      opacity: 0,
      transition: 'opacity 0.3s, transform .3s'
    },
    '& .ActionSheet ': {
      maxWidth: '320px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .ActionSheet--closing': {
      opacity: 0,
      transition: 'opacity 0.3s, transform .3s'
    },
  }
}));

const ActionSheet: React.FC<ActionSheetProps> & { Item: typeof ActionSheetItem } = (
  {children, ...props},
) => {
  const platform = usePlatform();
  const isDesktop = useSelector((state) => state.config.launchParams.platform === 'desktop_web');
  const mc = useStyles();

  return (
    <VKActionSheet className={cn(isDesktop && mc.actionSheet)} {...props}>
      {children}
      {platform === IOS && !isDesktop && (
        <ActionSheetItem onClick={props.onClose} mode="cancel">
          <span style={{color: '#2787f5'}}>Отменить</span>
        </ActionSheetItem>
      )}
    </VKActionSheet>
  );
};

ActionSheet.Item = ActionSheetItem;

export default ActionSheet;
