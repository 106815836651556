import React, { memo, useEffect, useRef, useMemo } from 'react';
import { View } from '@vkontakte/vkui';
import { useHistory } from 'react-router-dom';

import { MainPanel } from '../panels/MainPanel';
import { useStrictRouteMatch } from '../../hooks';

enum MainViewPanels {
  MAIN = '/',
}

/**
 * Компонент, с которого начинается визуальная часть
 * @type {React.NamedExoticComponent<object>}
 */
export const MainView = memo(() => {
  /* Навигация */
  const activePanel = useStrictRouteMatch(
    '/:section?',
    MainViewPanels,
    MainViewPanels.MAIN,
  );

  /* Запоминаем предыдущий раздел, чтобы вернуться из просмотрщика в нужное место */
  const history = useHistory();
  const mainPathRef = useRef<string>(MainViewPanels.MAIN);
  // const openMainPanel = useCallback(() => history.replace(mainPathRef.current), [history]);

  useEffect(() => {
    mainPathRef.current = history.location.pathname;
  }, [history.location.pathname]);

  /* Рендер */
  return useMemo(
    () => (
      <View activePanel={activePanel}>
        <MainPanel id={MainViewPanels.MAIN} separator={false}/>
      </View>
    ),
    [activePanel],
  );
});
