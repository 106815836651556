import React from "react";
import { ModalProps, ModalId } from "../../atomic/modals/types";
import { ModalPage, PanelHeader, Button, Div } from "@vkontakte/vkui";
import { makeStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/react-hooks";
import {
  DeleteChecklistCategoryMutation,
  deleteChecklistCategoryMutation,
} from "types/gql/deleteChecklistCategoryMutation";

import { useSnackbar, useErrorClose, useActions } from "hooks";
import { DeleteChecklistMutation, deleteChecklistMutation } from "types/gql";
import { appActions } from "store/reducers/app";

const styles = makeStyles(
  {
    bottomBtnsGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 5,
      "& > :not(:last-child)": {
        marginBottom: 10,
      },
      "& > button": {
        width: "60%",
      },
    },
    modalPage: {
      zIndex: 2,
    },
  },
  { classNamePrefix: "AdminDelete" }
);
export const AdminDeleteModal: React.FC<ModalProps<ModalId.ADMIN_DELETE>> = ({
  payload: { category, checklist, categoryId },
  onClose,
  ...modalProps
}) => {
  const classes = styles();
  const isCategory = !!category;
  const isChecklist = !!checklist;
  const errorClose = useErrorClose();
  const openErrorSnackbar = useSnackbar();
  const deleteCategoryAction = useActions(appActions.deleteChecklistCategory);
  const deleteChecklistAction = useActions(appActions.deleteChecklist);
  const [
    deleteChecklistCategory,
    { loading: deleteCategLoading },
  ] = useMutation<
    DeleteChecklistCategoryMutation,
    DeleteChecklistCategoryMutation.Arguments
  >(deleteChecklistCategoryMutation);

  const [deleteChecklist, { loading: deleteFromCategLoading }] = useMutation<
    DeleteChecklistMutation,
    DeleteChecklistMutation.Arguments
  >(deleteChecklistMutation);

  const deleteCategoryHandler = () => {
    deleteChecklistCategory({
      variables: {
        categoryId: category?.id!,
      },
    })
      .then(() => {
        deleteCategoryAction({ categoryId: category?.id! });
        errorClose();
      })
      .catch((e) => {
        openErrorSnackbar({ text: e.message });
      });
  };

  const deleteChecklistHandler = () => {
    deleteChecklist({
      variables: {
        checklistId: checklist?.id!,
      },
    })
      .then(() => {
        deleteChecklistAction({
          checklistId: checklist?.id!,
          categories: checklist?.categories!,
        });
        errorClose();
      })
      .catch((e) => {
        openErrorSnackbar({ text: e.message });
      });
  };

  const headerTitle = isCategory
    ? `Удалить категорию "${category?.title}"?`
    : `Удалить чеклист "${checklist?.title}"?`;

  const getButtonText = () => {
    const btnText =
      deleteCategLoading || deleteFromCategLoading ? "Удаляю..." : "Удалить";
    return btnText;
  };
  return (
    <ModalPage
      className={classes.modalPage}
      title={isCategory ? "Удалить категорию" : "Удалить чеклист"}
      {...modalProps}
      header={<PanelHeader>{headerTitle}</PanelHeader>}
    >
      <Div className={classes.bottomBtnsGroup}>
        <Button
          size={"l"}
          mode={"destructive"}
          onClick={() => {
            isCategory ? deleteCategoryHandler() : deleteChecklistHandler();
          }}
        >
          {getButtonText()}
        </Button>
        <Button onClick={() => onClose()} size={"l"}>
          Отмена
        </Button>
      </Div>
    </ModalPage>
  );
};
