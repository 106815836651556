import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { FormLayoutGroup, ModalCard } from '@vkontakte/vkui';
import Div from '../vkui/Div';
import Button from '../vkui/Button';

import { PopoutId, PopoutProps } from './types';
import { Theme } from '../../../theme/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& .ModalCard__in': {
      transform: 'translateY(0)',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: '72px',
  },
  label: {
    marginTop: 16,
    padding: '0, 16px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightDemiBold,
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: '0.38px',
  },
  button: {
    marginTop: 16,
  },
}));

const ChecklistEndPopout: React.FC<PopoutProps<PopoutId.CHECKLIST_END>> = ({
  payload: { checklist }, onClose
}) => {
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <ModalCard header="" onClose={onClose}>
        <Div>
          <FormLayoutGroup className={mc.block}>
            <img className={mc.icon} src={checklist.icon.imageUrl} alt=""/>
            <div className={mc.label}>
              Поздравляем! Вы завершили чеклист «{checklist.title}»
            </div>
            <Button
              className={mc.button}
              onClick={onClose}
              mode="primary"
              stretched
              size='xl'
            >
              Класс
            </Button>
          </FormLayoutGroup>
        </Div>
      </ModalCard>
    </div>
  );
};

export default ChecklistEndPopout;
