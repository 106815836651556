import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import View, { ViewProps } from '@vkontakte/vkui/dist/components/View/View';
import { CatalogPanel } from '../panels/CatalogPanel';
import { useStrictRouteMatch } from '../../hooks';

export enum CatalogViewPanels {
  CATALOG = '/catalog',
}

export const CatalogView: React.FC<Partial<ViewProps>> = ({ ...viewProps }) => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const [swipeHistory] = useState([CatalogViewPanels.CATALOG]);
  const activePanel = useStrictRouteMatch(
    '/catalog/:section?',
    CatalogViewPanels,
    CatalogViewPanels.CATALOG,
  );

  return (
    <View
      {...viewProps}
      onSwipeBack={goBack}
      activePanel={activePanel}
      history={swipeHistory}
    >
      <CatalogPanel
        id={CatalogViewPanels.CATALOG}
        onBack={goBack}
      />
    </View>
  );
};
