import {Theme} from '../types';
import {themeDefaults} from './themeDefaults';

import {
  buttonSpaceGrayTheme,
  formControlSpaceGrayTheme,
  inputSpaceGrayTheme,
  linkSpaceGrayTheme, panelHeaderSpaceGrayTheme, panelSpaceGrayTheme,
  selectSpaceGrayTheme,
  separatorSpaceGrayTheme, viewSpaceGrayTheme,
} from '../component-themes';

export const spaceGrayTheme: Theme = {
  ...themeDefaults,
  components: {
    Button: buttonSpaceGrayTheme,
    FormControl: formControlSpaceGrayTheme,
    Input: inputSpaceGrayTheme,
    Link: linkSpaceGrayTheme,
    Panel: panelSpaceGrayTheme,
    PanelHeader: panelHeaderSpaceGrayTheme,
    Select: selectSpaceGrayTheme,
    Separator: separatorSpaceGrayTheme,
    View: viewSpaceGrayTheme,
  },
  palette: {
    bodyBackground: '#19191a',
    bodyText: '#e1e3e6',
    text: {
      primary: '#e1e3e6',
      secondary: '#76787a',
    },
    card: {
      background: '#2c2d2e',
      backgroundDrop: '0 0 8px 0 rgba(200, 200, 200, 0.4)',
      label: '#e1e3e6',
      description: '#818c99',
      text: '#6d7885',
    },
    progress: {
      background: '#e1e3e6',
      active: '#3f8ae0',
      done: '#4bb34b',
    },
    icon: {
      active: '#99a2ad',
      inactive: '#aeb7c2',
      blue: '#3f8ae0',
      red: '#e64646',
      checked: '#4bb34b'
    },
    share: {
      background: '#2c2d2e',
      label: '#818c99',
      description: '#aeb7c2',
      icon: '#aeb7c2'
    },
    statusBar: {
      default: {
        color: '#19191a',
        style: 'light',
      },
    },
  },
};
