import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Panel, PanelHeader, FixedLayout,
  Tabs, TabsItem, Button, Div,
} from '@vkontakte/vkui';
import { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';

import { AdminCategories } from 'components/Admin/Categories';
import { AdminChecklists } from 'components/Admin/CheckLists';
import { ModalId } from 'components/atomic/modals/types';
import { AdminIcons } from 'components/Admin/Icons';
import { useModal } from 'hooks';

interface AdminPanelProps extends PanelProps {
  onBack(): void;
}

enum AdminTabs {
  CATEGORIES,
  CHECKLISTS,
  ICONS,
}

const styles = makeStyles(
  {
    root: {},
    fixedLayout: {
      backgroundColor: 'inherit',
      width: '100%',
      '& .FixedLayout__in': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  { classNamePrefix: 'AdminPanel' },
);
export const AdminPanel = ({ onBack, ...panelProps }: AdminPanelProps) => {
  const classes = styles();
  const [activeTab, setActiveTab] = useState<Partial<AdminTabs>>(
    AdminTabs.CATEGORIES,
  );
  const openAddCategoryModal = useModal(ModalId.ADMIN_CATEGORY_ADD);
  const openAddChecklistModal = useModal(ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT);
  const openAddIconModal = useModal(ModalId.ADMIN_ICON_ADD);

  const returnBtnText = () => {
    if (activeTab === AdminTabs.CATEGORIES) {
      return 'Создать категорию';
    } else if (activeTab === AdminTabs.CHECKLISTS) {
      return 'Создать чеклист';
    } else {
      return 'Добавить иконку';
    }
  };

  const tabHandler = () => {
    if (activeTab === AdminTabs.CATEGORIES) {
      openAddCategoryModal({});
    } else if (activeTab === AdminTabs.CHECKLISTS) {
      openAddChecklistModal({});
    } else {
      openAddIconModal({});
    }
  };

  return (
    <Panel {...panelProps}>
      <PanelHeader separator={false}>Админ</PanelHeader>
      <FixedLayout className={classes.fixedLayout}>
        <Tabs>
          <TabsItem
            onClick={() => setActiveTab(AdminTabs.CATEGORIES)}
            selected={activeTab === AdminTabs.CATEGORIES}
          >
            Категории
          </TabsItem>
          <TabsItem
            onClick={() => setActiveTab(AdminTabs.CHECKLISTS)}
            selected={activeTab === AdminTabs.CHECKLISTS}
          >
            Чек листы
          </TabsItem>
          <TabsItem
            onClick={() => setActiveTab(AdminTabs.ICONS)}
            selected={activeTab === AdminTabs.ICONS}
          >
            Иконки
          </TabsItem>
        </Tabs>
        <Div>
          <Button onClick={() => tabHandler()}>{returnBtnText()}</Button>
        </Div>
      </FixedLayout>
      {activeTab === AdminTabs.CATEGORIES && <AdminCategories/>}
      {activeTab === AdminTabs.CHECKLISTS && <AdminChecklists/>}
      {activeTab === AdminTabs.ICONS && <AdminIcons/>}
    </Panel>
  );
};
