import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import {
  PanelHeader, PanelHeaderButton,
  FormLayout, FormLayoutGroup,
  Input, Radio, Textarea, Button,
  withModalRootContext, Group, Gallery,
} from '@vkontakte/vkui';
import ModalPage from '../../vkui/Modal';

import { useActions, useModal, useOverlay, useSelector } from '../../../../hooks';
import { getDuration } from '../../../../utils';
import { userActions } from '../../../../store/reducers/user';

import {
  updateUserChecklistMutation,
  UpdateUserChecklistMutation,
} from '../../../../types/gql';
import { Durations } from '../../../../types/durations';

import { Theme } from '../../../../theme/types';
import { ModalId, ModalProps } from '../types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 57px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))',
  },
  darkBackground: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0, left: 0, right: 0, bottom: 0,
    zIndex: 10,
    background: 'rgba(0,0,0,.4)',
    transition: 'opacity 167ms ease-out',
    animation: 'animation-fadeIn .32s ease-in',
    opacity: 0,
    display: 'none',
  },
  darkBackgroundActive: {
    opacity: 1,
    display: 'block',
    userSelect: 'none',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    flex: '1 0 auto',

    '& > .FormLayout__submit': {
      display: 'none',
    },
  },
  groupIcons: {},
  swipeList: {
    height: 'auto',
    marginTop: '0 !important',

    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 16,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 16,
    },
  },
  icon: {
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F2F3F5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '10px',
    margin: '0 4px',

    '& img': {
      width: '30px',
    },
  },
  iconChecked: {
    border: `2px solid ${theme.palette.icon?.checked}`,
    boxSizing: 'border-box',
  },
  fixed: {
    padding: '16px',
    background: theme.palette.bodyBackground,
    position: 'sticky',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 11,
  },
  fixedRound: {
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
  },
  customDuration: {
    display: 'none',
    marginBottom: '24px',
  },
  customDurationActive: {
    display: 'block',
  },
  customDurationTitle: {
    marginBottom: 24,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.25',
    textAlign: 'center',
  },
}));

const ChecklistEditModal: React.FC<ModalProps<ModalId.CHECKLIST_EDIT>> = ({
  payload: { checklist }, onClose, ...modalProps
}) => {
  const mc = useStyles();
  const icons = useSelector((store) => store.app.icons).sort((a, b) => b.weight - a.weight);

  const openOverlay = useOverlay();
  const openCheckList = useModal(ModalId.CHECKLIST_VIEW, true);
  const openErrorRetryModal = useModal(ModalId.ERROR_RETRY);
  const [updateUserChecklist] =
    useMutation<UpdateUserChecklistMutation, UpdateUserChecklistMutation.Arguments>(updateUserChecklistMutation);
  const updateUserChecklistAction = useActions(userActions.updateUserChecklist);

  const [title, setTitle] = useState(checklist.title);
  const [description, setDescription] = useState(checklist.description);
  const [iconId, setIconId] = useState(checklist.icon.id);
  const [isIconChanged, setIconChanged] = useState(false);
  const iconFondedIndex = icons.findIndex(icon => icon.id === iconId);
  const [iconIndex, setIconIndex] = useState(iconFondedIndex >= 0 ? iconFondedIndex : 0);
  const [dragging, setDragging] = useState<boolean>(false);

  const prevDurationValue = getDuration(checklist.endsAt, checklist.createdAt);
  const valueInDurations = Durations.find(d => d.value === prevDurationValue);

  const [duration, setDuration] = useState(valueInDurations ? valueInDurations.value : 0);
  const [showCustomDuration, setShowCustomDuration] = useState(false);
  const [customDuration, setCustomDuration] = useState(!valueInDurations ? prevDurationValue : 0);

  const [isFirstClick, setFirstClick] = useState(true);
  const isValidTitle = (title.trim().length > 0) && title.trim().length <= 30;
  const isValidDescription = (description.trim().length >= 0) && description.trim().length <= 100;
  const isValidCustomDuration = customDuration >= 7 && customDuration >= prevDurationValue && customDuration < 750;

  const completeEnabled =
    isValidTitle && isValidDescription && iconId &&
    (duration || (customDuration && isValidCustomDuration));

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);

  const onDurationChange = useCallback((value) => {
    setShowCustomDuration(false);
    setDuration(value);
  }, []);

  const onCustomDurationChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDuration = Number(e.target.value);

    setDuration(0);
    setCustomDuration(newDuration > 0 && newDuration < 750 ? newDuration : 0);
  }, []);

  const customDurationInput = useRef<HTMLInputElement>(null);
  const switchCustomDurationInput = () => {
    setTimeout(() => {
      if (customDurationInput.current) {
        customDurationInput.current && customDurationInput.current.focus();
      }
    }, 1);

    setTimeout(() => {
      const rootEl = document.getElementById('rootElEdit');
      if (rootEl) {
        rootEl.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }, 301);
  };

  const onCustomDurationClick = () => {
    setDuration(0);
    setShowCustomDuration(true);
    switchCustomDurationInput();
  };

  useEffect(() => {
    openOverlay(showCustomDuration);
  }, [showCustomDuration]);

  useEffect(() => {
    return () => {
      openOverlay(false);
    };
  }, []);

  const updateUserChecklistHandler = () =>
    updateUserChecklist({
      variables: {
        params: {
          userChecklistId: checklist.id,
          notifyAt: checklist.notifyAt,
          title,
          description,
          iconId: isIconChanged ? iconId : null,
          duration: duration ? Number(duration) : Number(customDuration),
          weight: checklist.weight,
        },
      },
    }).then(({ data }) => {
      if (data) {
        const newDuration = duration ? Number(duration) : Number(customDuration);
        const ends = moment(checklist.createdAt).add(newDuration, 'days').toDate();
        const icon = icons.find(i => i.id === iconId)!;

        const newChecklist = {
          parentId: checklist.parentId,
          id: checklist.id,
          title,
          description,
          createdAt: checklist.createdAt,
          notifyAt: checklist.notifyAt,
          days: checklist.days,
          endsAt: ends,
          icon: isIconChanged ? {
            id: iconId,
            imageUrl: icon.imageUrl,
            weight: icon.weight,
          } : checklist.icon,
          weight: checklist.weight,
        };

        updateUserChecklistAction(newChecklist);
        openCheckList({ checklist: newChecklist });
      }
    });

  const onConfirmClick = () => {
    setFirstClick(false);

    if (completeEnabled) {
      updateUserChecklistHandler().catch(() => {
        openErrorRetryModal({ text: 'Не удалось отредактировать чеклист', onRetry: updateUserChecklistHandler });
      });
    }
  };

  return (
    <ModalPage
      title={'Редактирование'}
      fullHeight
      {...modalProps}
      header={
        <PanelHeader left={
          <PanelHeaderButton
            onClick={onClose}
          > Отменить </PanelHeaderButton>
        }>Редактирование</PanelHeader>
      }
    >
      <div
        className={mc.root}
        id="rootElEdit"
      >
        <div className={cn(mc.darkBackground, showCustomDuration && mc.darkBackgroundActive)}/>
        <FormLayout className={mc.form}>
          <FormLayoutGroup top={'Название'}>
            <Input
              type="text"
              name="title"
              placeholder={'Введите название...'}
              value={title}
              onChange={onTitleChange}
              status={isFirstClick || isValidTitle ? 'default' : 'error'}
              onBlur={() => setTitle((prev) => prev.trim())}
            />
          </FormLayoutGroup>

          <FormLayoutGroup top={'Описание'}>
            <Textarea
              name="description"
              placeholder={'Введите описание...'}
              style={{ height: '100px' }}
              value={description}
              onChange={onDescriptionChange}
              grow={false}
              maxLength={80}
              rows={5}
              status={isValidDescription ? 'default' : 'error'}
              onBlur={() => setDescription((prev) => prev.trim())}
            />
          </FormLayoutGroup>

          <FormLayoutGroup top={'Иконка'}>
            <Group className={mc.groupIcons}>
              <Gallery
                slideWidth="custom"
                align="left"
                className={mc.swipeList}
                slideIndex={iconIndex}
                onChange={slideIndex => {
                  setDragging(true);
                  setIconIndex(slideIndex);
                }}
                onDragEnd={() => setDragging(false)}
              >
                {icons.map(({ id, imageUrl }) => (
                  <div
                    key={id}
                    className={cn(mc.icon, id === iconId && mc.iconChecked)}
                    onClick={() => {
                      if (dragging) {
                        return;
                      }
                      setIconId(id);
                      setIconChanged(true);
                    }}
                  >
                    <img src={imageUrl} alt="*"/>
                  </div>
                ))}
              </Gallery>
            </Group>
          </FormLayoutGroup>

          <FormLayoutGroup top='Длительность'>
            {Durations.map(({ label, value }) => (
              <Radio
                key={value}
                className={mc.radio}
                checked={duration === value}
                disabled={value < prevDurationValue || value < customDuration}
                onChange={() => onDurationChange(value)}
              >
                {label}
              </Radio>
            ))}
            <Radio
              className={mc.radio}
              checked={(showCustomDuration || customDuration > 0) && duration === 0}
              onChange={onCustomDurationClick}
              onClick={onCustomDurationClick}
            >
              Свой вариант
            </Radio>
          </FormLayoutGroup>
        </FormLayout>
        <div className={cn(mc.fixed, showCustomDuration && mc.fixedRound)}>
          <div className={cn(mc.customDuration, showCustomDuration && mc.customDurationActive)}>
            <div className={mc.customDurationTitle}>Укажите количество дней</div>
            <Input
              type="number"
              getRef={customDurationInput}
              name="title"
              placeholder={'Введите количество дней...'}
              value={
                String(customDuration)
                  .replace(/^0+/g, '')
                  .replace(/[^0-9]+/g, '')
              }
              onChange={onCustomDurationChange}
              status={isValidCustomDuration ? 'default' : 'error'}
              onBlur={() => setShowCustomDuration(false)}
            />
          </div>
          <Button
            size={'xl'}
            onClick={onConfirmClick}
            disabled={!isFirstClick && !completeEnabled}
          >
            Сохранить изменения
          </Button>
        </div>
      </div>
    </ModalPage>
  );
};

export default withModalRootContext(ChecklistEditModal);
