import React, { useState } from "react";
import { ModalProps, ModalId } from "components/atomic/modals/types";
import {
  ModalPage,
  PanelHeader,
  PanelHeaderButton,
  FormLayout,
  FormLayoutGroup,
  Input,
  Button,
  Textarea,
  Checkbox,
  File,
  Div,
  Separator,
  Group,
  Gallery,
} from "@vkontakte/vkui";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useModal, useSnackbar, useActions } from "hooks";
import CameraIcon from "@vkontakte/icons/dist/28/camera_outline";
import { Theme } from "theme";
import { useMutation } from "@apollo/react-hooks";
import cn from "classnames";
import {
  CreateIconMutation,
  createIconMutation,
} from "types/gql/createIconMutation";
import {
  CreateChecklistMutation,
  createChecklistMutation,
  AddChecklistToCategoryMutation,
  addChecklistToCategoryMutation,
  UpdateChecklistMutation,
  updateChecklistMutation,
  RemoveChecklistFromCategoryMutation,
  removeChecklistFromCategoryMutation,
} from "types/gql";
import { appActions } from "store/reducers/app";

const styles = makeStyles(
  (theme: Theme) => ({
    modalPage: {
      "& .ModalPage__content": {
        marginTop: 5,
      },
    },
    formLayout: {
      "& .FormLayout__row-top": {
        fontWeight: theme.typography.fontWeightDemiBold,
      },
    },
    bottomBtnsGroup: {
      display: "flex",
      justifyContent: (props: { isEdit: boolean }) =>
        props.isEdit ? "space-between" : "flex-end",
      margin: "5px 10px 15px 10px",
      paddingBottom: 10,
    },
    hiddenIconFileInput: {
      width: "100%",
      borderRadius: 0,
      height: "100%",
      padding: 0,
      margin: "0 !important",
      opacity: "0 !important",
      position: "absolute",
      top: 0,
      left: 0,
    },
    fileIconContainer: {
      height: 120,
      width: 120,
      background: "#F0F2F5",
      position: "relative",
      marginLeft: 12,
      padding: 0,
      borderRadius: 4,
      cursor: "pointer",
    },
    groupIcons: {
      margin: "0 10px",
    },
    swipeList: {
      height: "auto",
      marginTop: "0 !important",
    },
    icon: {
      width: 56,
      height: 56,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#F2F3F5",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: "10px",
      marginRight: "8px",

      "& img": {
        width: "30px",
      },
    },
    iconChecked: {
      border: "2px solid #2975CC",
      boxSizing: "border-box",
    },
    cameraIconContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#2A5885",
    },
  }),
  { classNamePrefix: "AdminAddChecklistModal" }
);

export const AdminAddChecklistModal: React.FC<ModalProps<
  ModalId.ADMIN_CHECKLIST_ADD_OR_EDIT
>> = ({ payload: { checklist, categoryId }, onClose, ...modalProps }) => {
  const {
    id: editId,
    trackerTitle: editTrackerTitle,
    trackerDescription: editDesc,
    categories: editCateg,
    icon: editIcon,
    weight: editWeight,
  } = { ...checklist };

  const isEdit = !!checklist;
  const openDeleteModal = useModal(ModalId.ADMIN_DELETE);
  const openErrorSnackbar = useSnackbar();
  const createChecklistAction = useActions(appActions.createCheckList);
  const editChecklistAction = useActions(appActions.editChecklist);
  const deleteChecklistFromCategoriesAction = useActions(
    appActions.deleteChecklistFromCategories
  );
  const addIcon = useActions(appActions.addIcon);
  const addChecklistToCategories = useActions(
    appActions.addChecklistToCategories
  );
  const [createIcon] = useMutation<
    CreateIconMutation,
    CreateIconMutation.Arguments
  >(createIconMutation);

  const [createChecklist] = useMutation<
    CreateChecklistMutation,
    CreateChecklistMutation.Arguments
  >(createChecklistMutation);

  const [addChecklistToCategory] = useMutation<
    AddChecklistToCategoryMutation,
    AddChecklistToCategoryMutation.Arguments
  >(addChecklistToCategoryMutation);

  const [updateChecklist] = useMutation<
    UpdateChecklistMutation,
    UpdateChecklistMutation.Arguments
  >(updateChecklistMutation);

  const [deleteChecklistFromCateg] = useMutation<
    RemoveChecklistFromCategoryMutation,
    RemoveChecklistFromCategoryMutation.Arguments
  >(removeChecklistFromCategoryMutation);

  const classes = styles({ isEdit });
  const checklistsCategories = useSelector(
    (state) => state.app.checklistCategories
  );
  const icons = useSelector((store) => store.app.icons);
  const editDefaultState = {
    title: editTrackerTitle,
    desc: editDesc,
    iconSrc: editIcon?.imageUrl,
    iconId: editIcon?.id,
    categories: editCateg ? editCateg.map((categ) => categ.id) : [],
  };

  const [formObj, setFormObj] = useState<{
    title: string;
    desc: string;
    iconSrc: string;
    iconId: string;
    categories: string[];
  }>({
    title: isEdit ? editDefaultState.title! : "",
    desc: isEdit ? editDefaultState.desc! : "",
    iconSrc: isEdit ? editDefaultState.iconSrc! : "",
    iconId: isEdit ? editDefaultState.iconId! : "",
    categories: isEdit ? editDefaultState.categories! : [],
  });

  const [isImageEdit, setIsImageEdit] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);

  const [iconIndex, setIconIndex] = useState(
    ~icons.findIndex((icon) => icon.id === editIcon?.id)
      ? icons.findIndex((icon) => icon.id === editIcon?.id)
      : 0
  );

  const validateForm = () => {
    try {
      const validated = Object.values(formObj).map((value) => value.length > 0);
      console.log(validated);

      const validatedQty = validated.filter((field) => field);
      console.log(validatedQty);

      if (validatedQty.length >= Object.keys(formObj).length - 1) {
        return true;
      } else return false;
    } catch (e) {
      console.log(e);
    }
  };
  const readImage = (file: any) => {
    const reader = new FileReader();

    reader.addEventListener("load", (event: any) => {
      setFormObj({ ...formObj, iconSrc: event.target.result });
    });

    reader.readAsDataURL(file);
  };

  const onIconFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    file && readImage(file);
  };

  const checkboxCategoriesHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCategArray = [...formObj.categories] as string[];
    console.log(formObj, e.target.value);

    if (~formObj.categories.indexOf(e.target.value)) {
      const index = formObj.categories.indexOf(e.target.value);
      console.log(index);

      newCategArray.filter((categoryId) => categoryId !== e.target.value);
      newCategArray.splice(index, 1);
      setFormObj({
        ...formObj,
        categories: newCategArray,
      });
    } else {
      setFormObj({
        ...formObj,
        categories: [...formObj.categories, e.target.value],
      });
    }
  };

  const icon = formObj.iconSrc ? (
    <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 4,
        border: "1px solid #99a2ad",
      }}
      alt={"иконка чеклиста"}
      src={formObj.iconSrc}
    ></img>
  ) : (
    <>
      <CameraIcon style={{ color: "#7996B2" }} height={60} width={64} />
      <div>Загрузить</div>
    </>
  );
  const disabled = validateForm();

  const checklistHandler = async () => {
    try {
      setFormLoading(true);

      if (isEdit) {
        /*   let newIcon = null;
        if (isImageEdit) {
          newIcon = await createIcon({
            variables: {
              params: {
                imageUrl: null,
                image: null,
                imageBase64: formObj.iconSrc,
              },
            },
          });
          newIcon.data?.createIcon &&
            addIcon({
              id: newIcon.data.createIcon.id,
              imageUrl: newIcon.data?.createIcon.imageUrl,
            });
        }
        const getIconId = (full = false) => {
          if (formObj.iconId.length > 0) {
            return formObj.iconId;
          } else return editIcon?.id;
        }; */

        const editParams = {
          iconId: formObj.iconId,
          trackerTitle: formObj.title,
          trackerDescription: formObj.desc,
          defaultDescription: formObj.desc,
          defaultTitle: formObj.title,
        };

        const { data: updatedChecklistData } = await updateChecklist({
          variables: {
            params: { ...editParams, checklistId: editId! },
          },
        });
        const idsCategoriesToDelete: string[] = editDefaultState.categories?.filter(
          (categ) => {
            const isMatch = ~formObj.categories.indexOf(categ);
            if (!isMatch) {
              return categ;
            } else return false;
          }
        );
        const deleteCategoriesPromises = [];
        const addToCategoriesPromises = [];
        for (let i = 0; i < idsCategoriesToDelete?.length!; i++) {
          deleteCategoriesPromises.push(
            deleteChecklistFromCateg({
              variables: {
                categoryId: idsCategoriesToDelete[i],
                checklistId: editId!,
              },
            })
          );
        }
        for (let i = 0; i < formObj.categories.length; i++) {
          addToCategoriesPromises.push(
            addChecklistToCategory({
              variables: {
                categoryId: formObj.categories[i],
                checklistId: editId!,
              },
            })
          );
        }
        await Promise.all(deleteCategoriesPromises);
        await Promise.all(addToCategoriesPromises);
        deleteChecklistFromCategoriesAction({
          checklistId: editId!,
          categoriesIds: idsCategoriesToDelete,
        });
        if (updatedChecklistData?.updateChecklist) {
          addChecklistToCategories({
            categoriesIds: formObj.categories,
            checklist: {
              id: editId!,
              icon: updatedChecklistData.updateChecklist.icon,
              usersCount: updatedChecklistData.updateChecklist.usersCount,
              trackerDescription:
                updatedChecklistData.updateChecklist.trackerDescription,
              defaultTitle: updatedChecklistData.updateChecklist.defaultTitle,
              defaultDescription:
                updatedChecklistData.updateChecklist.defaultDescription,
              //@ts-ignore
              categories: formObj.categories,
              trackerTitle: updatedChecklistData.updateChecklist.trackerTitle,
            },
          });

          editChecklistAction({
            id: editId!,
            icon: updatedChecklistData.updateChecklist.icon,
            usersCount: updatedChecklistData.updateChecklist.usersCount,
            trackerDescription:
              updatedChecklistData.updateChecklist.trackerDescription,
            defaultTitle: updatedChecklistData.updateChecklist.defaultTitle,
            defaultDescription:
              updatedChecklistData.updateChecklist.defaultDescription,
            //@ts-ignore
            categories: formObj.categories,

            trackerTitle: updatedChecklistData.updateChecklist.trackerTitle,
            weight: editWeight!,
          });
        }
      } else {
        /*  let icon;
        if (!formObj.iconId) {
          icon = await createIcon({
            variables: {
              params: {
                imageUrl: null,
                image: null,
                imageBase64: formObj.iconSrc,
              },
            },
          });
          icon.data?.createIcon &&
            addIcon({
              id: icon.data.createIcon.id,
              imageUrl: icon.data.createIcon.imageUrl,
            });
        }
        const iconData = icon && icon.data?.createIcon!; */
        const addParams = {
          iconId: formObj.iconId!,
          trackerTitle: formObj.title,
          trackerDescription: formObj.desc,
          defaultDescription: formObj.desc,
          defaultTitle: formObj.title,
        };

        const { data: checklist } = await createChecklist({
          variables: {
            params: { ...addParams, categoryIds: formObj.categories },
          },
        });
        if (checklist?.createChecklist) {
          checklist?.createChecklist &&
            createChecklistAction({
              //@ts-ignore
              categories: formObj.categories,
              weight: 0,
              trackerTitle: checklist.createChecklist.trackerTitle,
              defaultDescription: checklist.createChecklist.defaultDescription,
              defaultTitle: checklist.createChecklist.defaultTitle,
              icon: checklist.createChecklist.icon,
              trackerDescription: checklist.createChecklist.trackerDescription,
              usersCount: checklist.createChecklist.usersCount,
              id: checklist.createChecklist.id,
            });
        }
      }

      setFormLoading(false);
      onClose();
    } catch (e) {
      setFormLoading(false);
      openErrorSnackbar({ text: e.message });
      console.error(e);
    }
  };

  const buttonText = () => {
    if (isEdit) {
      if (formLoading) {
        return "Сохраняю...";
      } else return "Сохранить";
    } else {
      if (formLoading) {
        return "Создаю...";
      } else return "Создать чеклист";
    }
  };

  return (
    <ModalPage
      className={classes.modalPage}
      title={"Создание чеклиста"}
      style={{ marginTop: 5 }}
      {...modalProps}
      header={
        <PanelHeader
          left={
            <PanelHeaderButton onClick={onClose}> Отменить </PanelHeaderButton>
          }
        >
          {isEdit ? "Редактирование чеклиста" : "Создание чеклиста"}
        </PanelHeader>
      }
    >
      <FormLayout className={classes.formLayout}>
        <FormLayoutGroup top={"Название"}>
          <Input
            type="text"
            name="title"
            placeholder={"Напишите название"}
            value={formObj.title}
            onChange={(e) => {
              setFormObj({ ...formObj, title: e.target.value });
            }}
          />
        </FormLayoutGroup>
        <FormLayoutGroup top={"Краткое описание"}>
          <Textarea
            top="Краткое описание"
            name="title"
            placeholder={"Введите описание"}
            value={formObj.desc}
            onChange={(e) => {
              setFormObj({ ...formObj, desc: e.target.value });
            }}
          />
        </FormLayoutGroup>
        <FormLayoutGroup top={"Категории"}>
          {checklistsCategories.map(({ title, id }) => (
            <Checkbox
              onChange={(e) => {
                checkboxCategoriesHandler(e);
              }}
              key={id}
              value={id}
              checked={formObj.categories.some((categId) => categId === id)}
            >
              {title}
            </Checkbox>
          ))}
        </FormLayoutGroup>
        <FormLayoutGroup top={"Готовые иконки"}>
          <Group className={classes.groupIcons}>
            <Gallery
              slideWidth="60px"
              align="left"
              className={classes.swipeList}
              slideIndex={iconIndex}
              onChange={(slideIndex) => {
                setDragging(true);
                setIconIndex(slideIndex);
              }}
              onDragEnd={() => setDragging(false)}
            >
              {icons.map(({ id, imageUrl }) => (
                <div
                  key={id}
                  className={cn(
                    classes.icon,
                    id === formObj.iconId && classes.iconChecked
                  )}
                  onClick={() => {
                    if (dragging) {
                      return;
                    }
                    formObj.iconId === id
                      ? setFormObj({ ...formObj, iconId: "" })
                      : setFormObj({ ...formObj, iconId: id });
                  }}
                >
                  <img src={imageUrl} alt="*" />
                </div>
              ))}
            </Gallery>
          </Group>
        </FormLayoutGroup>
        {/*   <FormLayoutGroup top={"Загрузить свою иконку"}>
          <Div
            style={{ opacity: formObj.iconId.length > 0 ? 0.5 : 1 }}
            className={classes.fileIconContainer}
          >
            <div className={classes.cameraIconContainer}>{icon}</div>
            <File
              disabled={formObj.iconId.length > 0}
              accept="image/*"
              onChange={(e) => {
                setIsImageEdit(true);
                onIconFileChangeHandler(e);
              }}
              className={classes.hiddenIconFileInput}
              type="file"
            />
          </Div>
        </FormLayoutGroup> */}
        <Separator />
      </FormLayout>
      <div className={classes.bottomBtnsGroup}>
        {isEdit && (
          <Button
            onClick={() =>
              openDeleteModal({
                checklist: { ...formObj, id: editId! },
                categoryId: categoryId,
              })
            }
            mode="tertiary"
          >
            Удалить чеклист
          </Button>
        )}
        <div>
          <Button onClick={onClose} mode="tertiary">
            Отменить
          </Button>
          <Button onClick={checklistHandler} disabled={!disabled}>
            {buttonText()}
          </Button>
        </div>
      </div>
    </ModalPage>
  );
};
