import { SharingDuration, UserChecklist } from '../../../types/gql';

import ChecklistContextActionSheet from './ChecklistContextActionSheet';
import ChecklistShareMethodActionSheet from './ChecklistShareMethodActionSheet';
import ChecklistNotificationActionSheet from './ChecklistNotificationActionSheet';
import ScreenSpinnerPopout from './ScreenSpinnerPopout';
import ChecklistEndPopout from './ChecklistEndPopout';

export enum PopoutId {
  CHECKLIST_CONTEXT = 'checklist_context',
  CHECKLIST_SHARE_METHOD = 'checklist_share_method',
  CHECKLIST_NOTIFICATION = 'checklist_notification',
  CHECKLIST_END = 'checklist_end',
  SCREEN_SPINNER = 'screen_spinner'
}

export const popoutComponents = {
  [PopoutId.CHECKLIST_CONTEXT]: ChecklistContextActionSheet,
  [PopoutId.CHECKLIST_SHARE_METHOD]: ChecklistShareMethodActionSheet,
  [PopoutId.CHECKLIST_NOTIFICATION]: ChecklistNotificationActionSheet,
  [PopoutId.SCREEN_SPINNER]: ScreenSpinnerPopout,
  [PopoutId.CHECKLIST_END]: ChecklistEndPopout,
};

export interface PopoutPayload {
  [PopoutId.CHECKLIST_CONTEXT]: { checklist: UserChecklist };
  [PopoutId.CHECKLIST_SHARE_METHOD]: { checklist: UserChecklist; duration: number; durationType: SharingDuration };
  [PopoutId.CHECKLIST_NOTIFICATION]: { checklist: UserChecklist };
  [PopoutId.SCREEN_SPINNER]: {};
  [PopoutId.CHECKLIST_END]: { checklist: UserChecklist };
}

export interface PopoutProps<K extends PopoutId> {
  onClose(): void;
  payload: PopoutPayload[K];
}
