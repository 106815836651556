import {ofType, unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';

export interface LayoutReducerState {
  activeModal: string | null;
}

export const layoutActions = unionize(
  {
    setActiveModal: ofType<string>(),
  },
  unionizeConfig,
);

type LayoutAction = UnionOf<typeof layoutActions>;

const initialState: LayoutReducerState = {
  activeModal: 'default',
};

/**
 * Редьюсер который отвечает за визуальную сосотовляющую приложения
 */
function layoutReducer(
  state: LayoutReducerState = initialState,
  action: LayoutAction,
) {
  return layoutActions.match(action, {
    setActiveModal: (modalId) => ({...state, activeModal: modalId}),
    default: () => state,
  });
}

export default layoutReducer;
