import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/ru';
import {
  DragDropContext, Droppable, Draggable,
  DroppableProvided, DraggableProvided,
  DraggableStateSnapshot, DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { useMutation } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// import vKbridge from '@vkontakte/vk-bridge';
import { Panel, PanelHeader, PanelHeaderButton, Progress } from '@vkontakte/vkui';
import { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '../atomic/vkui/Button';
import Notifications from '@vkontakte/icons/dist/28/notifications';
import NotificationsDisable from '@vkontakte/icons/dist/28/notification_disable_outline';
import CheckSquareOutline from '@vkontakte/icons/dist/28/check_square_outline';
import AddOutline from '@vkontakte/icons/dist/28/add_outline';

import { useActions, useModal, usePopout, useSelector, useSnackbar } from '../../hooks';
import { getDuration, isSameDate, tapticNotification } from '../../utils';

import { Theme } from '../../theme/types';
import { ModalId, NotificationType } from '../atomic/modals/types';
import {
  checkUserDayMutation,
  CheckUserDayMutation,
  DateTime, updateUserChecklistMutation,
  UpdateUserChecklistMutation,
  UserChecklist,
} from '../../types/gql';
import { userActions } from '../../store/reducers/user';
import { PopoutId } from '../atomic/popouts/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    padding: '0 16px',
    boxSizing: 'border-box',
  },
  rootDesktop: {
    padding: '0 24px',
  },
  addChecklist: {
    color: theme.palette.blue,
  },
  checklists: {
    width: '100%',
    marginTop: 16,
  },
  checklistsDesktop: {
    marginTop: 68,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  checklist: {
    position: 'relative',
    padding: '16px 20px',
    marginBottom: 20,
    background: `${theme.palette.card?.background}`,
    borderRadius: '14px',
  },
  checklistDesktop: {
    width: '43%',
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    width: 'calc(100% - 55px)',
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: '0.38px',
    color: theme.palette.card?.label,
    whiteSpace: 'pre-wrap',
  },
  notification: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notificationIcon: {
    color: theme.palette.icon?.active,
  },
  notificationIconDisabled: {
    color: theme.palette.icon?.active,
  },
  progressBlock: {},
  progress: {
    position: 'relative',
    borderRadius: '5.5px',
    marginTop: '18px',
    background: theme.palette.progress?.background,

    '& .Progress__bg': {
      background: theme.palette.progress?.background,
    },
    '& .Progress__in': {
      background: theme.palette.progress?.active,
      maxWidth: '100%',
    },
  },
  progressDone: {
    '& .Progress__in': {
      background: theme.palette.progress?.done,
    },
  },
  status: {
    marginTop: '22px',
  },
  statusText: {
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.card?.text,
  },
  statusDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  day: {
    width: '35px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  date: {
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    color: theme.palette.card?.description,
  },
  week: {
    fontFamily: theme.typography.fontFamilySFD,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: theme.palette.card?.description,
    letterSpacing: '0.144px',
  },
  icon: {
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      width: '30px',
      height: '30px',
      opacity: '0.6',
      filter: 'grayscale(100%)',
    },
  },
  iconChecked: {
    '& img': {
      opacity: '1',
      filter: 'grayscale(0%)',
    },
  },
  iconOutside: {
    '& img': {
      opacity: '0.2',
      filter: 'grayscale(100%)',
    },
  },
  checklistIconBg: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0,
    width: '40%',
    height: '100%',
    '-webkit-filter': 'blur(1.5px)',
    filter: 'blur(1.5px)',
    overflow: 'hidden',
    zIndex: 1,

    '& img:nth-child(1)': {
      position: 'absolute',
      top: -40,
      left: 0,
      transform: 'rotate(45deg) scale(0.2)',
    },
    '& img:nth-child(2)': {
      position: 'absolute',
      bottom: -35,
      right: 15,
      transform: 'rotate(135deg) scale(0.2)',
    },
    '& img:nth-child(3)': {
      position: 'absolute',
      top: -40,
      right: -30,
      transform: 'rotate(-45deg) scale(0.3)',
    },
    '& img:nth-child(4)': {
      position: 'absolute',
      bottom: -15,
      right: -45,
      transform: 'rotate(-135deg) scale(0.3)',
    },
  },
  empty: {
    position: 'fixed',
    top: 0, left: 0, bottom: 0, right: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emptyIcon: {
    color: theme.palette.icon?.active,
  },
  emptyText: {
    maxWidth: '311px',
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
    marginTop: 16,
    padding: '0 32px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '-0.32px',
    color: theme.palette.text?.secondary,
  },
  emptyButton: {
    marginTop: 24,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface ChecklistsPanelProps extends PanelProps {
  onBack(): void;
  userChecklists: UserChecklist[];
}

export const ChecklistsPanel: React.FC<ChecklistsPanelProps> = function Checklists(
  { onBack, userChecklists, ...panelProps },
) {
  const location = useLocation();
  const hashCheckList = queryString.parse(location.search);
  const checklists = useSelector((store) => store.user.checklists);

  useEffect(() => {
    if (hashCheckList.checklist && typeof hashCheckList.checklist === 'string') {
      const checklist = checklists.find(ch => ch.id === hashCheckList.checklist);
      if (checklist) {
        // vKbridge.send('VKWebAppSetLocation', { 'location': 'checklists' });
        openCheckList({ checklist });
      }
    }
  }, []);

  const mc = useStyles();
  const isDesktop = useSelector((state) => state.config.launchParams.platform === 'desktop_web');
  const openCheckListAdd = useModal(ModalId.CHECKLIST_ADD);
  const openCheckList = useModal(ModalId.CHECKLIST_VIEW);
  const openCheckListEditNotification = useModal(ModalId.CHECKLIST_EDIT_NOTIFICATION);
  const showChecklistNotificationPopout = usePopout(PopoutId.CHECKLIST_NOTIFICATION);
  const showChecklistEndPopout = usePopout(PopoutId.CHECKLIST_END);
  const showSnackbar = useSnackbar();

  const [updateUserChecklist] =
    useMutation<UpdateUserChecklistMutation, UpdateUserChecklistMutation.Arguments>(updateUserChecklistMutation);
  const updateUserChecklistAction = useActions(userActions.updateUserChecklistWeight);
  const updateUserChecklistHandler = (drop: any) => {
    if (drop && drop.destination) {
      const checklist = userChecklists.find(ch => ch.id === drop.draggableId);
      const newWeight = userChecklists[drop.destination.index].weight;

      if (checklist && isFinite(newWeight)) {
        updateUserChecklistAction({ checklist, newWeight });

        updateUserChecklist({
          variables: {
            params: {
              userChecklistId: checklist.id,
              title: checklist.title,
              description: checklist.description,
              iconId: checklist.icon.id,
              duration: getDuration(checklist.endsAt, checklist.createdAt),
              notifyAt: checklist.notifyAt,
              weight: newWeight,
            },
          },
        });
      }
    }
  };

  const [checkUserDay] =
    useMutation<CheckUserDayMutation, CheckUserDayMutation.Arguments>(checkUserDayMutation);
  const checkUserDayAction = useActions(userActions.checkUserDay);
  const checkUserDayHandler = (checklist: any, currentDay: any, inDays: boolean) => {
    const utcDate: DateTime = moment(currentDay).utc(currentDay).toDate();

    if (checklist) {
      checkUserDay({
        variables: {
          userChecklistId: checklist.id,
          date: utcDate,
          isChecked: !inDays,
        },
      }).then(({ data }) => {
        tapticNotification('success');

        if (data && data.checkUserDay) {
          checkUserDayAction({
            userChecklistId: checklist.id,
            date: utcDate,
            isChecked: data.checkUserDay ? !inDays : inDays,
          });

          if (isSameDate(moment().subtract(1, 'day'), checklist.endsAt) && !inDays) {
            showChecklistEndPopout({ checklist });
          }
        }
      }).catch(() => {
        tapticNotification('error');
        showSnackbar({ text: 'Невозможно отметить день за пределами чеклиста!' });
      });
    } else {
      return;
    }
  };

  const weekStatus = useCallback((checklist: UserChecklist) => {
    // const startWeek = moment().startOf('week').isoWeekday(1);
    const today = moment().startOf('day').utc(true);
    const createdAt = moment(checklist.createdAt).startOf('day').utc(true);
    const endsAt = moment(checklist.endsAt).startOf('day').utc(true);
    const beforeDif = today.diff(createdAt, 'days');
    const afterDif = endsAt.diff(today, 'days');

    let startOfList = createdAt.clone();

    if (beforeDif > 7) {
      startOfList = createdAt.clone().add(beforeDif - 6, 'days');
    }
    if (afterDif < 7) {
      startOfList = endsAt.clone().subtract(7, 'days');
    }

    const weekStatusBlock = [];

    for (let i = 0; i < 7; i++) {
      const currentDay = startOfList.clone().add(i, 'day');
      const inDays = !!checklist.days.find((d: any) => isSameDate(d, currentDay));
      const isOutside = currentDay.isBefore(createdAt, 'day') || currentDay.isAfter(endsAt, 'day');

      weekStatusBlock.push(
        <div
          key={i}
          className={mc.day}
          onClick={() => checkUserDayHandler(checklist, currentDay, inDays)}
        >
          <div className={mc.date}>
            {currentDay.format('D')}
          </div>
          <div className={mc.week}>{currentDay.format('dd')}</div>
          <div className={cn(mc.icon, inDays && mc.iconChecked, isOutside && mc.iconOutside)}>
            <img src={checklist.icon.imageUrl} alt="*"/>
          </div>
        </div>,
      );
    }

    return weekStatusBlock;
  }, [checkUserDayHandler]);

  const getProgress = useCallback((checklist: any) => {
    const fullProgress = moment(checklist.endsAt).diff(moment(checklist.createdAt), 'day');
    const todayProgress = moment().startOf('day').diff(moment(checklist.createdAt).startOf('day'), 'day');

    return todayProgress ? Math.round(todayProgress * 100 / fullProgress) : 1;
  }, []);

  const isProgressDone = useCallback((endsAt: DateTime) => {
    return moment().startOf('day').utc(true).isSameOrAfter(endsAt);
  }, []);

  const theme = useTheme<Theme>();

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    boxShadow: isDragging ?
      `${theme.palette.card?.backgroundDrop}` :
      'none',

    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    paddingBottom: isDraggingOver ? 300 : 0,
  });

  return (
    <Panel className={mc.panel} {...panelProps}>
      {!isDesktop && <PanelHeader
        separator={false}
        left={
          <PanelHeaderButton onClick={() => openCheckListAdd({ checklist: null })}>
            <AddOutline className={mc.addChecklist}/>
          </PanelHeaderButton>
        }>Чеклисты</PanelHeader>}

      <div className={cn(mc.root, isDesktop && mc.rootDesktop)}>
        {
          userChecklists.length ? (
            <DragDropContext onDragEnd={(drop) => updateUserChecklistHandler(drop)}>
              <Droppable droppableId="list">
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                  <div
                    className={cn(mc.checklists, isDesktop && mc.checklistsDesktop)}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {userChecklists.map((checklist, checklistIndex) => (
                      <Draggable
                        key={checklist.id}
                        draggableId={checklist.id}
                        index={checklistIndex}
                      >
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={cn(mc.checklist, isDesktop && mc.checklistDesktop)}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <div className={mc.titleBlock}>
                              <div className={mc.title} onClick={() => openCheckList({
                                checklist,
                              })}>{checklist.title}</div>
                              <div className={mc.notification}>
                                {
                                  isProgressDone(checklist.endsAt) ? null :
                                    checklist.notifyAt && checklist.notifyAt >= 0 ?
                                      <Notifications
                                        className={mc.notificationIcon}
                                        onClick={() => showChecklistNotificationPopout({ checklist })}
                                      /> :
                                      <NotificationsDisable
                                        className={cn(mc.notificationIconDisabled)}
                                        onClick={() => openCheckListEditNotification(
                                          { checklist, type: NotificationType.EDIT })}
                                      />
                                }
                              </div>
                            </div>
                            <div
                              className={mc.progressBlock}
                              onClick={() => openCheckList({ checklist })}
                            >
                              <Progress
                                className={cn(mc.progress, isProgressDone(checklist.endsAt) && mc.progressDone)}
                                value={getProgress(checklist)}
                              />
                            </div>
                            <div className={mc.status}>
                              {
                                isProgressDone(checklist.endsAt) ?
                                  <div className={mc.statusText}>Цель достигнута</div> :
                                  <div className={mc.statusDisplay}>
                                    {weekStatus(checklist)}
                                  </div>
                              }
                            </div>
                            {isProgressDone(checklist.endsAt) &&
                            <div className={mc.checklistIconBg}>
                              <img src={checklist.icon.imageUrl} alt="*"/>
                              <img src={checklist.icon.imageUrl} alt="*"/>
                              <img src={checklist.icon.imageUrl} alt="*"/>
                              <img src={checklist.icon.imageUrl} alt="*"/>
                            </div>
                            }
                          </div>)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className={mc.empty}>
              <CheckSquareOutline width={48} height={48} className={mc.emptyIcon}/>
              <div className={mc.emptyText}>
                Сейчас здесь пусто. Создайте свой первый чеклист или добавьте подходящий из каталога.
              </div>
              <Button
                className={mc.emptyButton}
                size={'l'}
                mode='primary'
                onClick={() => openCheckListAdd({ checklist: null })}
              >Создать чеклист</Button>
            </div>
          )
        }
      </div>
    </Panel>
  );
};
