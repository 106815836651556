import React, { memo, useCallback } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import bridge, { Insets } from '@vkontakte/vk-bridge';
import { Button, Panel } from '@vkontakte/vkui';
import { PanelProps } from '@vkontakte/vkui/dist/components/Panel/Panel';

import logo from '../../assets/logo.png';
import CheckCircleOutline from '@vkontakte/icons/dist/28/check_circle_outline';
import Notifications from '@vkontakte/icons/dist/28/notifications';
import UsersOutline from '@vkontakte/icons/dist/28/users_outline';

import { useInsets, useStorageValue } from '../../hooks';

import { Theme } from '../../theme';
import { StorageFieldEnum } from '../../types';

const useStyles = makeStyles<Theme, Insets>((theme) => ({
  root: {
    position: 'fixed',
    top: 0, left: 0, bottom: 0, right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: theme.palette.bodyBackground,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    padding: '0 24px',
    width: '100%',
    height: '100%',
    maxWidth: '300px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  logo: {
    '& img': {
      width: 80
    }
  },
  label: {
    marginTop: 20,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightDemiBold,
    fontSize: '31px',
    lineHeight: '28px',
  },
  descriptions: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamilySFT,
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.4px',

    '& + & ': {
      paddingTop: 24,
    },
  },
  icon: {
    color: '#3F8AE0',
    marginRight: 12,
  },
  buttons: {
    width: '100%',
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
}));

/**
 * Вью онбординга
 */
export const OnboardPanel: React.FC<PanelProps> = memo(({ ...panelProps }) => {
  const insets = useInsets();
  const mc = useStyles(insets);
  const setCompleted = useStorageValue(StorageFieldEnum.OnboardingCompleted)[1];
  const onStartClick = useCallback(() => setCompleted(true), [setCompleted]);
  const allowNotifications = () => bridge.send('VKWebAppAllowNotifications', {});

  return (
    <Panel {...panelProps}>
      <div className={mc.root}>
        <div className={mc.wrapper}>
          <div className={mc.info}>
            <div className={mc.logo}>
              <img src={logo} alt="logo"/>
            </div>
            <div className={mc.label}>Чеклисты</div>
            <div className={mc.descriptions}>
              <div className={mc.description}>
                <CheckCircleOutline className={mc.icon}/>
                <div>Ставьте цели и вырабатывайте полезные привычки</div>
              </div>
              <div className={mc.description}>
                <Notifications className={mc.icon}/>
                <div>Включите уведомления, чтобы не забывать о своих целях</div>
              </div>
              <div className={mc.description}>
                <UsersOutline className={mc.icon}/>
                <div>Делитесь своими результатами с друзьями</div>
              </div>
            </div>
          </div>

          <div className={mc.buttons}>
            <Button className={mc.button} mode={'primary'} size={'xl'} onClick={onStartClick}>Начать</Button>
            <Button className={mc.button} mode={'secondary'} size={'xl'} onClick={allowNotifications}>
              Включить уведомления
            </Button>
          </div>
        </div>
      </div>
    </Panel>
  );
});
