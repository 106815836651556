import 'core-js';
import React from "react";
import ReactDOM from "react-dom";
import vkBridge from "@vkontakte/vk-bridge";

import { AppRoot } from "./components/AppRoot";

if (process.env.NODE_ENV === 'development' && false) {
  // eslint-disable-next-line
  const eruda = require('eruda');
  const el = document.createElement('div');
  document.body.appendChild(el);

  eruda.init({
    container: el,
    tool: ['console', 'elements', 'network'],
  });
}

// Notify native application, initialization done. It will make native
// application hide loader and display this application.
vkBridge.send("VKWebAppInit");

// We are making some timeout due to we are unable to get device insets
// correctly. There is a little timeout after VKWebAppInit is called when
// we cannot get truthy insets
ReactDOM.render(<AppRoot />, document.getElementById("root"));
