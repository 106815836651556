import vkBridge, {ShowStoryBoxOptions} from '@vkontakte/vk-bridge';
import * as StackBlur from 'stackblur-canvas';

import {Theme} from '../theme/types';
import {UserChecklist} from '../types/gql';

/**
 * Создает шару на стене.
 * @param snippetImageUrl ссылка на изображение сниппета
 * @param title название выпуска
 * @param link оригинальная ссылка на приложение (в формате https://vk.com/appXXXXXXX)
 * @param appId идентификатор приложения
 */
export async function shareContentByWall(
  snippetImageUrl: string,
  title: string,
  link: string,
  appId: number,
) {
  // Получаем токен который позволит укоротить ссылку
  // const {access_token} = await vkBridge.send('VKWebAppGetAuthToken', {
  //   scope: '',
  //   app_id: appId,
  // });
  //
  // const short_url = await vkBridge.send(
  //   'VKWebAppCallAPIMethod',
  //   {
  //     method: 'utils.getShortLink',
  //     params: {
  //       url: link, private: 0, v: '5.103', access_token,
  //     },
  //   },
  // ).then(({response}) => response.short_url, () => link);

  const short_url = 'https://vk.com/checklist';

  const options: any = {
    message: `${title}\n ${short_url}\n`,
    attachments: link,
    link_button: 'open_url',
    link_title: title,
    link_image: snippetImageUrl,
  };

  // отказ публиковать пост не считаем ошибкой
  await vkBridge.send('VKWebAppShowWallPostBox', options).catch(() => null);
}

function roundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  ctx.beginPath();
  ctx.moveTo(x, y + radius);
  ctx.lineTo(x, y + height - radius);
  ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
  ctx.lineTo(x + width - radius, y + height);
  ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  ctx.lineTo(x + width, y + radius);
  ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
  ctx.lineTo(x + radius, y);
  ctx.quadraticCurveTo(x, y, x, y + radius);
  ctx.closePath();
  ctx.clip();
}

function drawImageCoverBackground(img: HTMLImageElement, ctx: CanvasRenderingContext2D) {
  const {canvas} = ctx;
  const hRatio = canvas.width / img.width;
  const vRatio = canvas.height / img.height;
  const ratio = Math.max(hRatio, vRatio);
  const centerShiftX = (canvas.width - img.width * ratio) / 2;
  const centerShiftY = (canvas.height - img.height * ratio) / 2;
  ctx.drawImage(img, 0, 0, img.width, img.height, centerShiftX, centerShiftY, img.width * ratio, img.height * ratio);
}

function createStoryBackground(image: HTMLImageElement, vh: number, vw: number): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.height = vh;
  canvas.width = vw;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Работа с канвасом не поддерживается');
  }

  drawImageCoverBackground(image, ctx);

  StackBlur.canvasRGB(canvas, 0, 0, vw, vh, 1);

  ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
  ctx.rect(0, 0, vw, vh);
  ctx.fill();

  return canvas;
}

function createLayer(
  image: HTMLImageElement,
): HTMLCanvasElement {
  const initial = {
    cover: {
      width: 560,
      height: 315
    },
  };

  const layerW = initial.cover.width;
  const coverW = layerW;
  const coverH = initial.cover.height;
  const coverScale = coverW / layerW;

  const canvas = document.createElement('canvas');

  canvas.height = coverH - 70;
  canvas.width = layerW;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Работа с канвасом не поддерживается');
  }

  /* Рисуем обложку */
  ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
  ctx.shadowBlur = 2.3 * coverScale;
  ctx.shadowOffsetX = 2 * coverScale;
  ctx.shadowOffsetY = 3.5 * coverScale;

  roundedRect(ctx, 0, 0, coverW, coverH - 70, 19);
  ctx.drawImage(image, 0, 0, coverW, coverH);

  ctx.shadowBlur = 0;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;

  return canvas;
}

/**
 * Создает шару в историях.
 */
export async function shareContentByStory(
  previewBgImageUrl: string,
  link: string,
  theme: Theme,
  checklist: UserChecklist,
  duration: number,
  wallSnippet: string,
) {
  const bgImage: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = previewBgImageUrl;
  });

  const image: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = wallSnippet;
  });

  /* Генерируем изображение фона */
  const vw = window.innerWidth;
  const vh = window.innerHeight;

  const backgroundCanvas = createStoryBackground(bgImage, vh, vw);

  /* Генерируем изображение переднего слоя */
  const stickerCanvas = createLayer(image);
  const {height: stickerH, width: stickerW} = stickerCanvas;

  /* Собираем опции для стори */
  const storyBoxOptions: ShowStoryBoxOptions = {
    background_type: 'image',
    blob: backgroundCanvas.toDataURL(),
    attachment: {type: 'url', text: 'read', url: link},
    stickers: [
      {
        sticker_type: 'renderable',
        sticker: {
          blob: stickerCanvas.toDataURL(),
          content_type: 'image',
          can_delete: false,
          original_height: stickerH,
          original_width: stickerW,
          transform: {relation_width: 0.8},
          clickable_zones: [
            {
              action_type: 'link',
              action: {link, tooltip_text_key: 'tooltip_open_default'},
              clickable_area: [
                {x: 0, y: 0},
                {x: stickerW, y: 0},
                {x: stickerW, y: stickerH},
                {x: 0, y: stickerH},
              ],
            },
          ],
        },
      },
    ],
  };

  // отказ публиковать стори не считаем ошибкой
  return vkBridge.send('VKWebAppShowStoryBox', storyBoxOptions).catch(() => null);
}
