import React, {PureComponent} from 'react';

import '@vkontakte/vkui/dist/vkui.css';
import vkBridge, {
  UpdateConfigData, VKBridgeSubscribeHandler, Insets,
} from '@vkontakte/vk-bridge';

import {AppLoadingView} from '../views/AppLoadingView';
import {AppCrashView} from '../views/AppCrashView';
import {App} from '../App';
import {ServicePanel} from '../tools/ServicePanel';

import {ThemeProvider} from '../ThemeProvider';
import {GlobalStyleSheet} from '../GlobalStyleSheet/GlobalStyleSheet';
import {VKStorageProvider} from '../VKStorageProvider';
import {ApolloProvider, createApolloClient} from '../ApolloProvider';
import {Provider as StoreProvider, ReactReduxContext} from 'react-redux';

import {Store} from 'redux';
import {createReduxStore, ReduxState} from '../../store';
import {configActions, ConfigReducerState} from '../../store/reducers/config';

import {HashRouter as Router} from 'react-router-dom';

import {appRootContext} from './context';

import {getStorage, getLaunchParams} from '../../utils';
import config from '../../config';

import {AppRootState, AppRootContext} from './types';
import {
  registerMutation, RegisterMutation,
  visitMutation, VisitMutation,
  startAppQuery, StartAppQuery,
} from '../../types/gql';
import moment from 'moment';
import {prepareUpdateStatePayload} from './utils';

const {Provider: AppRootProvider} = appRootContext;

// Assign human-readable store provider name for debugging purposes
ReactReduxContext.displayName = 'AppRoot';

/**
 * Root application component. Everything application requires for showing
 * first screen is being loaded here
 */
export class AppRoot extends PureComponent<{}, AppRootState> {
  /**
   * True if first app config was received
   * @type {boolean}
   */
  private isConfigReceived = false;

  /**
   * Application root context
   * @type {{init: () => Promise<void>}}
   */
  private appRootContext: AppRootContext = {init: this.init.bind(this)};

  /**
   * Application launch parameters
   * @type {LaunchParams}
   */
  private readonly launchParams = getLaunchParams();
  private readonly launchParamsStr = window.location.search.slice(1);

  // Create Apollo client
  private client = createApolloClient(
    config.gqlHttpUrl,
    this.launchParamsStr,
  );

  /**
   * Переменная, которая отвечает за статус отправленного события
   * обновления конфига приложения. Необходимо в случае, когда это событие
   * успели отловить, но в тот момент Redux-хранилища еще не существовало
   */
  private initialAppConfig: UpdateConfigData | null = null;

  /**
   * Аналогично initialAppConfig
   */
  private initialAppInsets: Insets | null = null;

  public state: AppRootState = {
    loading: true,
    store: null,
    error: null,
    blocked: false,
    platform: null,
  };

  public async componentDidMount() {
    // When component did mount, we are waiting for application config from
    // bridge and add event listener
    vkBridge.subscribe(this.onVKBridgeEvent);

    // Init application
    await this.init();
  }

  public componentDidCatch(error: Error) {
    // Catch error if it did not happen before
    this.setState({error: error.message});
  }

  public componentWillUnmount() {
    // When component unloads, remove all event listeners
    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const {loading, error, storage, store} = this.state;
    const {appRootContext, client} = this;

    if (loading || !storage || error || !store) {
      const init = this.init.bind(this);

      return (
        <AppRootProvider value={appRootContext}>
            <GlobalStyleSheet/>
            {error
              ? <AppCrashView onRestartClick={init} error={error}/>
              : <AppLoadingView/>
            }
        </AppRootProvider>
      );
    }

    return (
      <AppRootProvider value={appRootContext}>
        <StoreProvider store={store}>
          <VKStorageProvider storage={storage}>
            <ApolloProvider client={client}>
              <ThemeProvider>
                <Router hashType={'noslash'}>
                  <GlobalStyleSheet/>
                  <App/>
                  <ServicePanel onRestart={this.init}/>
                </Router>
              </ThemeProvider>
            </ApolloProvider>
          </VKStorageProvider>
        </StoreProvider>
      </AppRootProvider>
    );
  }

  /**
   * Checks if event is VKWebAppUpdateConfig to know application config
   * sent from bridge
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = event => {
    const store = this.state && this.state.store;

    if (event.detail) {
      if (event.detail.type === 'VKWebAppUpdateConfig') {
        if (store) {
          store.dispatch(configActions.updateConfig(event.detail.data));
        } else {
          this.initialAppConfig = event.detail.data;
        }

        const config = event.detail.data;
        const updateStatePayload = prepareUpdateStatePayload(
          config, this.isConfigReceived,
        );

        if (!this.isConfigReceived) {
          this.isConfigReceived = true;
        }

        // If state update is required, call it
        if (updateStatePayload) {
          this.setState(updateStatePayload as any);
        }
      } else if (event.detail.type === 'VKWebAppUpdateInsets') {
        if (store) {
          store.dispatch(configActions.updateInsets(event.detail.data.insets));
        } else {
          this.initialAppInsets = event.detail.data.insets;
        }
      } else if (event.detail.type === 'VKWebAppViewRestore') {
        if (store) {
          const {actionBarColor, statusBarStyle} = store.getState().config.viewSettings;
          vkBridge.send('VKWebAppSetViewSettings', {
            status_bar_style: statusBarStyle,
            action_bar_color: actionBarColor,
          });
        }
      }
    }
  };

  /**
   * Initializes application
   */
  private async init() {
    this.setState({loading: true});

    try {
      // Performing all async operations and getting data to launch application
      const [storage, userInfo] = await Promise.all([
        getStorage(),
        vkBridge.send('VKWebAppGetUserInfo'),
      ]);

      const {first_name, last_name, photo_200} = userInfo;

      const utcOffset = moment().utcOffset() / 60;

      await this.client.mutate<RegisterMutation>({
        mutation: registerMutation,
        variables: {params: {timeZoneOffset: utcOffset}},
      });

      await this.client.mutate<VisitMutation>({
        mutation: visitMutation,
        variables: {params: {timeZoneOffset: utcOffset}},
      });

      const startAppResult = await this.client.query<StartAppQuery>({
        query: startAppQuery,
        fetchPolicy: 'network-only',
      });

      const startApp = startAppResult && startAppResult.data;
      console.log('startApp', startApp);
      const userProfile = startApp.user;
      const icons = startApp.icons;
      const checklistCategories = startApp.checklistCategories;

      let appConfig: ConfigReducerState = {
        app: 'vkclient',
        appConfig: config,
        viewSettings: {statusBarStyle: 'light'},
        appearance: 'light',
        scheme: 'client_light',
        insets: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        viewportHeight: 0,
        viewportWidth: 0,
        launchParams: this.launchParams,
      };

      if (this.initialAppConfig) {
        appConfig = {...appConfig, ...this.initialAppConfig};
      }
      if (this.initialAppInsets) {
        appConfig = {...appConfig, insets: this.initialAppInsets};
      }

      const store: Store<ReduxState> | null = createReduxStore({
        storage,
        user: {
          fullName: `${first_name} ${last_name}`,
          profileImageUrl: photo_200,
          isRegistered: !!userProfile,
          isAdmin: userProfile.isAdmin,
          checklists: [...userProfile.checklists.sort((a, b) => b.weight - a.weight)],
        },
        app: {
          icons: [...icons],
          checklistCategories: [...checklistCategories.sort((a, b) => b.weight - a.weight)],
        },
        config: appConfig,
      });

      this.setState({loading: false, storage, store});
    } catch (e) {
      // In case error appears, catch it and display
      this.setState({error: e ? (e.message || 'Неизвестная ошибка') : null, loading: false});
    }
  }
}
