import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import View, {ViewProps} from '@vkontakte/vkui/dist/components/View/View';
import {ChecklistsPanel} from '../panels/CheckListsPanel';
import {useSelector, useStrictRouteMatch} from '../../hooks';

export enum CheckListsViewPanels {
  CHECKLISTS = '/checklists',
}

export const CheckListsView: React.FC<Partial<ViewProps>> = ({...viewProps}) => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const userChecklists = useSelector((store) => store.user.checklists).sort((a, b) => a.weight - b.weight);
  const activePanel = useStrictRouteMatch('/checklists/:section?', CheckListsViewPanels,
    CheckListsViewPanels.CHECKLISTS);
  const [swipeHistory] = useState([CheckListsViewPanels.CHECKLISTS]);

  return (
    <View {...viewProps} onSwipeBack={goBack} activePanel={activePanel} history={swipeHistory}>
      <ChecklistsPanel
        id={CheckListsViewPanels.CHECKLISTS}
        onBack={goBack}
        userChecklists={userChecklists}
      />
    </View>
  );
};
