import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import {
  FormLayout, FormLayoutGroup,
  Input, Radio, Textarea, Button,
  withModalRootContext, Gallery, Group,
} from '@vkontakte/vkui';
import ModalPage from '../../vkui/Modal';

import { useActions, useModal, useSelector, useSnackbar } from '../../../../hooks';
import { userActions } from '../../../../store/reducers/user';

import {
  createUserChecklistMutation,
  CreateUserChecklistMutation, DateTime,
} from '../../../../types/gql';
import { Durations } from '../../../../types/durations';
import { Theme } from '../../../../theme/types';
import { ModalId, ModalProps } from '../types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: '0 12px 12px',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  form: {
    '& > .FormLayout__submit': {
      display: 'none',
    },
  },
  groupIcons: {
    margin: '0 -12px',
  },
  swipeList: {
    height: 'auto',
    marginTop: '0 !important',

    '& .Gallery__layer > .Gallery__slide:first-child > div': {
      marginLeft: 24,
    },
    '& .Gallery__layer > .Gallery__slide:last-child > div': {
      marginRight: 24,
    },
  },
  icon: {
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F2F3F5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '10px',
    margin: '0 4px',

    '& img': {
      width: '30px',
    },
  },
  iconChecked: {
    border: `2px solid ${theme.palette.icon?.checked}`,
    boxSizing: 'border-box',
  },
  saveButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  customDuration: {
    marginBottom: 24,
    display: 'none',
  },
  customDurationActive: {
    display: 'block',
  },
  customDurationTitle: {
    marginBottom: 24,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamilySFT,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.25',
    textAlign: 'center',
  },
}));

const ChecklistAddModal: React.FC<ModalProps<ModalId.CHECKLIST_ADD>> = (
  { payload: { checklist: parentCheckList }, ...modalProps },
) => {
  const mc = useStyles();
  const icons = useSelector((store) => store.app.icons).sort((a, b) => b.weight - a.weight);

  const openCheckList = useModal(ModalId.CHECKLIST_VIEW, true);
  const openErrorRetryModal = useModal(ModalId.ERROR_RETRY);
  const [createUserChecklist] =
    useMutation<CreateUserChecklistMutation, CreateUserChecklistMutation.Arguments>(createUserChecklistMutation);
  const setUserChecklist = useActions(userActions.setUserChecklist);

  const [checklistId] = useState((parentCheckList && parentCheckList.id) || null);
  const [title, setTitle] = useState((parentCheckList && parentCheckList.defaultTitle) || '');
  const [description, setDescription] = useState((parentCheckList && parentCheckList.defaultDescription) || '');
  const [iconId, setIconId] = useState((parentCheckList && parentCheckList.icon.id) || (icons[0] && icons[0].id));
  const [iconIndex, setIconIndex] = useState(icons.findIndex(icon => icon.id === iconId));
  const [dragging, setDragging] = useState<boolean>(false);

  const [showCustomDuration, setShowCustomDuration] = useState(false);
  const [duration, setDuration] = useState(Durations[0].value);
  const [customDuration, setCustomDuration] = useState(0);

  const [isFirstClick, setFirstClick] = useState(true);
  const isValidTitle = (title.trim().length > 0) && title.trim().length <= 30;
  const isValidDescription = (description.trim().length >= 0) && description.trim().length <= 100;
  const isValidCustomDuration = customDuration >= 7 && customDuration < 750;

  const completeEnabled =
    iconId && isValidTitle && isValidDescription &&
    (duration || (customDuration && isValidCustomDuration));

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);

  const onDurationChange = useCallback((value) => {
    setShowCustomDuration(false);
    setDuration(value);
  }, []);

  const onCustomDurationChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDuration = Number(e.target.value);

    setDuration(0);
    setCustomDuration(newDuration > 0 && newDuration < 750 ? newDuration : 0);

  }, []);

  const createUserChecklistHandler = () =>
    createUserChecklist({
      variables: {
        params: {
          checklistId,
          title,
          description,
          iconId,
          duration: duration || customDuration,
        },
      },
    }).then(({ data }) => {
      if (data) {
        const utcDate: DateTime = moment().startOf('day').utc(true).toDate();
        const ends = moment(utcDate).add(duration || customDuration, 'days').toDate();
        const { id, weight } = data.createUserChecklist;
        const icon = icons.find(i => i.id === iconId)!;

        const newChecklist = {
          parentId: checklistId,
          id,
          title,
          description,
          createdAt: utcDate,
          notifyAt: null,
          days: [],
          endsAt: ends,
          icon: {
            id: iconId,
            imageUrl: icon.imageUrl,
            weight: icon.weight,
          },
          weight,
        };

        setUserChecklist(newChecklist);
        openCheckList({ checklist: newChecklist });
      }
    });

  const onConfirmClick = () => {
    setFirstClick(false);

    if (completeEnabled) {
      createUserChecklistHandler().catch(() => {
        openErrorRetryModal({ text: 'Не удалось добавить чеклист', onRetry: createUserChecklistHandler });
      });
    }
  };

  const rootEl = document.getElementById('rootElAdd');
  const customDurationInput = useRef<HTMLInputElement>(null);
  const focusCustomDurationInput = () => {
    customDurationInput.current && customDurationInput.current.focus();
    rootEl && rootEl.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };
  const blurCustomDurationInput = () => {
    customDurationInput.current && customDurationInput.current.blur();
    rootEl && rootEl.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  useEffect(() => {
    showCustomDuration ? focusCustomDurationInput() : blurCustomDurationInput();
  }, [blurCustomDurationInput, focusCustomDurationInput, showCustomDuration]);

  return (
    <ModalPage
      title={'Создание'}
      fullHeight
      {...modalProps}
    >
      <div id="rootElAdd" className={mc.root}>
        <FormLayout className={mc.form}>
          <FormLayoutGroup top={'Название'}>
            <Input
              type="text"
              name="title"
              placeholder={'Введите название...'}
              value={title}
              onChange={onTitleChange}
              status={isFirstClick || isValidTitle ? 'default' : 'error'}
              onBlur={() => setTitle((prev: any) => prev.trim())}
            />
          </FormLayoutGroup>

          <FormLayoutGroup top={'Описание'}>
            <Textarea
              name="description"
              placeholder={'Введите описание...'}
              style={{ height: '100px' }}
              value={description}
              onChange={onDescriptionChange}
              grow={false}
              maxLength={80}
              rows={5}
              status={isValidDescription ? 'default' : 'error'}
              onBlur={() => setTitle((prev: any) => prev.trim())}
            />
          </FormLayoutGroup>

          <FormLayoutGroup top={'Иконка'} className={mc.groupIcons}>
            <Group >
              <Gallery
                slideWidth="custom"
                align="left"
                className={mc.swipeList}
                slideIndex={iconIndex}
                onChange={slideIndex => {
                  setDragging(true);
                  setIconIndex(slideIndex);
                }}
                onDragEnd={() => setDragging(false)}
              >
                {icons.map(({ id, imageUrl }) => (
                  <div
                    key={id}
                    className={cn(mc.icon, id === iconId && mc.iconChecked)}
                    onClick={() => {
                      if (dragging) {
                        return;
                      }
                      setIconId(id);
                    }}
                  >
                    <img src={imageUrl} alt="*"/>
                  </div>
                ))}
              </Gallery>
            </Group>
          </FormLayoutGroup>

          <FormLayoutGroup top='Длительность'>
            {Durations.map(({ label, value }) => (
              <Radio
                key={value}
                className={mc.radio}
                checked={duration === value}
                onChange={() => onDurationChange(value)}
              >
                {label}
              </Radio>
            ))}
            <Radio
              className={mc.radio}
              checked={(showCustomDuration || customDuration > 0) && duration === 0}
              onChange={() => {
                setShowCustomDuration(true);
                setDuration(0);
              }}
              onClick={() => {
                setShowCustomDuration(true);
                setDuration(0);
              }}
            >
              Свой вариант
            </Radio>
            <div className={mc.fixed}>
              <div className={cn(mc.customDuration, showCustomDuration && mc.customDurationActive)}>
                <Input
                  type="number"
                  getRef={customDurationInput}
                  name="title"
                  placeholder={'Введите количество дней...'}
                  value={
                    String(customDuration)
                      .replace(/^0+/g, '')
                      .replace(/[^0-9]+/g, '')
                  }
                  onChange={onCustomDurationChange}
                  status={isValidCustomDuration ? 'default' : 'error'}
                  onBlur={() => setShowCustomDuration(false)}
                />
              </div>
              <Button
                size={'l'}
                onClick={onConfirmClick}
                disabled={!isFirstClick && !completeEnabled}
                className={mc.saveButton}
              >
                Создать чеклист
              </Button>
            </div>
          </FormLayoutGroup>
        </FormLayout>
      </div>
    </ModalPage>
  );
};

export default withModalRootContext(ChecklistAddModal);
