import {createContext} from 'react';
import {ModalPayload} from '../atomic/modals/types';
import {PopoutPayload} from '../atomic/popouts/types';
import {SnackbarProps} from '@overrided-vkui/Snackbar/Snackbar';

export interface LayoutContext {
  openModal<K extends keyof ModalPayload>(modalId: K, payload: ModalPayload[K], override?: boolean): void;
  closeModal(): void;
  openPopout<K extends keyof PopoutPayload>(popoutId: K, payload: PopoutPayload[K]): void;
  closePopout(): void;
  openSnackbar(props: SnackbarProps): void;
  closeSnackbar(): void;
  clearQuery(query: string): void;
  errorClose(): void;
  overlayOpened: boolean;
  openOverlay: any;
}

const context = createContext<LayoutContext>({
  openModal: () => null,
  closeModal: () => null,
  openPopout: () => null,
  closePopout: () => null,
  openSnackbar: () => null,
  closeSnackbar: () => null,
  clearQuery: () => null,
  errorClose: () => null,
  overlayOpened: false,
  openOverlay: (value: boolean) => null,
});

export default context;
